import { create } from 'zustand';

export interface ProgressState {
  progress: number;
  setProgress: (newProgress:number) => void;
  resetProgress: () => void;
}

const useProgressStore = create<ProgressState>((set) => ({
  progress: 0,
  setProgress: (newProgress:number) => set({ progress: newProgress }),
  resetProgress: () => set({ progress: 0 }),
}));

export default useProgressStore;
