import React, { useState, useEffect, ImgHTMLAttributes } from 'react';
import { useAuth } from 'react-oidc-context';
import logger from 'services/logger';
import { getMessageFromError } from 'utils/errorMessage';

interface AuthImageProps extends ImgHTMLAttributes<HTMLImageElement> {
  imageUrl: string;
}

export default function AuthImage({ imageUrl, ...imgProps }: AuthImageProps) {
  const [blobUrl, setBlobUrl] = useState<string | null>(null);
  const auth = useAuth();

  useEffect(() => {
    const fetchImage = async () => {
      try {
        const response = await fetch(imageUrl, {
          headers: {
            authorization: `bearer ${auth?.user?.access_token}`,
          },
        });
        if (!response.ok) {
          logger.error('Auth Image response not ok', { status: response.status });
        }
        const blob = await response.blob();
        const url = URL.createObjectURL(blob);
        setBlobUrl(url);
      } catch (error) {
        const errorMessage = getMessageFromError(error);
        logger.error('Failed to fetch image', { errorMessage });
      }
    };

    if (!blobUrl && imageUrl && auth?.user?.access_token) {
      fetchImage();
    }

    // Clean up the blob URL to avoid memory leaks
    return () => {
      if (blobUrl) {
        URL.revokeObjectURL(blobUrl);
      }
    };
  }, [imageUrl, blobUrl, auth?.user?.access_token]);

  if (!blobUrl) {
    return <div className="m-auto text-center">Loading...</div>;
  }

  return <img src={blobUrl} alt={imgProps.alt || 'Authenticated'} {...imgProps} />;
}
