import React from 'react';
import Arrow from './Arrow';
import RoundedDoubleLeftIcon from './RoundedDoubleLeftIcon';

interface LeftArrowProps {
  className?: string;
}

function LeftArrow({ className }: LeftArrowProps): JSX.Element {
  return (
    <Arrow className={className}>
      <RoundedDoubleLeftIcon className="h-16 w-16 absolute z-20" />
    </Arrow>
  );
}

export default LeftArrow;
