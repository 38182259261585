/* eslint-disable max-len */
import { EventEmitter } from 'events';
import Human, { FaceResult } from '@vladmandic/human';
import logger from 'services/logger';
import { getMessageFromError } from 'utils/errorMessage';
import {
  errorMessages, FaceMatchErrorCodes, loggerMessages, AgePredictionErrorCodes,
} from 'types/logger';
import { HumanConfigValues, DebugConfig } from 'stores/configStore';
import detectCenteredFace from 'utils/detectCenteredFace';
import getErrorMessage from 'utils/getErrorMessage';
import getMedianValue from 'utils/getMedianValue';
import straightFaceMessage from 'utils/straightFaceMessage';
import validateFace from 'utils/validateFace';
import isMobileDevice from 'utils/isMobileDevice';
import createFaceAngles from 'utils/createFaceAngles';
import AgePredictor from 'services/onnx/AgePredictor';
import calculateFaceAngle from 'utils/calculateFaceAngle';
// eslint-disable-next-line import/no-webpack-loader-syntax
import HumanWorker from 'workerize-loader!./human.worker';
import PostAndReceiveWorker from 'services/PostAndReceiveWorker';
import mixpanel from 'services/mixpanel';
import isOffscreenCanvasSupported from 'utils/isOffscreenCanvasSupported';
import {
  FaceAngle, FaceResultMessage, HumanWorkerMessageData, SimilarityResultMessage,
} from './types';
import humanConfig from './config';

class HumanAgePrediction extends EventEmitter {
  human: Human | null;
  humanWorker: PostAndReceiveWorker<HumanWorkerMessageData>;
  initialized: boolean;
  config: HumanConfigValues;
  debugConfig: DebugConfig;
  videoRef: HTMLVideoElement | null = null;
  faceResults: FaceResult[];
  timeInProcess: number;
  _idleProgressTiming: number;
  _idleReinitTiming: number;
  timeInStep2: number;
  keyFace: FaceResult | null;
  keyFaceMismatches: number;
  maxPitch: number;
  minPitch: number;
  maxYaw: number;
  minYaw: number;
  _stepsLogged: number;
  realCheck: boolean;
  liveCheck: boolean;
  anglesSeen: {
    up: boolean;
    down: boolean;
    left: boolean;
    right: boolean;
  };
  allAnglesComplete: boolean;
  startStep3: boolean;
  latestLiveScore: number | undefined;
  latestRealScore: number | undefined;
  _stalledProgressLogged: boolean;
  _timeoutId: ReturnType<typeof setTimeout> | null;
  timeoutImage: File | null;
  step2Variant: 'FaceStraight' | 'FaceAngles' | null;
  endRecursion: boolean;
  agePredictor: AgePredictor;
  ages: number[];
  faceAngles: FaceAngle[];
  videoFrameCanvas: OffscreenCanvas | HTMLCanvasElement | null = null;
  agePredictorActive: boolean;
  isOffscreenCanvasSupported: boolean;

  constructor(configValues: HumanConfigValues, debugConfig: DebugConfig) {
    super();
    this.human = null;
    this.humanWorker = new PostAndReceiveWorker<HumanWorkerMessageData>({ worker: HumanWorker as Worker, name: 'Human' });
    this.humanWorker.worker.onmessage = (event) => {
      if (event.data.type === 'error') {
        logger.error('Human worker error', { error: event.data.error });
        this.emit('error', event.data.error);
      }
    };

    this.initialized = false;
    this.config = configValues;
    this.debugConfig = debugConfig || null;

    this.faceResults = [];
    this.timeInProcess = 0;
    this._idleProgressTiming = 0;
    this._idleReinitTiming = 0;
    this.timeInStep2 = 0;
    this.keyFace = null;
    this.keyFaceMismatches = 0;
    this.maxPitch = 0;
    this.minPitch = 0;
    this.maxYaw = 0;
    this.minYaw = 0;
    this._stepsLogged = 0;
    this.realCheck = false;
    this.liveCheck = false;
    this.anglesSeen = {
      up: false,
      down: false,
      left: false,
      right: false,
    };
    this.allAnglesComplete = false;
    this.startStep3 = false;
    this.latestLiveScore = undefined;
    this.latestRealScore = undefined;
    this._stalledProgressLogged = false;
    this._timeoutId = null;
    this.timeoutImage = null;
    this.step2Variant = 'FaceAngles';
    this.endRecursion = false;
    this.agePredictor = new AgePredictor();
    this.ages = [];
    this.faceAngles = createFaceAngles(configValues.faceAngleStep2.numAngles);
    this.videoFrameCanvas = null;
    this.agePredictorActive = false;
    this.isOffscreenCanvasSupported = isOffscreenCanvasSupported();
  }

  async init() {
    try {
      const promises = [];
      promises.push(await this.initOnnx());
      promises.push(await this.initHuman());
      await Promise.all(promises);
    } catch (error) {
      const msg = getMessageFromError(error);
      const stack = (error as Error).stack || '';
      logger.error('Error initializing models', {
        errorMessage: msg,
        stack,
      });
      this.emit('initError', error);
    }
  }

  async initHuman() {
    if (!this.isOffscreenCanvasSupported) {
      logger.info('Initializing Human without web worker');
      this.human = new Human(humanConfig);
      this.initialized = true;
      this.emit('initialized');
      return;
    }
    logger.debug(loggerMessages.agePrediction.debug.humanInit);
    const timeout = setTimeout(() => {
      logger.warn('Human took longer that 10 seconds to initialize');
      this.emit('initTimeout');
    }, 10_000);
    const start = Date.now();
    try {
      logger.info('Initializing - Human Warmup');
      const humanRes = await this.humanWorker.postAndReceiveMessage({ type: 'warmup' });

      if (humanRes.type === 'error') {
        logger.error('Error warming up human', { error: humanRes.error });
        this.emit('initError', new Error('Error warming up human'));
        return;
      }

      const end = Date.now();
      logger.debug(loggerMessages.agePrediction.debug.humanInitialized, {
        aggregates: {
          humanLoadTime: end - start,
        },
      });
      mixpanel.trackEvent({ event: 'Human Model Loaded', humanLoadTime: end - start });
      clearTimeout(timeout);
      this.initialized = true;
      this.emit('initialized');
    } catch (error) {
      clearTimeout(timeout);
      console.error(error);
      const msg = getMessageFromError(error);
      const stack = (error as Error).stack || '';
      logger.error(loggerMessages.agePrediction.error.humanInit, {
        errorMessage: msg,
        stack,
        aggregates: { errorCode: AgePredictionErrorCodes.ERR_HUMAN_INIT },
      });
      this.emit('initError', error);
    }
  }

  async initOnnx() {
    try {
      logger.info('Initializing - Onnx Model');
      const start = Date.now();
      const onnxRes = await this.agePredictor.init();

      if (onnxRes && ((onnxRes.type && onnxRes.type) === 'error' || onnxRes.error)) {
        logger.error('Error initializing onnx model', { error: onnxRes.error });
        this.emit('initError', new Error('Error initializing onnx model'));
        return;
      }

      this.agePredictorActive = true;

      logger.debug('Onnx Model initialized');
      const end = Date.now();
      mixpanel.trackEvent({ event: 'Onnx Model Loaded', onnxLoadTime: end - start });
    } catch (error) {
      console.error(error);
      const msg = getMessageFromError(error);
      const stack = (error as Error).stack || '';
      logger.error('Onnx error', {
        errorMessage: msg,
        stack,
      });
      this.emit('initError', error);
    }
  }

  terminateHumanWorker() {
    try {
      if (!this.isOffscreenCanvasSupported) {
        // If offscreen canvas is not supported, Human was not initialized with a worker
        return;
      }
      this.humanWorker.worker.terminate();
    } catch (error) {
      console.error(error);
      const msg = getMessageFromError(error);
      logger.error('Error terminating Human worker', {
        errorMessage: msg,
      });
    }
  }

  async initHumanWorker() {
    this.humanWorker = new PostAndReceiveWorker<HumanWorkerMessageData>({ worker: HumanWorker as Worker, name: 'Human' });
    try {
      await this.humanWorker.postAndReceiveMessage({ type: 'warmup' });
    } catch (error) {
      console.error(error);
      const msg = getMessageFromError(error);
      logger.error('Error initializing Human worker', {
        errorMessage: msg,
      });
      this.emit('initError', error);
    }
  }

  terminateAgePredictWorker() {
    try {
      if (!this.isOffscreenCanvasSupported) {
        // If offscreen canvas is not supported, onnx was not initialized with a worker
        // this.agePredictorActive should remain true so the we will not try to reinitialize
        return;
      }
      this.agePredictor.terminate();
      this.agePredictorActive = false;
    } catch (error) {
      console.error(error);
      const msg = getMessageFromError(error);
      logger.error('Error terminating Onnx worker', {
        errorMessage: msg,
      });
    }
  }

  async initAgePredictWorker() {
    try {
      await this.agePredictor.freshInit();
      this.agePredictorActive = true;
    } catch (error) {
      console.error(error);
      const msg = getMessageFromError(error);
      logger.error('Error initializaing Onnx worker', {
        errorMessage: msg,
      });
      this.emit('initError', error);
    }
  }

  async idleReinit() {
    logger.warn(loggerMessages.agePrediction.warn.reinitializingHuman, { aggregates: { timeInProcess: this.timeInProcess } });
    try {
      await this.humanWorker.postAndReceiveMessage({ type: 're-init' });

      this._idleReinitTiming = 0;
    } catch (error) {
      console.error(error);
      const msg = getMessageFromError(error);
      logger.error('Error re-initializing Human', {
        errorMessage: msg,
        aggregates: { errorCode: AgePredictionErrorCodes.ERR_HUMAN_INIT },
      });
      this.emit('initError', error);
    }
  }

  async detectFace(img: HTMLImageElement | HTMLCanvasElement | HTMLVideoElement, willReadFrequently = false) {
    if (!this.initialized) {
      logger.warn('Human not initialized for face detection');
      return false;
    }

    let width = 0;
    let height = 0;
    let imageData: ImageData | null = null;
    if (img instanceof HTMLImageElement || img instanceof HTMLCanvasElement) {
      ({ width, height } = img);
    } else if (img instanceof HTMLVideoElement) {
      width = img.videoWidth;
      height = img.videoHeight;
    }

    // Create or reuse the OffscreenCanvas
    if (!this.videoFrameCanvas || this.videoFrameCanvas.width !== width || this.videoFrameCanvas.height !== height) {
      if (this.videoFrameCanvas) this.videoFrameCanvas = null;
      if (this.isOffscreenCanvasSupported) {
        this.videoFrameCanvas = new OffscreenCanvas(width, height);
      } else {
        this.videoFrameCanvas = document.createElement('canvas');
        this.videoFrameCanvas.width = width;
        this.videoFrameCanvas.height = height;
      }
    }

    const ctx = this.videoFrameCanvas.getContext('2d', { willReadFrequently }) as OffscreenCanvasRenderingContext2D;
    ctx.drawImage(img, 0, 0, width, height);
    imageData = ctx.getImageData(0, 0, width, height);
    if (!imageData) {
      return false;
    }

    if (!this.isOffscreenCanvasSupported) {
      const res = await this.human?.detect(imageData);
      return res?.face[0] || false;
    }
    const res = await this.humanWorker.postAndReceiveMessage({
      type: 'detect',
      frame: imageData.data.buffer,
      width,
      height,
    }, [imageData.data.buffer]) as FaceResultMessage;

    if (res.error) {
      logger.warn(
        loggerMessages.agePrediction.warn.humanJsDetect,
        {
          errorMessage: res.error,
          type: 'ERR_HUMAN_DETECT',
          aggregates: {
            errorCode: AgePredictionErrorCodes.ERR_HUMAN_DETECT,
            phaseType: 'age-prediction:error',
          },
        },
      );
      this.emit('error', res.error);
      throw new Error(res.error);
    }

    if (!res.face) {
      logger.debug(loggerMessages.agePrediction.info.noFaceDetected, {
        aggregates: { timeInProcess: this.timeInProcess },
      });
      return false;
    }

    return res.face;
  }

  async matchFaces(face: FaceResult, faces: FaceResult[]) {
    const { threshold } = this.config.faceMatch;
    if (!this.initialized) {
      logger.warn('Human not initialized for face matching');
      return { pass: false, similarity: 0 };
    }
    try {
      if (!face.embedding) {
        console.warn('Cannot match because no face embedding');
        return { pass: false, similarity: 0 };
      }
      const { embedding } = face;
      const { results } = await this.humanWorker.postAndReceiveMessage({ type: 'matchFaces', embedding, faces }) as { results: [FaceResult, number][] };
      const resultsMap: Map<FaceResult, number> = new Map(results);
      // sort results map by similarity
      const sortedResults = [...resultsMap.entries()].sort((a, b) => b[1] - a[1]);
      const topSimilarity = sortedResults[0][1];
      const topFace = sortedResults[0][0];
      const pass = topSimilarity > threshold;

      if (this.debugConfig.displayTopSimilarityFace && topFace.embedding && face.box && this.videoFrameCanvas) {
        const [x, y, width, height] = face.box;
        const canvas = document.createElement('canvas');
        canvas.width = width;
        canvas.height = height;
        const ctx = canvas?.getContext('2d');
        ctx?.drawImage(this.videoFrameCanvas, x, y, width, height);
        const div = document.getElementById('verify-id-image-container');
        div?.appendChild(canvas);
      }

      this.emit('faceMatchResult', {
        pass,
        similarity: topSimilarity,
        topFace,
      });
      mixpanel.trackFaceMatch({ pass, similarity: topSimilarity });

      if (pass) {
        logger.info(loggerMessages.verifyId.info.faceMatches, {
          aggregates: {
            resultsLength: sortedResults.length,
            pass,
            similarity: topSimilarity,
            threshold,
            phaseType: 'verify-id:success',
            age: topFace.age || 0,
            gender: topFace.gender || '',
            genderScore: topFace.genderScore || 0,
          },
        });
      } else {
        logger.warn(loggerMessages.verifyId.warn.faceDoesNotMatch, {
          type: 'ERR_FACE_MATCH_ID',
          errorMessage: errorMessages[FaceMatchErrorCodes.ERR_FACE_MATCH_ID],
          aggregates: {
            threshold,
            resultsLength: sortedResults.length,
            pass,
            similarity: topSimilarity,
            errorCode: FaceMatchErrorCodes.ERR_FACE_MATCH_ID,
            phaseType: 'verify-id:error',
            age: topFace.age || 0,
            gender: topFace.gender || '',
            genderScore: topFace.genderScore || 0,
          },
        });
      }

      return { pass, similarity: topSimilarity };
    } catch (error) {
      logger.warn(loggerMessages.verifyId.warn.matchingFaces, {
        errorMessage: getMessageFromError(error),
        type: 'ERR_MATCHING_FACES',
        aggregates: {
          errorCode: FaceMatchErrorCodes.ERR_MATCHING_FACES,
        },
      });
      return { pass: false, similarity: 0 };
    }
  }

  assignStep2Variant() {
    const { forceFaceAngles } = this.config;
    this.step2Variant = forceFaceAngles
      ? 'FaceAngles'
      : Math.random() > 0.5 ? 'FaceStraight' : 'FaceAngles';
    logger.setMessageAggregate('step2Variant', this.step2Variant);
    logger.info(loggerMessages.agePrediction.info.step2Variant, { aggregates: { step2Variant: this.step2Variant } });
    mixpanel.trackAgePrediction({ event: 'Step 2 Variant', step2Variant: this.step2Variant });
    this.emit('step2Variant', this.step2Variant);
  }

  logStepChange(step1Complete: boolean, step2Complete: boolean, step3Complete: boolean) {
    if (step1Complete && this._stepsLogged === 0) {
      logger.info(loggerMessages.agePrediction.info.step, { aggregates: { stepCompleted: 1, timeInProcess: this.timeInProcess } });
      this._stepsLogged = 1;
    }

    if (step2Complete && this._stepsLogged === 1) {
      logger.info(loggerMessages.agePrediction.info.step, { aggregates: { stepCompleted: 2, timeInProcess: this.timeInProcess } });
      logger.info(
        loggerMessages.agePrediction.info.realAndLiveCheck,
        { aggregates: { liveScore: this.latestLiveScore ?? 0, realScore: this.latestRealScore ?? 0 } },
      );
      this.emit('realScore', this.latestRealScore ?? 0);
      this.emit('liveScore', this.latestLiveScore ?? 0);
      this._stepsLogged = 2;
    }

    if (step3Complete && this._stepsLogged === 2) {
      logger.info(loggerMessages.agePrediction.info.step, { aggregates: { stepCompleted: 3, timeInProcess: this.timeInProcess } });
      this._stepsLogged = 3;
    }
  }

  async step1SetKeyFace(face: FaceResult) {
    if (!face.embedding) {
      logger.warn('Cannot set key face because no face embedding');
      return;
    }

    if (!this.initialized) {
      logger.warn('Human not initialized for setting key face');
      return;
    }

    if (this.debugConfig.displayKeyFace && this.videoRef && this.videoFrameCanvas && face.box) {
      logger.info('Drawing key face to canvas');
      const keyFaceDiv = document.getElementById('keyFaceImage') as HTMLCanvasElement;

      const blob = await (this.videoFrameCanvas as OffscreenCanvas).convertToBlob();
      const img = new Image();
      img.onload = () => {
        const canvas = document.createElement('canvas');
        canvas.width = img.width;
        canvas.height = img.height;

        const ctx = canvas.getContext('2d');
        ctx?.drawImage(img, 0, 0);

        keyFaceDiv.childNodes.forEach((child) => keyFaceDiv.removeChild(child));
        keyFaceDiv.appendChild(canvas);
      };

      img.src = URL.createObjectURL(blob);
    }

    logger.info('Setting key face', {
      aggregates: {
        score: face.score,
        faceScore: face.faceScore,
        boxScore: face.boxScore,
        age: face.age || 0,
        gender: face.gender || '',
        genderScore: face.genderScore || 0,
        real: face.real || 0,
        live: face.live || 0,
      },
    });
    mixpanel.trackAgePrediction({ event: 'Setting Key Face', keyFace: true });
    this.keyFace = face;
    this.emit('step', 2);
  }

  async step2CollectFaces(face: FaceResult, now: number, then: number) {
    /**
     * The face matches the earlier reading
     * - Save the face for comparison with photo ID if needed
     * - Record the age estimation
     * - add to progress bar
     */
    const { duration, minFaces, maxFaces } = this.config.ageDetection;

    if (!face.box || !this.videoFrameCanvas) {
      logger.warn('No face box or video frame canvas', { faceBox: face.box, videoFrameCanvas: !!this.videoFrameCanvas });
      return;
    }

    const [x, y, width, height] = face.box;

    let faceBoxCanvas: OffscreenCanvas | HTMLCanvasElement;
    if (this.isOffscreenCanvasSupported) {
      faceBoxCanvas = new OffscreenCanvas(width, height);
    } else {
      faceBoxCanvas = document.createElement('canvas');
      faceBoxCanvas.width = width;
      faceBoxCanvas.height = height;
    }

    const ctx = faceBoxCanvas.getContext('2d') as OffscreenCanvasRenderingContext2D | CanvasRenderingContext2D;
    ctx.drawImage(this.videoFrameCanvas, x, y, width, height);

    let ageResult = null;
    if (!this.isOffscreenCanvasSupported) {
      ageResult = await this.agePredictor.predictAgeWithoutWebWorker(faceBoxCanvas);
    } else {
      ageResult = await this.agePredictor.predictAge(faceBoxCanvas);
    }
    if (ageResult === null || ageResult.pred_a === 0) {
      logger.warn('No age result', { ageResult: ageResult?.pred_a });
      return;
    }

    if (this.debugConfig.infiniteFaceCollection) {
      const pLively = document.getElementById('age-debug-lively') as HTMLParagraphElement;
      pLively.innerHTML = `Lively Age: ${ageResult.pred_a}`;
      pLively.style.color = ageResult.pred_a > this.config.ageDetection.threshold ? 'green' : 'yellow';
      const pHuman = document.getElementById('age-debug-human') as HTMLParagraphElement;
      pHuman.innerHTML = `Human Age: ${face.age}`;
      return;
    }

    this.faceResults.push(face);
    this.ages.push(ageResult.pred_a);

    const timeProgress = (this.timeInStep2 / duration) * 100;
    const numFacesProgress = (this.faceResults.length / maxFaces) * 100;

    // Halt progress at 80% of we have not reached the minimum number of faces
    if (this.faceResults.length < minFaces && timeProgress >= 0.8) {
      return;
    }

    this.timeInStep2 += now - then;
    const p = Math.max(timeProgress, numFacesProgress);
    this.emit('progress', p);
  }

  async step2CollectFaceAngles(face: FaceResult, pitch: number, yaw: number) {
    let progressMade = false;
    if (!face.box || !this.videoFrameCanvas) {
      logger.warn('No face box or video frame canvas', { faceBox: face.box, videoFrameCanvas: !!this.videoFrameCanvas });
      return progressMade;
    }
    const [x, y, width, height] = face.box;

    let faceBoxCanvas: OffscreenCanvas | HTMLCanvasElement;
    if (this.isOffscreenCanvasSupported) {
      logger.debug('Creating OffscreenCanvas');
      faceBoxCanvas = new OffscreenCanvas(width, height);
    } else {
      logger.debug('Creating HTMLCanvasElement');
      faceBoxCanvas = document.createElement('canvas');
      faceBoxCanvas.width = width;
      faceBoxCanvas.height = height;
    }
    const ctx = faceBoxCanvas.getContext('2d') as OffscreenCanvasRenderingContext2D | CanvasRenderingContext2D;
    ctx.drawImage(this.videoFrameCanvas, x, y, width, height);

    let ageResult = null;
    if (!this.isOffscreenCanvasSupported) {
      ageResult = await this.agePredictor.predictAgeWithoutWebWorker(faceBoxCanvas);
    } else {
      ageResult = await this.agePredictor.predictAge(faceBoxCanvas);
    }

    if (ageResult === null || ageResult.pred_a === 0) {
      logger.warn('No age result', { ageResult: ageResult?.pred_a });
      return progressMade;
    }

    const { maxFaces } = this.config.ageDetection;
    const {
      numAngles, proximity, percentToComplete, minEulerValue,
    } = this.config.faceAngleStep2;

    if (this.debugConfig.infiniteFaceCollection) {
      const pLively = document.getElementById('age-debug-lively') as HTMLParagraphElement;
      pLively.innerHTML = `Lively Age: ${ageResult.pred_a}`;
      pLively.style.color = ageResult.pred_a > this.config.ageDetection.threshold ? 'green' : 'yellow';
      const pHuman = document.getElementById('age-debug-human') as HTMLParagraphElement;
      pHuman.innerHTML = `Human Age: ${face.age}`;
      return progressMade;
    }

    if (this.faceResults.length < maxFaces) {
      this.faceResults.push(face);
      this.ages.push(ageResult.pred_a);
      progressMade = true;
    }

    /**
     * Because of the conversion made in calculateFaceAngle()
     * the face angle is off by 90 degrees. We adjust for
     * this by adding 90 to the angle if it is less than 270
     * and subtracting 270 if it is greater than 270
     */
    let angle = calculateFaceAngle(yaw, pitch);
    angle = angle < 270 ? angle + 90 : angle - 270;
    let twoOppositesComplete = false;
    const half = numAngles / 2;
    this.faceAngles.forEach((faceAngle, index) => {
      const withinRange = Math.abs(faceAngle.angle - angle) < proximity; // within 10 degrees
      const minFaceTurn = Math.abs(pitch) > minEulerValue || Math.abs(yaw) > minEulerValue;
      if (withinRange && minFaceTurn && !faceAngle.completed) {
        progressMade = true;
        faceAngle.completed = true;
        // If this values opposite is also completed
        // then we have two angles 180 degrees apart
        const oppositeIndex = index < half
          ? index + half
          : index - half;
        twoOppositesComplete = this.faceAngles[oppositeIndex].completed;
      }
    });

    const progress = this.step2FaceAngleProgress();

    // Emit progress to trigger UI updates
    this.emit('progress', progress * 100);

    if (progress > percentToComplete || twoOppositesComplete) {
      /**
       * We do not ask the user to turn their face 360 degrees.
       * Instead we will pass them on two conditions:
       * 1. If more than 50% of the face angles are completed
       * 2. If two angles exactly 180 degrees apart are completed
       *
       * If either condition is met, change all angles to complete
       */
      this.faceAngles.forEach((faceAngle) => {
        faceAngle.completed = true;
      });

      this.liveCheck = true;
      this.realCheck = true;
      this.emit('progress', 100);
    }

    return progressMade;
  }

  step2FaceAngleProgress() {
    const progress = this.faceAngles.reduce((acc, cur) => (cur.completed ? acc + 1 : acc), 0);
    const p = (progress / this.faceAngles.length);
    return p;
  }

  step3FaceRotation(pitch: number, yaw: number, now: number, then: number) {
    const {
      yawLeft, yawRight, pitchDown, pitchUp,
    } = this.config.faceRotation;
    if (!this.startStep3) {
      this.emit('step', 3);
      logger.info(loggerMessages.agePrediction.info.startFaceDirectionTest, { timeInProcess: this.timeInProcess });
      this.startStep3 = true;
    }

    const downComplete = this.maxPitch > pitchDown;
    const upComplete = this.minPitch < pitchUp;
    const leftComplete = this.minYaw < yawLeft;
    const rightComplete = this.maxYaw > yawRight;
    const angles = [downComplete, upComplete, leftComplete, rightComplete];
    const countAngles = angles.reduce((acc, cur) => (cur ? acc + 1 : acc), 0);

    /**
     * Reducing the number of angles to 1 to increase conversion rate
     * Will likely increase the number of false positives
     */
    // this.allAnglesComplete = downComplete && upComplete && leftComplete && rightComplete;
    this.allAnglesComplete = countAngles >= 1;

    this.anglesSeen = {
      down: downComplete,
      up: upComplete,
      left: leftComplete,
      right: rightComplete,
    };

    if (!this.allAnglesComplete) {
      let isFaceRotationProgressing = false;

      // face down
      if (pitch > pitchDown && !downComplete) {
        logger.debug('Step 3 face down', { pitch, pitchDown });
        this.emit('pitchDown', true);
        isFaceRotationProgressing = true;
      }

      // face up
      if (pitch < pitchUp && !upComplete) {
        logger.debug('Step 3 face up', { pitch, pitchUp });
        this.emit('pitchUp', true);
        isFaceRotationProgressing = true;
      }

      // face left
      if (yaw < yawLeft && !leftComplete) {
        logger.debug('Step 3 face left', { yaw, yawLeft });
        this.emit('yawLeft', true);
        isFaceRotationProgressing = true;
      }

      // face right
      if (yaw > yawRight && !rightComplete) {
        logger.debug('Step 3 face right', { yaw, yawRight });
        this.emit('yawRight', true);
        isFaceRotationProgressing = true;
      }

      if (pitch > this.maxPitch) {
        this.maxPitch = pitch;
      }
      if (pitch < this.minPitch) {
        this.minPitch = pitch;
      }

      if (yaw > this.maxYaw) {
        this.maxYaw = yaw;
      }
      if (yaw < this.minYaw) {
        this.minYaw = yaw;
      }

      // Add to idle time when user is not progressing
      if (!isFaceRotationProgressing) {
        logger.debug('Step 3 failed progress', {
          pitch,
          yaw,
          maxPitch: this.maxPitch,
          minPitch: this.minPitch,
          maxYaw: this.maxYaw,
          minYaw: this.minYaw,
        });
        this.addIdleTime(now, then, 'No new face angle');
      }
    } else {
      this.liveCheck = true;
      this.realCheck = true;
    }
  }

  isCenteredAndStraight(face: FaceResult) {
    if (this.videoRef === null) {
      return {
        isCentered: false, isStraight: false, pitch: 0, yaw: 0,
      };
    }
    const { videoWidth, videoHeight } = this.videoRef;
    // const { width, height } = this.videoRef;
    const isCentered = detectCenteredFace(face.box, videoWidth, videoHeight);
    this.emit('centered', isCentered);

    const pitch = face?.rotation?.angle?.pitch || 0;
    const yaw = face?.rotation?.angle?.yaw || 0;
    // const roll = face?.rotation?.angle?.roll || 0;
    const { isStraight } = straightFaceMessage(pitch, yaw);
    this.emit('straight', isStraight);
    this.emit('angles', { pitch, yaw });

    if (this.debugConfig.logFaceAngles) {
      logger.debug('Face angles', { pitch, yaw });
    }

    return {
      isCentered, isStraight, pitch, yaw,
    };
  }

  async faceMatchesKeyFace(face: FaceResult) {
    if (!this.initialized) {
      logger.warn('Human not initialized for face matching');
      return false;
    }

    if (!this.keyFace || !this.keyFace.embedding) {
      logger.warn('Cannot match because no key face');
      this.keyFace = null;
      return false;
    }

    if (!face.embedding) {
      logger.warn('Cannot match because no face embedding');
      return false;
    }

    const { keyFaceMismatchLimit, keyFaceThreshold } = this.config.faceMatch;
    let similarity = 0;
    if (!this.isOffscreenCanvasSupported) {
      similarity = this.human?.match.similarity(face.embedding, this.keyFace.embedding) || 0;
    } else {
      // Compare each new face with the straight face from step 1
      ({ similarity } = await this.humanWorker.postAndReceiveMessage({
        type: 'similarity',
        face1: face.embedding,
        face2: this.keyFace.embedding,
      }) as SimilarityResultMessage);
    }

    const match = similarity >= keyFaceThreshold;
    if (this.debugConfig.logFaceMatch) {
      logger.debug('Face match', { similarity, match });
    }

    if (!match) {
      logger.debug('Face does not match key face', { aggregates: { similarity } });
      this.keyFaceMismatches += 1;
      if (this.keyFaceMismatches < keyFaceMismatchLimit) {
        return match;
      }
      /**
       * We have reached the limit of consecutive face mismatches
       * This means the either
       * - the user is not the same person
       * - the key face was not a good representation of the user
       *
       * Restart the process with a new key face
       */
      logger.warn(loggerMessages.agePrediction.warn.faceMismatch, {
        errorMessage: errorMessages[AgePredictionErrorCodes.ERR_FACE_MISMATCH],
        type: 'ERR_FACE_MISMATCH',
        aggregates: {
          errorCode: AgePredictionErrorCodes.ERR_FACE_MISMATCH,
          timeInProcess: this.timeInProcess,
          numberOfFaces: this.faceResults.length,
          similarity,
        },
      });
      this.resetAgePrediction(false);
      return match;
    }

    this.keyFaceMismatches = 0;
    return match;
  }

  logIdleWarning(step1Complete: boolean, step2Complete: boolean, step3Complete: boolean) {
    logger.info(loggerMessages.agePrediction.info.stalledProgress, {
      aggregates: {
        timeInProcess: this.timeInProcess,
        stepCompleted: this._stepsLogged,
        step1Complete,
        step2Complete,
        step3Complete,
      },
    });
    this._stalledProgressLogged = true;
  }

  addIdleTime(now: number, then: number, reason: string) {
    logger.debug('Adding idle time', {
      idleTime: this._idleProgressTiming,
      reason,
    });
    this._idleProgressTiming += now - then;
  }

  addTimeInProcess(now: number, then: number) {
    this.timeInProcess += now - then;
  }

  addIdleReinitTime(now: number, then: number) {
    this._idleReinitTiming += now - then;
  }

  handleIdleTimeout() {
    logger.info(loggerMessages.agePrediction.info.timedOut, {
      aggregates: {
        stepCompleted: this._stepsLogged,
        timeInProcess: this.timeInProcess,
        idleProgressTiming: this._idleProgressTiming,
        faceUp: this.anglesSeen.up,
        faceDown: this.anglesSeen.down,
        faceLeft: this.anglesSeen.left,
        faceRight: this.anglesSeen.right,
      },
    });
    mixpanel.trackAgePrediction({
      event: 'Age Prediction Timeout',
      timedOut: true,
      timeInProcess: this.timeInProcess,
      numberOfFaces: this.faceResults.length,
    });
    this.emit('faces', this.faceResults);
    this.emit('status', 'timedOut');
  }

  completeAgePrediction() {
    if (this._timeoutId) clearTimeout(this._timeoutId);
    logger.debug('Age results', { ages: this.ages });
    const sorted = [...this.ages].sort();

    logger.debug('Sorted lowest to highest', { ages: sorted });
    const medianAge = getMedianValue(sorted);

    logger.info(loggerMessages.agePrediction.info.complete, {
      aggregates: {
        numberOfFaces: this.faceResults.length,
        age: medianAge,
        timeInProcess: this.timeInProcess,
        idleTime: this._idleProgressTiming,
        liveScore: this.latestLiveScore || 0,
        realScore: this.latestRealScore || 0,
      },
    });
    mixpanel.trackAgePrediction({
      event: 'Age Prediction Complete',
      completed: true,
      predictedAge: medianAge,
      numberOfFaces: this.faceResults.length,
      timeInProcess: this.timeInProcess,
      idleTime: this._idleProgressTiming,
    });

    this.emit('age', medianAge);
    this.emit('faces', this.faceResults);

    this.timeInProcess = 0;

    setTimeout(() => {
      // wait 500ms for green circle to complete
      this.emit('status', 'complete');
    }, 500);
  }

  async recursiveAgePrediction(then: number, videoRef?: HTMLVideoElement) {
    const now = Date.now();
    logger.debug('Time between readings', {
      time: now - then,
      aggregates: {
        timeBetweenReadings: now - then,
      },
    });
    const firstLoop = !!videoRef;
    if (firstLoop) {
      /** recursiveAgePrediction is called explicity from the component
       * with the videoRef as an argument. This means we know we want
       * to start the process and we don't want to end it early.
       *
       * All subsequent calls will not have the videoRef argument and should
       * defer to endRecursion to determine if the process should end.
       */
      mixpanel.trackAgePrediction({ event: 'Age Prediction Start', startTime: new Date().toISOString() });
      this.endRecursion = false;
    }

    if (this.endRecursion) {
      logger.debug('Age prediction recursion ended');
      return;
    }

    const {
      duration, delay, idleTimeout, minFaces, maxFaces,
    } = this.config.ageDetection;

    // Ensure onnx age predictor worker is initialized
    if (!this.agePredictorActive) {
      logger.debug('Waiting on age predictor to initialize');
      this._timeoutId = setTimeout(() => this.recursiveAgePrediction(now), delay);
      return;
    }

    if (videoRef) {
      this.videoRef = videoRef;
    }

    if (!this.initialized) {
      logger.warn('Human not initialized for age prediction');
      return;
    }

    if (this.videoRef === null) {
      logger.warn('Video ref not set for age prediction');
      return;
    }

    const step1Complete = this.keyFace !== null;
    const step2FaceCollectionComplete = this.step2Variant === 'FaceStraight' && this.faceResults.length >= minFaces && (this.timeInStep2 > duration || this.faceResults.length >= maxFaces);
    const step2FaceAnglesComplete = this.step2Variant === 'FaceAngles' && this.step2FaceAngleProgress() === 1;
    const step2Complete = step1Complete && !this.debugConfig.infiniteFaceCollection && (step2FaceCollectionComplete || step2FaceAnglesComplete);
    const step3Complete = step2Complete && (this.allAnglesComplete || this.step2Variant === 'FaceAngles');

    this.logStepChange(step1Complete, step2Complete, step3Complete);

    /**
     * The user has progressed for the idle timeout duration
     */
    if (this._idleProgressTiming > idleTimeout) {
      this.handleIdleTimeout();
      return;
    }

    /**
     * There has been no face detected for 5 seconds
     *
     * Some users experience a bug where their face is not detected
     * even though they are looking at the camera. Reinitalize Human
     * to hopefully fix this.
     */
    // if (this._idleReinitTiming > 5_000) {
    //   await this.idleReinit();
    // }

    /**
     * The user has progressed for 3 seconds
     * Log that the user has stalled
     */
    if (this._idleProgressTiming > 3_000 && !this._stalledProgressLogged) {
      this.logIdleWarning(step1Complete, step2Complete, step3Complete);
    }

    /**
     * Successfully completed the age detection process
     * Either:
     * - the user has looked at the camera for the duration
     * - the minimum number of faces have been detected
     * And:
     * - the real check passed
     * - the live check passed
     */
    if (step2Complete && this.realCheck && this.liveCheck) {
      this.completeAgePrediction();
      return;
    }

    try {
      const face = await this.detectFace(this.videoRef, true);
      this.addTimeInProcess(now, then);

      if (!face) {
        this.emit('faceDetected', false);
        // this.addIdleReinitTime(now, then);
        this.addIdleTime(now, then, 'No face detected');
        this._timeoutId = setTimeout(() => this.recursiveAgePrediction(now), delay);
        return;
      }

      const validation = validateFace(face, this.config.faceValidation);

      if (this.debugConfig.logValidFace) {
        logger.debug('Face validation', validation);
      }

      this.latestLiveScore = face.live;
      this.latestRealScore = face.real;

      if (this.debugConfig.logFaceResult) {
        logger.debug('Face result', { face: JSON.stringify(face) });
      }

      // Skip this face
      if (validation.isValidFace === false) {
        this.emit('faceDetected', false);
        this.addIdleTime(now, then, 'Invalid face');

        if (this.debugConfig.logInvalidFace) {
          logger.debug(loggerMessages.agePrediction.debug.faceFailedValidation, {
            aggregates: {
              ...validation,
              timeInProcess: this.timeInProcess,
            },
          });

          if (!validation.faceScoreValid) {
            logger.debug(loggerMessages.agePrediction.debug.faceScoreLow, {
              aggregates: { faceScore: validation.faceScore },
            });
          }

          if (!validation.hasEmbedding) {
            logger.debug(loggerMessages.agePrediction.debug.faceHasNoEmbedding);
          }
        }

        this._timeoutId = setTimeout(() => this.recursiveAgePrediction(now), delay);
        return;
      }

      // After step 1, we need to make sure the face matches the key face
      if (step1Complete) {
        const match = this.faceMatchesKeyFace(face);
        if (!match) {
          this.emit('faceDetected', false);
          this.addIdleTime(now, then, 'Face does not match key face');
          this._timeoutId = setTimeout(() => this.recursiveAgePrediction(now), delay);
          return;
        }
      }

      this.emit('faceDetected', true);

      const {
        isCentered, isStraight, pitch, yaw,
      } = this.isCenteredAndStraight(face);

      /**
       * Step 1
       * We need to get a baseline reading of the user's face looking
       * straight at the camera. This will be used to compare all other readings
       * against to make sure it is the same user throughout the process
       */
      if (!this.keyFace) {
        if (isCentered && isStraight) {
          await this.step1SetKeyFace(face);
        } else {
          logger.debug('Step 1 failed progress', {
            isCentered,
            isStraight,
          });
          this.addIdleTime(now, then, 'Face not centered or straight');
        }
      }

      /**
       * Step 2
       * Now that we have a baseline face reading have the user look straight
       * at the camera and start taking age measurements.
       */
      if (step1Complete && !step2Complete) {
        if (this.step2Variant === 'FaceAngles') {
          const progressMade = await this.step2CollectFaceAngles(face, pitch, yaw);
          if (!progressMade) {
            this.addIdleTime(now, then, 'No angle progress');
          }
        }

        if (this.step2Variant === 'FaceStraight') {
          if (isStraight && isCentered) {
            await this.step2CollectFaces(face, now, then);
          } else if (!isStraight) {
            logger.debug('Step 2 failed progress', { isStraight });
            this.addIdleTime(now, then, 'Face not straight');
          }
        }
      }

      /**
       * Step 3 (optional)
       *
       * Option 1
       * We have the user rotate their face up, down, left, right.
       * This is to make sure they are not holding a photo of an old person
       * up to the camera.
       *
       */
      if (step2Complete && (!this.realCheck || !this.liveCheck) && !this.allAnglesComplete && this.step2Variant !== 'FaceAngles') {
        this.step3FaceRotation(pitch, yaw, now, then);
      }

      // Take another face reading
      this._timeoutId = setTimeout(() => this.recursiveAgePrediction(now), delay);
    } catch (error) {
      if (this._timeoutId) clearTimeout(this._timeoutId);
      const msg = getErrorMessage(error as unknown as Error);

      // If we have at least one face, we can continue to verify ID with face match
      // Otherwise, we need to start over
      const nextLocation = this.faceResults.length > 0
        ? (isMobileDevice() ? '/verify-id-info' : '/verify-id?status=error')
        : `/exit?error=1&errorCode=${AgePredictionErrorCodes.ERR_RECURSIVE_AGE_PREDICTION}`;

      this.emit('faces', this.faceResults);

      logger.warn(
        loggerMessages.agePrediction.warn.detectingFace,
        {
          errorMessage: msg,
          type: 'ERR_RECURSIVE_AGE_PREDICTION',
          nextLocation,
          aggregates: {
            errorCode: AgePredictionErrorCodes.ERR_RECURSIVE_AGE_PREDICTION,
            phaseType: 'age-prediction:error',
          },
        },
      );

      this.emit('agePredictionError', {
        error: msg,
        nextLocation,
      });
    }
  }

  resetAgePrediction(includeTimeout = true) {
    if (includeTimeout) {
      this.timeInProcess = 0;
      this._idleProgressTiming = 0;
      this._timeoutId = null;
      this._stalledProgressLogged = false;
    }

    this.timeInStep2 = 0;
    this.faceResults = [];
    this.keyFace = null;
    this.keyFaceMismatches = 0;
    this.maxPitch = 0;
    this.minPitch = 0;
    this.maxYaw = 0;
    this.minYaw = 0;
    this._stepsLogged = 0;
    this.realCheck = false;
    this.liveCheck = false;
    this.anglesSeen = {
      up: false,
      down: false,
      left: false,
      right: false,
    };
    this.allAnglesComplete = false;
    this.startStep3 = false;
    this.latestLiveScore = undefined;
    this.latestRealScore = undefined;
    this.endRecursion = false;
    this.ages = [];
    this.faceAngles = createFaceAngles(this.config.faceAngleStep2.numAngles);
    this.emit('reset');
  }

  async resizePhotoForDetection(fileUrl: string) {
    let face: false | FaceResult = false;
    let attempt = 1;
    const canvas = document.createElement('canvas');
    const image = new Image();
    image.src = fileUrl;
    const { width, height } = image;
    let newW = width;
    let newH = height;

    /**
     * Human has a hard time detecting faces images with large dimensions
     * Shrink the image so the largest size is 1000px
     */
    if (width > 1000 || height > 1000) {
      logger.info('Resizing image for face detection', { width, height });
      if (width > height) {
        newW = 1000;
        newH = height * (1000 / width);
      } else {
        newW = width * (1000 / height);
        newH = 1000;
      }
    }

    /**
     * Shrink the image until a face is detected or we've tried 10 times
     */
    while (!face && attempt < 10) {
      canvas.width = newW;
      canvas.height = newH;
      const ctx = canvas.getContext('2d');
      ctx?.drawImage(image, 0, 0, newW, newH);
      // eslint-disable-next-line no-await-in-loop
      face = await this.detectFace(canvas);
      attempt += 1;
      newW *= 0.8;
      newH *= 0.8;
    }

    return face;
  }

  breakRecursiveAgePrediction() {
    logger.info('Breaking recursive age prediction');
    this.endRecursion = true;
  }
}

export default HumanAgePrediction;
