import React, { useCallback, useEffect, useState } from 'react';
import Button from 'components/Button';
import LicensePhoto from 'components/LicensePhoto';
import Checkbox from 'components/Checkbox';
import { useAuth } from 'react-oidc-context';
import axios from 'services/api/axios';
import logger from 'services/logger';
import useFetchConfig from 'services/useFetchConfig';
import { useTranslation } from 'react-i18next';

export interface UrlObject {
  id: number;
  fileId: number;
  emblemState: string;
  url: string;
  ocr_status: string;
  status: string;
  created_at: string;
  upload_reason: string;
  ocr_failure_reason: string;
}

function LicensePhotos() {
  const [licenses, setLicenses] = useState<UrlObject[]>([]);
  const [loading, setLoading] = useState(true);
  const [filterPass, setFilterPass] = useState(false);
  const [filterFail, setFilterFail] = useState(false);
  const { t } = useTranslation();

  const { networkId } = useFetchConfig();
  const auth = useAuth();
  const token = auth?.user?.access_token;
  const user = auth?.user?.profile.name;

  useEffect(() => {
    if (!token || !networkId) return;
    const getLicensePhotos = async () => {
      const headers = {
        'Content-Type': 'application/json',
        'x-network-id': networkId,
        authorization: `bearer ${token}`,
      };
      const { data } = await axios.get('/api/safe-passage/v1/trust-authority/license', { headers });
      setLicenses(data.urls);
      setLoading(false);
    };
    getLicensePhotos();
  }, [token, networkId]);

  const deleteAllPhotos = useCallback(async () => {
    logger.info('Deleting all photos', {
      user,
    });
    try {
      const headers = {
        'Content-Type': 'application/json',
        'x-network-id': networkId,
        authorization: `bearer ${token}`,
      };
      await axios.delete('/api/safe-passage/v1/trust-authority/license', { headers });
      setLicenses(licenses.filter((l) => l.status === 'pending'));
    } catch (err) {
      console.error(err);
    }
  }, [networkId, token, licenses, user]);

  if (loading) {
    return <div>{t('licenses.loading')}</div>;
  }

  const filters = [filterPass, filterFail];
  const allFiltersOff = filters.every((filter) => !filter);

  const filtered = allFiltersOff
    ? licenses
    : licenses.filter((license) => {
      if (allFiltersOff) return true;
      if (filterPass && license.ocr_status === 'pass') return true;
      if (filterFail && license.ocr_status === 'fail') return true;
      return false;
    });
  return (
    <div className="flex flex-col justify-start items-center">
      <h1>{t('licenses.title')}</h1>
      <div className="flex">
        <Checkbox
          label="Pass"
          labelClassName="text-white"
          checked={filterPass}
          onChange={() => setFilterPass(!filterPass)}
          className="mr-4"
        />
        <Checkbox
          label="Fail"
          labelClassName="text-white"
          checked={filterFail}
          onChange={() => setFilterFail(!filterFail)}
        />
      </div>
      <Button
        onClick={deleteAllPhotos}
      >
        {t('licenses.deleteAll')}
      </Button>
      {filtered.map((license) => (<LicensePhoto license={license} key={license.fileId} />))}
    </div>
  );
}

export default LicensePhotos;
