import React, {
  useState, useCallback, ChangeEvent, FormEvent, useRef, useEffect,
} from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Heading from 'components/Heading';
import Button from 'components/Button';
import TakePhoto from 'components/TakePhoto';
import UploadPhoto from 'components/UploadPhoto';
import FullscreenLoader from 'components/FullscreenLoader';

import useAu10tixAgeVerification from 'hooks/useAu10tixAgeVerification';
import useFileReader from 'hooks/useFileReader';
import useAgeDetectionStore from 'stores/ageDetectionStore';
import logger from 'services/logger';
import { loggerMessages } from 'types/logger';
import Loader from 'components/Loader';
import Text from 'components/Text';

function Aut10tixVerifyId() {
  const [imageSource, setImageSource] = useState<'camera' | 'upload' | null>(null);
  const imgRef = useRef<HTMLImageElement | null>(null);
  const [error, setError] = useState<string | null>(null);
  const navigate = useNavigate();
  const setImageId = useAgeDetectionStore((state) => state.setImageId);
  const { t } = useTranslation();

  const {
    file,
    setFile,
    fileUrl,
    setFileUrl,
    imgLoading,
  } = useFileReader();

  const {
    beginAu10tixProcessing,
    cancelCheckInterval,
    setRequestId,
    setAu10tixError,
    au10tixAgeResult,
    au10tixRequestId,
    au10tixLoading,
    au10tixError,
    isDoubleCheck,
  } = useAu10tixAgeVerification();

  useEffect(() => {
    if (file) {
      setAu10tixError(null);
    }
  }, [file, setAu10tixError]);

  useEffect(() => {
    if (au10tixAgeResult) {
      const pass = au10tixAgeResult.status === 'success';

      const qs = !pass
        ? `?error=2&errorCode=${au10tixAgeResult.failCode}`
        : '';

      navigate(`/au10tix/exit${qs}`);
    }
  }, [au10tixAgeResult, navigate]);

  const handleSubmit = useCallback(async (event: FormEvent) => {
    event.preventDefault();
    if (!file) {
      return;
    }
    setImageId(file);

    await beginAu10tixProcessing(file);
  }, [file, beginAu10tixProcessing, setImageId]);

  const handleChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setError(null);
    const { files } = event.target;
    if (!files || files.length === 0) {
      console.error(t('error.noFileSelected'));
      return;
    }
    setImageSource('upload');
    setFile(files[0]);
  }, [setFile, t]);

  useEffect(() => {
    logger.info(loggerMessages.au10tix.info.verifyId, { aggregates: { phaseProgress: true } });
    logger.flush();
  }, []);

  return (
    <div className="flex flex-col items-center w-full">
      <div className="flex flex-col items-center w-full max-w-md text-left">
        {imageSource === null && (
          <>
            <Heading className="mb-4">
              {t('a10tix.title')}
            </Heading>
            <div className="flex justify-center">
              <img alt="Illustration of avatar" className="h-64 w-auto" src="/images/fox-id.png" />
            </div>

            <Text size="lg" className="mb-8">
              {t('instruction.useIdOrLicense')}
            </Text>
          </>
        )}
        {au10tixRequestId && !au10tixError && !error && au10tixLoading ? (
          <div className="flex flex-col justify-center items-center">
            <h1>{t('a10tix.verificationPending')}</h1>
            <Text>{t('a10tix.verificationPendingDescription')}</Text>
            <div className="mt-8">
              <Loader />
            </div>
            {isDoubleCheck && (
              <>
                <Text className="mt-8">
                  {t('a10tix.takingLongTimeMessage')}
                </Text>
                <Button
                  className="mt-8"
                  onClick={() => {
                    setFile(null);
                    setFileUrl(null);
                    setError(null);
                    setImageSource(null);
                    cancelCheckInterval();
                    setRequestId(null);
                  }}
                >
                  {t('a10tix.uploadNewPhoto')}
                </Button>
              </>
            )}
          </div>
        ) : (
          <>
            {fileUrl && (
              <div className="relative rounded-md bg-neutral-300 overflow-hidden mb-4 w-full">
                <img
                  src={fileUrl}
                  alt="uploaded"
                  className="w-full object-cover"
                  ref={imgRef}
                />
                {error && (
                  <div className="transition-[opacity] ease-in-out duration-500 delay-500 absolute rounded-lg bottom-4 left-4 right-4 p-4 bg-red-500 text-center text-white">
                    {error}
                  </div>
                )}
                {au10tixError && (
                  <div className="transition-[opacity] ease-in-out duration-500 delay-500 absolute rounded-lg bottom-4 left-4 right-4 p-4 bg-red-500 text-center text-white">
                    {au10tixError}
                  </div>
                )}
              </div>
            )}

            {file && (
              <Button
                className="mb-4"
                onClick={handleSubmit}
                disabled={imgLoading}
              >
                {t('a10tix.submit')}
              </Button>
            )}

            {imageSource === null && (
              <Button
                className="mb-4"
                onClick={() => setImageSource('camera')}
              >
                {t('a10tix.takeAPhoto')}
              </Button>
            )}

            {imageSource === 'camera' && (
              <TakePhoto
                fileUrl={fileUrl}
                setFile={setFile}
                cancel={() => {
                  setImageSource(null);
                  setFile(null);
                  setFileUrl(null);
                  setError(null);
                }}
                disableCamera={imageSource !== 'camera'}
              />
            )}

            {imageSource !== null && fileUrl && (
              <Button
                onClick={() => {
                  setFile(null);
                  setFileUrl(null);
                  setError(null);
                  setImageSource('camera');
                }}
                className="mb-4 w-full"
                variant="secondary"
              >
                {t('a10tix.takeADifferentPhoto')}
              </Button>
            )}

            {((imageSource === null || imageSource === 'upload') || fileUrl) && (
              <UploadPhoto
                handleChange={handleChange}
              />
            )}
          </>
        )}

        {imgLoading && <FullscreenLoader />}
      </div>
    </div>
  );
}

export default Aut10tixVerifyId;
