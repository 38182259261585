import React from 'react';
import useProgressStore from '../../stores/progressStore';

const strokeWidth = 10;

interface Props {
  width: number;
  height: number;
  radius: number;
}

function ProgressCircleSVG({ width, height, radius }:Props) {
  const progress = useProgressStore((state) => state.progress);
  const diameter = radius * 2;
  const circumference = diameter * Math.PI;
  const angle = (progress / 100) * circumference;
  const trueDiameter = Math.max(diameter - (strokeWidth + 20), 0);
  const trueRadius = trueDiameter / 2;

  const cx = width / 2;
  const cy = height / 2;
  return (
    <svg
      style={{
        position: 'absolute',
        top: 0,
        left: 0,
        height: '100%',
        width: '100%',
      }}
    >
      <circle
        cx={cx}
        cy={cy}
        r={Math.max(trueRadius - 10, 0)}
        fill="none"
        stroke="#059669"
        strokeWidth={strokeWidth}
        strokeDasharray={`${angle}, ${circumference}`}
        style={{
          transition: 'all 300ms',
        }}
        transform={`rotate(-90, ${cx}, ${cy})`}
      />
    </svg>
  );
}

export default ProgressCircleSVG;
