import React from 'react';
import Heading from 'components/Heading';
import Button from 'components/Button';
import Text from 'components/Text';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import IdentificationSVG from 'components/svgs/IdentificationSVG';
import mixpanel from 'services/mixpanel';

function PrepareIdVerificationRoute() {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col items-center gap-4 w-full">
      <div className="max-w-md text-center">
        <Heading className="my-4 text-2xl">
          {t('prepareIdVerification.title')}
        </Heading>
        <Text size="lg" className="mb-1">
          {t('prepareIdVerification.description')}
        </Text>
        <div className="flex justify-center">
          <IdentificationSVG className="w-55 h-55" />
        </div>
        <Text size="lg" className="mb-4">
          {t('prepareIdVerification.restEasy')}
        </Text>
        <div className="space-y-3">
          <Button
            id="continue-to-select-country-link"
            component={Link}
            to="/select-doc-country"
            className="mb-2"
            variant="primary"
            onClick={() => {
              mixpanel.trackEvent({ event: 'Continue to Select Country' });
            }}
          >
            {t('prepareIdVerification.continue')}
          </Button>
        </div>
      </div>
    </div>
  );
}

export default PrepareIdVerificationRoute;
