import React from 'react';
import Loader from './Loader';
import Text from './Text';

interface Props {
  message?: string;
}

function FullscreenLoader({ message = '' }: Props) {
  return (
    <div className="fixed flex flex-col items-center justify-center inset-0 z-10">
      {!!message && (
        <div className="flex flex-col items-center justify-center inset-0">
          <Text size="lg" className="mb-4 text-center">{message}</Text>
        </div>
      )}
      <Loader />
    </div>
  );
}

export default FullscreenLoader;
