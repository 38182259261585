import React from 'react';
import { useTranslation } from 'react-i18next';

function IDPhotoGuidelines({ className = '' }) {
  const { t } = useTranslation();
  return (
    <div className={className}>
      <div className="py-3">{t('idPhotoGuidelines.title')}</div>
      <ul className="flex flex-col gap-3 list-disc pl-6">
        <li>{t('idPhotoGuidelines.placeId')}</li>
        <li>{t('idPhotoGuidelines.ensureGlareFree')}</li>
        <li>{t('idPhotoGuidelines.allEdges')}</li>
      </ul>
    </div>
  );
}

export default IDPhotoGuidelines;
