import React, {
  FormEventHandler, Fragment, useCallback, useEffect, useState,
} from 'react';
import { ExclamationCircleIcon } from '@heroicons/react/24/solid';
import clsx from 'clsx';
import Loader from 'components/Loader';
import Toast from 'components/Toast';
import useVerificationStore from 'stores/verificationStore';
import axios from 'services/api/axios';
import logger from 'services/logger';
import mixpanel from 'services/mixpanel';
import { AppealFormErrorCodes, UploadImageErrorCodes, loggerMessages } from 'types/logger';
import useFetchConfig from 'services/useFetchConfig';
import { getMessageFromError } from 'utils/errorMessage';
import useAgeDetectionStore from 'stores/ageDetectionStore';
import { UploadFile } from 'types/upload';
import { useTranslation } from 'react-i18next';
import ConfirmPhoto from './ConfirmPhoto';
import ConfirmId from './ConfirmId';
import Success from './Success';
import ManualSubmission from './ManualSubmission';
import { AppealFormData, Step, FormStatus } from './types';

interface AppealFormProps {
  uploadFile: UploadFile
}

const STEP_COMPONENTS = {
  ConfirmPhoto,
  ConfirmId,
  ManualSubmission,
  Success,
};

function AppealForm({ uploadFile }: AppealFormProps) {
  const emblemState = useVerificationStore((s) => s.emblemState);
  const [formStatus, setFormStatus] = useState<FormStatus>('');
  const tagRequestId = useVerificationStore((s) => s.tagRequestId);
  const { companySlug, networkId, requireManualReviewPhoto } = useFetchConfig();

  const steps: Step[] = requireManualReviewPhoto
    ? ['ConfirmPhoto', 'ConfirmId', 'ManualSubmission', 'Success']
    : ['ConfirmId', 'ManualSubmission', 'Success'];

  const [step, setStep] = useState<'ConfirmPhoto' | 'ConfirmId' | 'ManualSubmission' | 'Success'>(steps[0]);
  const isRetry = useAgeDetectionStore((s) => s.isRetry);
  const {
    similarity, realScore, liveScore, age,
  } = useAgeDetectionStore((s) => s);
  const [formData, setFormData] = useState<AppealFormData>({
    email: '',
    message: '',
    idImageUrl: '',
    idImage: null,
    userImageUrl: '',
    userImage: null,
    agreedToTermsAndConditionsAndPrivacyPolicy: false,
  });
  const { t, i18n } = useTranslation();

  useEffect(() => {
    logger.info(loggerMessages.appealForm.info.mount, { aggregates: { isRetry } });
    logger.flush();
  }, [isRetry]);

  const setAppealFormData = useCallback((update: Partial<AppealFormData>) => {
    setFormData((prev) => ({
      ...prev,
      ...update,
    }));
  }, []);

  const uploadImages = useCallback(async () => {
    const urls: {
      idImageUrl: string;
      idImageFileId: null | number;
      userImageUrl: string;
      userImageFileId: null | number;
    } = {
      idImageUrl: '', idImageFileId: null, userImageUrl: '', userImageFileId: null,
    };

    if (formData.idImage === null || (requireManualReviewPhoto && formData.userImage === null)) return urls;

    try {
      const promises = [];
      const promiseIdImage = uploadFile(formData.idImage);
      promises.push(promiseIdImage);

      if (formData.userImage != null) {
        const promiseUserImage = uploadFile(formData.userImage);
        promises.push(promiseUserImage);
      }
      const result = await Promise.all(promises);

      const [idImage, userImage] = result;

      const addToDBPromises = [];

      if (idImage && idImage.fileId) {
        urls.idImageUrl = idImage.url;
        urls.idImageFileId = idImage.fileId;
        // add photo to license_urls table
        addToDBPromises.push(axios.post('/api/safe-passage/v1/trust-authority/license', {
          fileId: urls.idImageFileId, url: urls.idImageUrl, emblemState, uploadReason: 'manual-review',
        }));
      }

      if (userImage && userImage.fileId) {
        urls.userImageUrl = userImage.url;
        urls.userImageFileId = userImage.fileId;
        // add photo to license_urls table
        addToDBPromises.push(axios.post('/api/safe-passage/v1/trust-authority/license', {
          fileId: urls.userImageFileId, url: urls.userImageUrl, emblemState, uploadReason: 'manual-review',
        }));
      }

      await Promise.all(addToDBPromises);
    } catch (err) {
      console.error(err);
      logger.error(loggerMessages.appealForm.error.upload, {
        type: 'ERR_UPLOAD_IMAGE',
        errorMessage: getMessageFromError(err),
        tagRequestId,
        aggregates: { errorCode: UploadImageErrorCodes.ERR_UPLOAD_IMAGE },
      });
    }

    return urls;
  }, [emblemState, formData.idImage, formData.userImage, requireManualReviewPhoto, tagRequestId, uploadFile]);

  const handleSubmit: FormEventHandler<HTMLFormElement> = async (e) => {
    mixpanel.trackEvent({ event: 'Submit Manual Review' });
    e.preventDefault();

    if (!formData.idImage) {
      setFormStatus('error');
      return;
    }

    if (requireManualReviewPhoto && !formData.userImage) {
      setFormStatus('error');
      return;
    }

    setFormStatus('submitting');

    const urls = await uploadImages();

    if (urls.idImageUrl === '' || (requireManualReviewPhoto && urls.userImageUrl === '')) {
      setFormStatus('error');
      return;
    }

    logger.info(loggerMessages.appealForm.info.submit, {
      aggregates: {
        similarity, realScore, liveScore, age: age || 0,
      },
    });

    const language = i18n.language
      ? i18n.language.substring(0, 2)
      : 'en';

    try {
      await axios.post(
        `/api/safe-passage/v1/companies/${companySlug}/${tagRequestId}/support`,
        {
          state: emblemState,
          id: urls.idImageUrl,
          idFileId: urls.idImageFileId,
          email: formData.email,
          message: formData.message || 'No message provided.',
          data: JSON.stringify({
            similarity, realScore, liveScore, age,
          }),
          requireManualReviewPhoto: requireManualReviewPhoto.toString(),
          language,
          ...(requireManualReviewPhoto && { photo: urls.userImageUrl, photoFileId: urls.userImageFileId }),
        },
        {
          headers: {
            'Content-Type': 'application/json',
            'x-network-id': networkId,
          },
        },
      );
      setFormStatus('success');
      setStep('Success');
    } catch (err) {
      console.error(err);
      logger.error(loggerMessages.appealForm.error.submit, {
        type: 'ERR_APPEAL_FORM_SUBMIT',
        errorMessage: getMessageFromError(err),
        tagRequestId,
        aggregates: { errorCode: AppealFormErrorCodes.ERR_APPEAL_FORM_SUBMIT },
      });
      setFormStatus('error');
    }
  };

  useEffect(() => {
    if (!formStatus || formStatus === 'submitting') return () => { };

    const timeout = setTimeout(() => {
      setFormStatus('');
    }, 5000);

    return () => clearTimeout(timeout);
  }, [formStatus]);

  const StepComponent = STEP_COMPONENTS[step];

  return (
    <Fragment>
      <form className="w-full" onSubmit={handleSubmit}>
        {StepComponent && (
          <StepComponent
            setFormData={setAppealFormData}
            setStep={setStep}
            formData={formData}
            formStatus={formStatus}
          />
        )}
      </form>
      <Toast
        className={clsx('transition-opacity pointer-events-none text-black', {
          'opacity-0': formStatus !== 'error',
          'opacity-100': formStatus === 'error',
        })}
        icon={<ExclamationCircleIcon width={25} className="text-red-600" />}
        message={t('error.generic')}
      />
      <Toast
        className={clsx('transition-opacity pointer-events-none text-black', {
          'opacity-0': formStatus !== 'submitting',
          'opacity-100': formStatus === 'submitting',
        })}
        icon={<Loader className="text-primary-500 w-[25px]" />}
        message={t('appeal.processing')}
      />
    </Fragment>
  );
}

export default AppealForm;
