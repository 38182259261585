import { useState, useEffect } from 'react';

export interface ZXingInstance {
  readBarcodeFromPixmap: (buffer: number, width: number, height: number, tryHarder: boolean, barcodeType: string) => Record<string, string>;
  _malloc: (size: number) => number;
  _free: (buffer: number) => void;
  HEAPU8: {
    set: (sourceBuffer: Uint8ClampedArray, buffer: number) => void;
  };
}

declare global {
  interface Window {
    ZXing?: () => Promise<ZXingInstance>;
  }
}

const useZXing = ({ enabled = true } : { enabled?: boolean }) => {
  const [state, setState] = useState<[Error | null, ZXingInstance | null]>([null, null]);

  useEffect(() => {
    let exited: boolean | undefined;

    if (!enabled) {
      return undefined;
    }

    /**
    * ZXing is a WASM binary compiled from c++.
    * It has it's own wonky stuff for loading the file.
    * I've stored the reader.js and wasm in public/scripts.
    */
    if (!window.ZXing) {
      return undefined;
    }

    window.ZXing().then((instance: ZXingInstance) => {
      if (exited) {
        return;
      }

      setState([null, instance]);
    }).catch((err: Error) => {
      if (exited) {
        return;
      }

      setState([err, null]);
    });

    return () => {
      exited = true;
    };
  }, [enabled]);

  return state;
};

export default useZXing;
