import { useEffect } from 'react';
import mixpanel from 'services/mixpanel';
import useConfigStore from 'stores/configStore';

function useSetupMixpanel() {
  const networkId = useConfigStore((state) => state.networkId);
  useEffect(() => {
    if (!networkId) return;
    const searchParams = new URLSearchParams(window.location.search);
    const emblemStateParam = searchParams.get('emblemState');
    const tagRequestIdParam = searchParams.get('tagRequestId');
    const projectKeyParam = searchParams.get('projectKey');
    const companyNameParam = searchParams.get('companyName');
    mixpanel.init(networkId === 137, emblemStateParam || '');
    mixpanel.register({
      emblemState: emblemStateParam || '',
      tagRequestId: tagRequestIdParam || '',
      projectKey: projectKeyParam || '',
      companyName: companyNameParam || '',
    });
    mixpanel.trackEvent({ event: 'Safe Passage Start' });
  }, [networkId]);
}

export default useSetupMixpanel;
