function createCardDimensions(
  videoHeight: number,
  videoWidth: number,
  orientation: 'portrait' | 'landscape',
  ratio = 1.5882,
) {
  if (orientation === 'portrait') {
    const cardHeight = videoHeight * 0.9;
    const cardWidth = cardHeight / ratio;
    return { cardHeight, cardWidth };
  }

  const cardWidth = videoWidth * 0.9;
  const cardHeight = cardWidth / ratio;
  return { cardHeight, cardWidth };
}

export default createCardDimensions;
