import axios, { InternalAxiosRequestConfig } from 'axios';

const instance = axios.create({
  baseURL: process.env.REACT_APP_HOST ?? '',
});

const { CancelToken } = axios;

instance.interceptors.request.use((options: InternalAxiosRequestConfig) => {
  if (options?.signal && !options?.cancelToken) {
    const { signal } = options;
    const source = CancelToken.source();

    options.cancelToken = source.token;

    if (signal.addEventListener) {
      signal.addEventListener('abort', () => source.cancel());
    }
  }

  return options;
});

export { CancelToken };
export default instance;
