import { getMessageFromError } from 'utils/errorMessage';
import logger from './logger';

class PostAndReceiveWorker<T> {
  worker: Worker;
  name: string;

  constructor({ worker, name }: { worker: Worker, name: string }) {
    // eslint-disable-next-line
    // @ts-ignore
    this.worker = worker();
    this.name = name;
  }

  async postAndReceiveMessage(message: T, transfer?: Transferable[]): Promise<T> {
    return new Promise((res, rej) => {
      const channel = new MessageChannel();

      channel.port1.onmessage = ({ data }) => {
        channel.port1.close();
        if (data.error) {
          logger.info('message channel err', {
            eventMsg: JSON.stringify(data),
            name: this.name,
          });
          rej(data.error);
        } else {
          logger.info('message channel', {
            eventMsg: JSON.stringify(data),
            name: this.name,
          });
          res(data);
        }
      };

      try {
        logger.info('Posting message', { message: JSON.stringify(message), name: this.name });
        this.worker.postMessage(message, [...(transfer || []), channel.port2]);
      } catch (error) {
        const errorMessage = getMessageFromError(error);
        logger.error('Error posting message to worker:', { errorMessage, name: this.name });
        channel.port1.close(); // Ensure resources are cleaned up on error.
        rej(error);
      }
    });
  }
}

export default PostAndReceiveWorker;
