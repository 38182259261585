import React from 'react';

interface ArrowProps {
  children: React.ReactNode;
  className?: string;
}

function Arrow({ children, className = '' }: ArrowProps): JSX.Element {
  return (
    <div className={`absolute inset-0 z-1 overflow-hidden ${className}`}>
      <div className="absolute inset-0 z-2 flex justify-center items-center animate-ping">
        {children}
      </div>
      <div className="absolute inset-0 z-1 rounded-[100%] opacity-50 bg-transparent" />
    </div>
  );
}

export default Arrow;
