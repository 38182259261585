import React, { ReactNode } from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { baseClasses, buttonSizes, buttonVariants } from 'components/Button';

interface Props {
  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  children?: ReactNode;
}

function UploadPhoto({ handleChange, children }: Props) {
  const { t } = useTranslation();
  return (
    <label
      className={clsx(
        'my-4 mx-1 cursor-pointer text-center',
        baseClasses,
        buttonSizes.md,
        buttonVariants.secondary,
      )}
    >
      {children || t('uploadPhoto.uploadPhoto')}
      <input
        style={{ display: 'none' }}
        type="file"
        id="uploadPhoto"
        name="uploadPhoto"
        accept=".png,.jpg,.jpeg,capture=camera"
        onChange={handleChange}
      />
    </label>
  );
}

export default UploadPhoto;
