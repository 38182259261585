import React from 'react';
import { UrlObject } from 'routes/LicensePhotos';
import { useTranslation } from 'react-i18next';
import Text from './Text';
import AuthImage from './AuthImage';

export default function LicensePhoto({ license }: { license: UrlObject }) {
  const { t } = useTranslation();
  const color = license.ocr_status === 'pass' ? 'lawngreen' : 'red';
  return (
    <div key={license.id} className="max-w-xl flex flex-col justify-start items-start mb-8 border border-white rounded-lg p-1">
      <Text>
        {t('license.emblemState')}
        {' '}
        {license.emblemState}
      </Text>
      {license.upload_reason && (
        <Text>
          {t('license.uploadReason')}
          {' '}
          {license.upload_reason}
        </Text>
      )}
      {license.status && (
        <Text>
          {t('license.status')}
          {' '}
          {license.status}
        </Text>
      )}
      {license.ocr_status && (
        <Text>
          {t('license.ocrStatus')}
          {' '}
          <span style={{ color }}>{license.ocr_status}</span>
        </Text>
      )}
      {license.ocr_failure_reason && (
        <Text>
          {t('license.ocrFailureReason')}
          {' '}
          {license.ocr_failure_reason}
        </Text>
      )}
      <Text>
        {t('license.fileId')}
        {' '}
        {license.fileId}
      </Text>
      <Text>
        {t('license.createdAt')}
        {' '}
        {license.created_at}
      </Text>
      <AuthImage
        imageUrl={`/api/safe-passage/v1/asset?url=${encodeURIComponent(license.url)}`}
        className="w-full"
        alt="ID"
        loading="lazy"
      />
    </div>
  );
}
