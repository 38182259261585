import { createWorker, Worker } from 'tesseract.js';
import { useState, useEffect } from 'react';

/**
 * ==== NOTES ====
 *
 * Two base types of models are provided:
 *
 * >> "fast" is obviously more performant but will not be *AS* accurate as best. it's still quite good
 * in my tests. (10x smaller than best ~1mb)
 *
 * >> "best" is less performant but more accurate. I haven't encounted a reason to use it yet but we
 * have yet to test on a large set, so it's here for options! (10x larger than fast ~10mb)
 *
 * In total I have provided 4 models (2 best and 2 fast) each slightly trained differently.
 * I had been using the default (fast), however, I've found that the
 * alternate model (alt-fast) does seem to perform better and faster. Once again, we've got
 * options to test with.
 *
 */

const useTesseract = ({
  model = 'alt-fast',
  enabled = true,
} : {
  model?: 'fast' | 'best' | 'alt-fast' | 'alt-best',
  enabled?: boolean,
}) => {
  const [state, setState] = useState<[Error | null, Worker | null]>([null, null]);

  useEffect(() => {
    let exited: boolean | undefined;

    if (!enabled) {
      return undefined;
    }

    createWorker(`mrz-${model}`, 1, {
      langPath: '/models',
    }).then((worker: Worker) => {
      if (exited) {
        worker.terminate();
        return;
      }

      setState([null, worker]);
    }).catch((err: Error) => {
      if (exited) {
        return;
      }

      setState([err, null]);
    });

    return () => {
      exited = true;
    };
  }, [
    model,
    enabled,
  ]);

  return state;
};

export default useTesseract;
