import React from 'react';
import { AuthProvider } from 'react-oidc-context';
import { User, WebStorageStateStore } from 'oidc-client-ts';
import useFetchConfig from 'services/useFetchConfig';
import Loader from 'components/Loader';

const path = window.location.pathname;
const host = window.location.origin;
const redirectUri = `${host}${path}`;

interface Props {
  children: React.ReactNode;
}

function OIDCProvider({ children }: Props) {
  const {
    keycloakBaseUrl, realm, clientId, loading,
  } = useFetchConfig();

  /**
   * User is authenticated, redirect to remove OIDC params from URL
   */

  const onSigninCallback = (_user: void | User) => {
    window.history.replaceState(
      {},
      document.title,
      window.location.pathname,
    );
  };

  const redirect = `${redirectUri}${window.location.search}`;

  if (loading || !(keycloakBaseUrl && realm && clientId)) {
    return (
      <div className="flex justify-center items-center h-full">
        <Loader className="w-20 h-20" />
      </div>
    );
  }

  return (
    <AuthProvider
      authority={`${keycloakBaseUrl}/realms/${realm}`}
      client_id={clientId}
      response_type="code"
      redirect_uri={redirect}
      onSigninCallback={onSigninCallback}
      userStore={new WebStorageStateStore({ store: window.localStorage })}
    >
      {children}
    </AuthProvider>
  );
}

export default OIDCProvider;
