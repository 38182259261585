import React from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import localStorageUtil from 'utils/localStorageUtil';
import Logo from './Logo';
import Text from './Text';
import Button from './Button';

function Footer() {
  const { t, i18n } = useTranslation();
  const language = i18n.language.split('-')[0];
  const handleChangeLanguage = (lng: string) => {
    i18n.changeLanguage(lng);
    localStorageUtil.setValues({ lng });
  };

  const languageParam = language === 'en' ? '' : `/${language}`;
  return (
    <footer className="text-sm">
      <div className="p-2 mx-auto mb-2">
        <div className="flex flex-no-wrap justify-center text-neutral-400 mb-1">
          <Button
            variant="text"
            size="sm"
            className={clsx('max-w-fit', { 'text-white': language === 'en' })}
            onClick={() => { handleChangeLanguage('en'); }}
          >
            English
          </Button>
          <div className="my-auto text-secondary">|</div>
          <Button
            variant="text"
            size="sm"
            className={clsx('max-w-fit', { 'text-white': language === 'de' })}
            onClick={() => { handleChangeLanguage('de'); }}
          >
            Deutsch
          </Button>
          <div className="my-auto text-secondary">|</div>
          <Button
            variant="text"
            size="sm"
            className={language === 'es' ? 'text-white' : ''}
            onClick={() => { handleChangeLanguage('es'); }}
          >
            Español
          </Button>
          <div className="my-auto text-secondary">|</div>
          <Button
            variant="text"
            size="sm"
            className={language === 'fr' ? 'text-white' : ''}
            onClick={() => { handleChangeLanguage('fr'); }}
          >
            Français
          </Button>
        </div>
        <div className="flex gap-2 justify-center items-center mb-2">
          <div className="text-sm font-semibold">{t('footer.title')}</div>
          <Logo className="h-5 w-auto" />
        </div>
        <nav className="flex flex-wrap justify-center gap-4 text-neutral-400">
          <a href={`https://www.emblemapp.com/terms${languageParam}`} target="_blank" rel="noreferrer">{t('footer.terms')}</a>
          <a href={`https://www.emblemapp.com/privacy${languageParam}`} target="_blank" rel="noreferrer">{t('footer.privacy')}</a>
          <a href={`https://www.emblemapp.com/biometric${languageParam}`} target="_blank" rel="noreferrer">{t('footer.biometric')}</a>
        </nav>
        <Text size="xs" className="text-neutral-500 text-center">
          ©
          {' '}
          {t('footer.copyright')}
          {' '}
          {(new Date().getFullYear())}
          .
          {'  '}
          {t('footer.rights')}
        </Text>
      </div>
    </footer>
  );
}

export default Footer;
