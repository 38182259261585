import clsx from 'clsx';
import React, { ChangeEventHandler, ReactNode } from 'react';
import Text from './Text';

interface CheckboxProps {
  checked?: boolean
  onChange?: ChangeEventHandler<HTMLInputElement>
  label?: string
  className?: string
  description?: ReactNode
  labelClassName?: string
}

function Checkbox({
  label, checked, onChange, description, className, labelClassName = '',
}: CheckboxProps) {
  return (
    <div className={clsx('relative flex items-start', className)}>
      <div className="flex h-6 items-center mt-[1px]">
        <input
          id="comments"
          aria-describedby="comments-description"
          name="comments"
          type="checkbox"
          className="h-4 w-4 rounded border-gray-300 text-primary-600 focus:ring-primary-600"
          onChange={onChange}
          checked={checked}
        />
      </div>
      <div className="ml-3 text-sm leading-6">
        {label && <label htmlFor="comments" className={clsx('font-medium text-gray-900', labelClassName)}>{label}</label>}
        {description && <Text id="comments-description" className="text-gray-500">{description}</Text>}
      </div>
    </div>
  );
}

export default Checkbox;
