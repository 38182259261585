import React, { ElementType, ComponentPropsWithoutRef } from 'react';
import clsx from 'clsx';

export type ButtonProps<T extends ElementType> = {
  component?: T
  variant?: 'text' | 'primary' | 'white' | 'outline' | 'secondary';
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
  className?: string;
  children: React.ReactNode;
}

export const baseClasses = 'inline-flex justify-center rounded-full font-semibold outline-2 outline-offset-2 transition-colors relative overflow-hidden w-full md:w-fit text-center';

export const buttonSizes = {
  xs: 'px-2.5 py-1.5 text-xs',
  sm: 'px-3 py-2 leading-4 text-sm',
  md: 'px-16 py-4 text-base min-w-[19rem]',
  lg: 'px-16 py-4 text-lg min-w-[19rem]',
  xl: 'px-16 py-4 text-xl min-w-[19rem]',
};

export const buttonVariants = {
  text: [
    'text-primary-600',
    'hover:text-primary-500',
    'active:text-primary-700',
  ].join(' '),
  outline: [
    'bg-neutral-950',
    'border-2',
    'border-neutral-100',
    'font-normal',
    'text-neutral-200',
    'before:absolute',
    'before:inset-0',
    'active:before:bg-transparent',
    'hover:before:bg-neutral-100/10',
    'active:bg-secondary-600',
    'active:text-neutral-100/80',
    'before:transition-colors',
  ].join(' '),
  primary: [
    'bg-gradient-to-r',
    'from-primary',
    'to-primaryDark',
    'border',
    'border-white',
    'text-white',
    'before:absolute',
    'before:inset-0',
    'active:before:bg-transparent',
    'hover:before:bg-white/10',
    'active:bg-primary-700',
    'active:text-white/80',
    'before:transition-colors',
  ].join(' '),
  secondary: [
    'bg-secondary',
    'text-white',
    'before:absolute',
    'before:inset-0',
    'active:before:bg-transparent',
    'hover:before:bg-white/10',
    'active:bg-primary-700',
    'active:text-white/80',
    'before:transition-colors',
  ].join(' '),
  white: [
    'bg-white',
    'border-2',
    'border-[#020F50]',
    'text-[#020F50]',
    'before:absolute',
    'before:inset-0',
    'active:before:bg-transparent',
    'hover:before:bg-white/10',
    'active:bg-secondary-600',
    'active:text-white/80',
    'before:transition-colors',
  ].join(' '),
};

function Button<T extends ElementType = 'button'>({
  component,
  className,
  children,
  variant = 'primary',
  size = 'md',
  disabled,
  ...rest
}: ButtonProps<T> & Omit<ComponentPropsWithoutRef<T>, keyof ButtonProps<T>>) {
  const Component = component || 'button';
  return (
    <Component
      {...rest}
      disabled={disabled}
      className={clsx({
        'cursor-not-allowed': disabled,
        'opacity-50': disabled,
      }, baseClasses, buttonSizes[size], buttonVariants[variant], className)}
    >
      {children}
    </Component>
  );
}

export default Button;
