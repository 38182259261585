import React, { useMemo, memo } from 'react';
import arcGen from './arc-gen';

const colorMap: Record<'red' | 'green' | 'transparent', string> = {
  red: '#dc2626', // red 600
  green: '#059669', // emerald 600
  transparent: 'transparent',
};

interface ArcProps {
  id?: number;
  cx: number;
  cy: number;
  radius: number;
  startAngle: number;
  length: number;
  φ: number;
  color?: 'red' | 'green' | 'transparent';
  style?: React.CSSProperties;
  strokeWidth?: number;
  strokeLinecap?: 'butt' | 'round' | 'square';
  hidden?: boolean;
}

export function degreesToRadians(degrees: number): number {
  return (degrees * Math.PI) / 180;
}

function Arc({
  cx,
  cy,
  radius,
  startAngle,
  length,
  φ,
  color = 'red',
  strokeWidth = 8,
  strokeLinecap = 'round',
}: ArcProps): JSX.Element {
  const _cx = cx + strokeWidth;
  const _cy = cy + strokeWidth;

  const pathElement = useMemo(() => arcGen([_cx, _cy], [radius, radius], [startAngle, length], φ), [_cx, _cy, radius, startAngle, length, φ]);
  const d = pathElement.getAttribute('d') || '';

  const offset = strokeWidth * 2;

  const computedStyle: React.CSSProperties = useMemo(() => ({
    position: 'absolute',
    top: `${-strokeWidth}px`,
    left: `${-strokeWidth}px`,
    fill: 'none',
    width: `${_cx + radius + offset}px`,
    height: `${_cy + radius + offset}px`,
  }), [_cx, _cy, radius, offset, strokeWidth]);

  const stroke = colorMap[color];

  return (
    <svg
      style={computedStyle}
    >
      <path
        d={d}
        stroke={stroke}
        strokeWidth={strokeWidth}
        strokeLinecap={strokeLinecap}
      />
    </svg>
  );
}

export default memo(Arc);
