import React, {
  useCallback, useEffect, useId, useState, useRef, Fragment,
} from 'react';
import { detect } from 'detect-browser';
import Button from 'components/Button';
import LandscapeCardSVG from 'components/svgs/LandscapeCardSVG';
import stopStream from 'utils/stopStream';
import setVideoSource from 'utils/setVideoSource';
import drawRoundedRect from 'utils/drawRoundedRect';
import logger from 'services/logger';
import createCardDimensions from 'utils/createCardDimensions';
import useConfigStore from 'stores/configStore';
import { useTranslation } from 'react-i18next';
import PortraitCardSVG from './svgs/PortraitCardSVG';
import Text from './Text';

type FacingMode = 'user' | 'environment' | 'unknown' | null;
interface Props {
  fileUrl: string | null;
  setFile: (newFileUrl: File | null) => void;
  cancel?: false | (() => void);
  disableCamera?: boolean;
  videoCallback?: (video: HTMLVideoElement) => void;
  disableTakePhoto?: boolean;
  disableTakePhotoMessage?: string;
}

function TakePhoto({
  setFile,
  fileUrl,
  cancel = false,
  disableCamera = false,
  videoCallback,
  disableTakePhoto = false,
}: Props) {
  const id = useId();
  const videoRef = useRef<HTMLVideoElement | null>(null);
  const [videoElReady, setVideoElReady] = useState(false);
  const [orientation, setOrientation] = useState<'portrait' | 'landscape'>('landscape');
  const [videoSize, setVideoSize] = useState({ width: 0, height: 0 });
  const [isDesktop, setIsDesktop] = useState(false);
  const [facingMode, setFacingMode] = useState<FacingMode>(null);
  const useLicenseMask = useConfigStore((state) => state.useLicenseMask);
  const { t } = useTranslation();

  useEffect(() => {
    if (!videoRef.current) {
      return;
    }

    if (fileUrl || disableCamera) {
      return;
    }

    const video = videoRef.current;

    const awaitSetVideoSource = async () => {
      /**
       * We pass in 'environment' as the facingMode to setVideoSource because if
       * the user is on a mobile device, we want to use the front camera. If the
       * user is on a desktop, facingMode will be ignored.
       *
       * trueFacingMode will be 'environment' if the user is on a mobile device
       * or null if the user is on a desktop.
       *
       * We need to know which camera the user if using because we need to flip
       * the video element if the user is on desktop.
       */
      const trueFacingMode = await setVideoSource(video, 'environment', (err) => {
        console.error(err);
      }) as FacingMode;

      setFacingMode(trueFacingMode);
    };

    awaitSetVideoSource();
  }, [videoRef, fileUrl, disableCamera]);

  useEffect(() => () => {
    if (!fileUrl) {
      return;
    }
    const video = videoRef.current;
    if (!video) {
      return;
    }
    const mediaStream = video.srcObject as MediaStream;
    stopStream(mediaStream);
  }, [fileUrl]);

  useEffect(() => {
    const video = videoRef.current;
    return () => {
      if (!video) {
        return;
      }
      const mediaStream = video.srcObject as MediaStream;
      stopStream(mediaStream);
      video.srcObject = null;
    };
  }, []);

  useEffect(() => {
    if (videoElReady && videoRef.current) {
      // get height and width of video element
      const { clientHeight, clientWidth } = videoRef.current;
      setVideoSize({ width: clientWidth, height: clientHeight });
    }
  }, [videoElReady]);

  useEffect(() => {
    /**
     * Even though we set isDesktop based on the trueFacingMode, we still need
     * to make this check because firefox does not support MediaStreamTrack.getCapabilities()
     *
     * https://developer.mozilla.org/en-US/docs/Web/API/MediaStreamTrack/getCapabilities
     */
    const mobileOs = ['iOS', 'Android OS', 'BlackBerry OS', 'Windows Mobile'];
    const browser = detect();
    if (!browser) {
      return;
    }
    const desktop = browser.os && !mobileOs.includes(browser.os);
    logger.info('browser info', {
      os: browser.os, name: browser.name, version: browser.version, isDesktop: desktop,
    });
    if (desktop) {
      setIsDesktop(true);
    }
  }, []);

  useEffect(() => {
    // Recursively check to see if a face can be detected in the camera frame
    if (videoCallback && videoRef.current && videoElReady) {
      const recursiveDetect = async () => {
        if (!videoRef.current || fileUrl) {
          return;
        }

        try {
          videoCallback(videoRef.current);
        } catch (err) {
          console.error(err);
        } finally {
          setTimeout(() => {
            recursiveDetect();
          }, 200);
        }
      };
      recursiveDetect();
    }
  }, [videoCallback, videoElReady, fileUrl]);

  const snapPhoto = useCallback(() => {
    logger.info('Take photo button clicked');

    setFile(null);
    const canvas = document.createElement('canvas');
    const video = videoRef.current;
    if (!canvas || !video) {
      return;
    }
    const context = canvas.getContext('2d');
    const width = 1000;
    const height = (video.videoHeight / video.videoWidth) * width;

    canvas.width = width;
    canvas.height = height;
    context?.drawImage(video, 0, 0, width, height);

    const { cardHeight, cardWidth } = createCardDimensions(height + 1, width + 1, orientation);
    const maskX = (width / 2) - (cardWidth / 2);
    const maskY = (height / 2) - (cardHeight / 2);

    if (context && useLicenseMask) {
      const borderRadius = 2;

      context.fillStyle = 'rgba(0, 0, 0, 0.75)';

      // Draw the top border rectangle with rounded top corners
      drawRoundedRect(context, 0, 0, width, maskY, borderRadius, {
        topLeft: true, topRight: true, bottomLeft: false, bottomRight: false,
      });
      context.fill();

      // Draw the bottom border rectangle with rounded bottom corners
      drawRoundedRect(context, 0, maskY + cardHeight, width, height - (maskY + cardHeight), borderRadius, {
        topLeft: false, topRight: false, bottomLeft: true, bottomRight: true,
      });
      context.fill();

      // Draw the left border rectangle, no rounded corners
      drawRoundedRect(context, 0, maskY, maskX, cardHeight, borderRadius, {
        topLeft: false, topRight: false, bottomLeft: false, bottomRight: false,
      });
      context.fill();

      // Draw the right border rectangle, no rounded corners
      drawRoundedRect(context, maskX + cardWidth, maskY, width - (maskX + cardWidth), cardHeight, borderRadius, {
        topLeft: false, topRight: false, bottomLeft: false, bottomRight: false,
      });
      context.fill();
    }

    canvas.toBlob((blob) => {
      if (blob) {
        const imgFile = new File([blob], 'image.png', { type: 'image/png' });
        const mediaStream = video.srcObject as MediaStream;
        stopStream(mediaStream);
        setVideoElReady(false);
        setFile(imgFile);
      }
    });
  }, [setFile, videoRef, orientation, useLicenseMask]);

  const videoStyles = facingMode === 'environment' || !isDesktop
    ? {}
    : { transform: 'rotateY(180deg)' };

  return (
    <div className="flex flex-col w-full items-center">
      <div
        className="relative rounded-md bg-neutral-300 overflow-hidden aspect-[4/3]"
      >
        {!fileUrl && (
          <>
            <video
              id={id}
              ref={videoRef}
              data-testid="take-photo-video"
              className="w-full h-full object-cover"
              style={videoStyles}
              onLoadedMetadata={() => {
                setVideoElReady(true);
              }}
              playsInline
              autoPlay
              muted
            />
            {videoElReady && (
              <>
                <div
                  className="absolute w-full flex justify-center"
                  style={{
                    top: 0,
                    left: 0,
                    zIndex: 1,
                  }}
                >
                  {orientation === 'landscape' ? (
                    <LandscapeCardSVG
                      width={videoSize.width}
                      height={videoSize.height}
                      style={videoStyles}
                    />
                  ) : (
                    <PortraitCardSVG
                      width={videoSize.width}
                      height={videoSize.height}
                      style={videoStyles}
                    />
                  )}
                </div>
                <Button
                  type="button"
                  onClick={() => setOrientation((prev) => (prev === 'landscape' ? 'portrait' : 'landscape'))}
                  className="max-w-fit rounded-md"
                  style={{
                    zIndex: 2,
                    position: 'absolute',
                    bottom: '0.5rem',
                    left: '0.5rem',
                  }}
                  variant="secondary"
                  size="xs"
                >
                  {t('takePhoto.rotateGuide')}
                </Button>
              </>
            )}
          </>
        )}
      </div>

      {!fileUrl && (
        <Fragment>
          <Text className="my-4 leading-snug">{t('takePhoto.setIdFlat')}</Text>
          <button
            type="button"
            onClick={snapPhoto}
            disabled={!videoElReady || disableTakePhoto}
            className="rounded-full bg-white flex justify-center items-center active:opacity-50"
            style={{
              height: '64px',
              width: '64px',
            }}
          >
            <div
              className="rounded-full bg-transparent border-2 border-main"
              style={{
                height: '58px',
                width: '58px',
              }}
            />
          </button>
          {cancel && (
            <Button
              onClick={() => {
                const video = videoRef.current;
                if (!video) {
                  return;
                }

                const mediaStream = video.srcObject as MediaStream;
                stopStream(mediaStream);

                cancel();
              }}
              variant="white"
              className="mb-4"
            >
              {t('takePhoto.cancel')}
            </Button>
          )}
        </Fragment>
      )}
    </div>
  );
}

export default TakePhoto;
