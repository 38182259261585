import React, { Dispatch, useEffect, useState } from 'react';
import { CheckCircleIcon } from '@heroicons/react/24/solid';
import clsx from 'clsx';
import Button, { baseClasses, buttonSizes, buttonVariants } from 'components/Button';
import FullscreenLoader from 'components/FullscreenLoader';
import Text from 'components/Text';
import EmblemLogo from 'components/EmblemLogo';
import axios from 'services/api/axios';
import logger from 'services/logger';
import useFetchConfig from 'services/useFetchConfig';
import useAgeDetectionStore from 'stores/ageDetectionStore';
import useVerificationStore from 'stores/verificationStore';
import { MintErrorCodes, loggerMessages } from 'types/logger';
import { getMessageFromError } from 'utils/errorMessage';
import { Trans, useTranslation } from 'react-i18next';
import useConfigStore from 'stores/configStore';
import mixpanel from 'services/mixpanel';

interface SuccessProps {
  setMintStatus: Dispatch<React.SetStateAction<'loading' | 'success' | 'error'>>
  authenticated: boolean;
  mintStatus: 'loading' | 'success' | 'error';
}

function Success({ setMintStatus, authenticated, mintStatus }: SuccessProps) {
  const [creatingAccount, setCreatingAccount] = useState(false);
  const {
    emblemHost, loading, networkId, companySlug,
  } = useFetchConfig();
  const tagRequestId = useVerificationStore((s) => s.tagRequestId) || '';
  const isRetry = useAgeDetectionStore((s) => s.isRetry);
  const tag = useVerificationStore((s) => s.tag);
  const emblemState = useVerificationStore((s) => s.emblemState);
  const projectKey = useVerificationStore((s) => s.projectKey);
  const mobileDeepLink = useVerificationStore((s) => s.mobileDeepLink);
  const companyName = useVerificationStore((s) => s.companyName);
  const allowSkip = useConfigStore((s) => s.allowSkip);
  const { t, i18n } = useTranslation();
  const search = new URLSearchParams({
    isRetry: `${isRetry}`,
    tag,
    emblemState,
    projectKey,
    tagRequestId,
    lng: i18n.language,
    companyName,
    reportUsage: 'true',
  });
  const accessUrl = mobileDeepLink ? `${mobileDeepLink}?${search.toString()}` : `${emblemHost}/access?${search.toString()}`;

  useEffect(() => {
    logger.info(loggerMessages.phases.info.pass, { aggregates: { phaseProgress: true, isRetry } });
    logger.flush();
  }, []);

  useEffect(() => {
    if (allowSkip) {
      setMintStatus('success');
      return;
    }

    // The companySlug is still being fetched from the config
    // Or the use is creating an account and state has been reset
    if (loading || creatingAccount) {
      return;
    }

    if (!companySlug || !tagRequestId) {
      setMintStatus('error');
      logger.error(loggerMessages.mintTag.error.missingParams, {
        companySlug,
        tagRequestId,
      });
      return;
    }

    const setTagRequest = async () => {
      const body = { status: 'approved' };
      try {
        logger.info(loggerMessages.mintTag.info.mintStart);
        await axios.post(
          `/api/safe-passage/v1/trust-authority/companies/${companySlug}/requests/${tagRequestId}/mint`,
          body,
          {
            headers: {
              'Content-Type': 'application/json',
              'x-network-id': networkId,
            },
          },
        );
        logger.info(loggerMessages.mintTag.info.mintComplete);
        setMintStatus('success');
      } catch (err) {
        const errMsg = getMessageFromError(err);
        setMintStatus('error');
        console.error(err);
        logger.error(loggerMessages.mintTag.error.mintError, {
          type: 'ERR_MINT_ROUTE',
          errorMessage: errMsg,
          tagRequestId,
          aggregates: { errorCode: MintErrorCodes.ERR_MINT_ROUTE },
        });
      }
    };
    setTagRequest();
  }, [tagRequestId, networkId, companySlug, setMintStatus, allowSkip, loading, creatingAccount]);

  if (mintStatus === 'loading' || creatingAccount) {
    return <FullscreenLoader />;
  }

  return (
    <div className="flex flex-col flex-1 items-center">
      <div className="flex flex-1 flex-col items-center justify-center mb-6">
        <h1 className="text-center text-lg tracking-tight font-bold mb-4">
          {t('success.title')}
        </h1>
        <CheckCircleIcon className={clsx('w-32 h-32 border rounded-full text-green-400')} />
      </div>

      {!authenticated && (
        <>
          <Text size="lg" className="text-center">
            {t('success.saveTime')}
          </Text>
          <Button
            variant="secondary"
            onClick={() => {
              logger.info(loggerMessages.successPage.info.createAccount);
              logger.flush();
              mixpanel.trackEvent({ event: 'Create Account' });
              setCreatingAccount(true);
              const redirect = encodeURIComponent(accessUrl);
              window.location.href = `${emblemHost}/login?tagRequestId=${tagRequestId}&redirect=${redirect}&login_hint=signup&lng=${i18n.language}`;
            }}
            disabled={loading}
            className="items-center my-4"
            // using style because the class px-0 is being overwritten by the button component's px-16
            style={{ paddingRight: '0', paddingLeft: '0' }}
          >
            <EmblemLogo style={{ height: '22.5px', width: '77px', marginRight: '1rem' }} />
            {t('success.createAccount')}
          </Button>
        </>
      )}

      <a
        className={clsx(baseClasses, buttonSizes.md, buttonVariants.primary, 'mb-4')}
        onClick={() => {
          logger.info(loggerMessages.successPage.info.continue);
          logger.flush();
        }}
        href={accessUrl}
        id="continue-to-site-link"
      >
        {authenticated ? t('success.continueToSite') : t('success.skip')}
      </a>

      {!authenticated && (
        <Text size="sm" className="text-center mb-4">
          <Trans
            i18nKey="success.createAccountMessage"
            components={{ span: <span /> }}
          />
        </Text>
      )}
    </div>
  );
}

export default Success;
