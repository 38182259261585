async function setVideoSource(
  videoEl: HTMLVideoElement | null,
  facingMode: 'user' | 'environment',
  handleError: (err: Error) => void,
) {
  try {
    if (videoEl === null) {
      return null;
    }
    const stream = await navigator.mediaDevices?.getUserMedia({
      video: {
        facingMode,
      },
    });

    videoEl.srcObject = stream;
    const tracks = stream?.getTracks();

    if (tracks.length === 0) {
      return null;
    }

    /**
     * getCapabilities is not supported in Firefox
     */
    if (tracks[0].getCapabilities === undefined) {
      return 'unknown';
    }

    const capabilities = tracks[0].getCapabilities();

    const f = capabilities?.facingMode || [];
    if (f.length) {
      return f[0];
    }
    return null;
  } catch (err) {
    handleError(err as Error);
    return null;
  }
}

export default setVideoSource;
