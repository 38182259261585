import { Rect } from './types';

export const captureFrame = (source: HTMLCanvasElement, maxWidth: number, quality: number): string | undefined => {
  const canvas = document.createElement('canvas');
  const context = canvas.getContext('2d');

  const frameWidth = Math.min(source.width, maxWidth);
  const frameScale = frameWidth / source.width;
  const frameHeight = source.height * frameScale;

  canvas.width = frameWidth;
  canvas.height = frameHeight;

  if (!context) {
    return undefined;
  }

  context.drawImage(
    source,
    0,
    0,
    frameWidth,
    frameHeight,
  );

  return canvas.toDataURL('image/jpeg', quality);
};

export const easeOutSine = (t: number): number => Math.sin(t * (Math.PI / 2));

export const createFPSCounter = (): () => number => {
  let startTime = 0;
  let frames = 0;

  return () => {
    const now = Date.now();

    if (startTime === 0) {
      startTime = now;
    }

    frames += 1;

    return Math.round(frames / ((now - startTime) / 1000));
  };
};

export const drawRect = (context: CanvasRenderingContext2D, x: number, y: number, width: number, height: number, radius = 0) => {
  /**
   * Older browsers don't support roundRect within the canvas context
   */
  if (radius > 0 && context.roundRect) {
    context.roundRect(x, y, width, height, radius);
  } else {
    context.rect(x, y, width, height);
  }
};

export const drawVideo = (context: CanvasRenderingContext2D | OffscreenCanvasRenderingContext2D, video: HTMLVideoElement): Rect => {
  /**
   * Calculate and draw the "covered" video frame onto the canvas.
   */
  const { videoWidth, videoHeight } = video;
  const { width, height } = context.canvas;

  const videoRatio = videoWidth / videoHeight;
  const canvasRatio = width / height;
  const drawRatio = canvasRatio / videoRatio;

  const region: Rect = {
    x: 0,
    y: 0,
    width: 0,
    height: 0,
  };

  if (drawRatio >= 1) {
    region.width = width;
    region.height = width / videoRatio;
  } else {
    region.width = height * videoRatio;
    region.height = height;
  }

  region.y = (height - region.height) * 0.5;
  region.x = (width - region.width) * 0.5;

  context.drawImage(
    video,
    region.x,
    region.y,
    region.width,
    region.height,
  );

  return region;
};

export const drawMask = (context: CanvasRenderingContext2D, shape: Rect, radius = 0) => {
  const { width, height } = context.canvas;

  context.fillStyle = '#000';
  context.fillRect(0, 0, width, height);

  context.globalCompositeOperation = 'destination-out';

  context.beginPath();

  drawRect(context, shape.x, shape.y, shape.width, shape.height, radius);

  context.fill();

  context.globalCompositeOperation = 'source-over';
};

export default {
  drawMask,
  drawRect,
  drawVideo,
  easeOutSine,
  createFPSCounter,
};
