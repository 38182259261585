import React from 'react';
import Arrow from './Arrow';
import RoundedDoubleRightIcon from './RoundedDoubleRightIcon';

interface RightArrowProps {
  className?: string;
}

function RightArrow({ className }: RightArrowProps): JSX.Element {
  return (
    <Arrow className={className}>
      <RoundedDoubleRightIcon className="h-16 w-16 absolute z-20" />
    </Arrow>
  );
}

export default RightArrow;
