import React from 'react';

function LockSVG({ className = '' }) {
  return (
    <svg width="106" height="106" viewBox="0 0 106 106" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
      <path d="M53.0803 96.3227C76.611 96.3227 95.6863 77.2473 95.6863 53.7166C95.6863 30.186 76.611 11.1106 53.0803 11.1106C29.5496 11.1106 10.4742 30.186 10.4742 53.7166C10.4742 58.1182 11.1417 62.3639 12.3809 66.358" stroke="#96D3FF" strokeWidth="1.19531" strokeLinecap="round" />
      <path d="M25.7888 17.3342C33.3905 11.6229 42.8402 8.23853 53.0803 8.23853C71.6891 8.23853 87.6875 19.4151 94.7289 35.4216M98.3097 48.9297C98.4743 50.503 98.5587 52.1001 98.5587 53.7169C98.5587 78.8339 78.1973 99.1953 53.0803 99.1953C27.9633 99.1953 7.60193 78.8339 7.60193 53.7169C7.60193 41.9144 12.0979 31.162 19.4705 23.0788" stroke="white" strokeOpacity="0.7" strokeWidth="0.398438" strokeLinecap="round" />
      <path d="M53 2C24.8335 2 2 24.8335 2 53C2 81.1665 24.8335 104 53 104C81.1665 104 104 81.1665 104 53C104 48.1151 103.313 43.3907 102.031 38.9179" stroke="url(#paint0_linear_902_1890)" strokeOpacity="0.8" strokeWidth="2.18571" strokeLinecap="round" />
      <g filter="url(#filter0_f_902_1890)">
        <path fillRule="evenodd" clipRule="evenodd" d="M27.2183 33.6136C27.2478 32.9425 27.7617 32.3752 28.4279 32.2885C38.2334 31.0121 43.6953 28.8163 51.7773 22.5885C52.2805 22.2006 52.9844 22.2028 53.4871 22.5913C60.9106 28.329 66.8575 30.7734 77.1265 32.4836C77.7656 32.59 78.2494 33.1393 78.2837 33.7862C79.8297 63.0246 80.3791 71.3255 53.2834 85.3371C52.8848 85.5432 52.3827 85.5389 51.9874 85.3266C25.1201 70.8973 25.8854 63.8985 27.2183 33.6136ZM51.884 25.8508C52.3388 25.5473 52.9314 25.5417 53.3929 25.8348C61.5324 31.0045 66.4775 33.1211 74.5877 34.5301C75.2371 34.6429 75.7245 35.2107 75.7419 35.8696C76.4829 63.8801 75.4241 70.4032 53.2451 81.9278C52.8668 82.1244 52.3965 82.1335 52.012 81.9494C30.6123 71.7027 28.1544 62.6 29.7164 35.8373C29.7541 35.192 30.2419 34.6497 30.8799 34.545C40.4887 32.9678 44.9605 30.4719 51.884 25.8508Z" fill="#E3F5FF" />
        <path fillRule="evenodd" clipRule="evenodd" d="M27.2183 33.6136C27.2478 32.9425 27.7617 32.3752 28.4279 32.2885C38.2334 31.0121 43.6953 28.8163 51.7773 22.5885C52.2805 22.2006 52.9844 22.2028 53.4871 22.5913C60.9106 28.329 66.8575 30.7734 77.1265 32.4836C77.7656 32.59 78.2494 33.1393 78.2837 33.7862C79.8297 63.0246 80.3791 71.3255 53.2834 85.3371C52.8848 85.5432 52.3827 85.5389 51.9874 85.3266C25.1201 70.8973 25.8854 63.8985 27.2183 33.6136ZM51.884 25.8508C52.3388 25.5473 52.9314 25.5417 53.3929 25.8348C61.5324 31.0045 66.4775 33.1211 74.5877 34.5301C75.2371 34.6429 75.7245 35.2107 75.7419 35.8696C76.4829 63.8801 75.4241 70.4032 53.2451 81.9278C52.8668 82.1244 52.3965 82.1335 52.012 81.9494C30.6123 71.7027 28.1544 62.6 29.7164 35.8373C29.7541 35.192 30.2419 34.6497 30.8799 34.545C40.4887 32.9678 44.9605 30.4719 51.884 25.8508Z" stroke="white" strokeOpacity="0.3" strokeWidth="0.320303" />
      </g>
      <path fillRule="evenodd" clipRule="evenodd" d="M27.2183 33.6136C27.2478 32.9425 27.7617 32.3752 28.4279 32.2885C38.2334 31.0121 43.6953 28.8163 51.7773 22.5885C52.2806 22.2006 52.9844 22.2028 53.4871 22.5913C60.9106 28.329 66.8575 30.7734 77.1265 32.4836C77.7656 32.59 78.2494 33.1393 78.2837 33.7862C79.8297 63.0246 80.3791 71.3255 53.2834 85.3371C52.8848 85.5432 52.3827 85.5389 51.9874 85.3266C25.1201 70.8973 25.8854 63.8985 27.2183 33.6136ZM51.884 25.8508C52.3388 25.5473 52.9314 25.5417 53.3929 25.8348C61.5324 31.0045 66.4775 33.1211 74.5877 34.5301C75.2371 34.6429 75.7245 35.2107 75.7419 35.8696C76.4829 63.8801 75.4241 70.4032 53.2451 81.9278C52.8668 82.1244 52.3965 82.1335 52.012 81.9494C30.6123 71.7027 28.1544 62.6 29.7164 35.8373C29.7541 35.192 30.2419 34.6497 30.8799 34.545C40.4887 32.9678 44.9605 30.4719 51.884 25.8508Z" fill="#D9F2FF" />
      <path d="M51.884 25.8508C52.3388 25.5473 52.9314 25.5417 53.3929 25.8348C61.5324 31.0045 66.4775 33.1211 74.5877 34.5301C75.2371 34.6429 75.7245 35.2107 75.7419 35.8696C76.4829 63.8801 75.4241 70.4032 53.2451 81.9278C52.8668 82.1244 52.3965 82.1335 52.012 81.9494C30.6123 71.7027 28.1544 62.6 29.7164 35.8373C29.7541 35.192 30.2419 34.6497 30.8799 34.545C40.4887 32.9678 44.9605 30.4719 51.884 25.8508Z" fill="#1E344D" />
      <path d="M51.884 25.8508C52.3388 25.5473 52.9314 25.5417 53.3929 25.8348C61.5324 31.0045 66.4775 33.1211 74.5877 34.5301C75.2371 34.6429 75.7245 35.2107 75.7419 35.8696C76.4829 63.8801 75.4241 70.4032 53.2451 81.9278C52.8668 82.1244 52.3965 82.1335 52.012 81.9494C30.6123 71.7027 28.1544 62.6 29.7164 35.8373C29.7541 35.192 30.2419 34.6497 30.8799 34.545C40.4887 32.9678 44.9605 30.4719 51.884 25.8508Z" fill="url(#paint1_radial_902_1890)" fillOpacity="0.3" />
      <path d="M51.884 25.8508C52.3388 25.5473 52.9314 25.5417 53.3929 25.8348C61.5324 31.0045 66.4775 33.1211 74.5877 34.5301C75.2371 34.6429 75.7245 35.2107 75.7419 35.8696C76.4829 63.8801 75.4241 70.4032 53.2451 81.9278C52.8668 82.1244 52.3965 82.1335 52.012 81.9494C30.6123 71.7027 28.1544 62.6 29.7164 35.8373C29.7541 35.192 30.2419 34.6497 30.8799 34.545C40.4887 32.9678 44.9605 30.4719 51.884 25.8508Z" fill="url(#paint2_radial_902_1890)" fillOpacity="0.2" />
      <path d="M51.884 25.8508C52.3388 25.5473 52.9314 25.5417 53.3929 25.8348C61.5324 31.0045 66.4775 33.1211 74.5877 34.5301C75.2371 34.6429 75.7245 35.2107 75.7419 35.8696C76.4829 63.8801 75.4241 70.4032 53.2451 81.9278C52.8668 82.1244 52.3965 82.1335 52.012 81.9494C30.6123 71.7027 28.1544 62.6 29.7164 35.8373C29.7541 35.192 30.2419 34.6497 30.8799 34.545C40.4887 32.9678 44.9605 30.4719 51.884 25.8508Z" fill="url(#paint3_radial_902_1890)" fillOpacity="0.3" />
      <path d="M51.884 25.8508C52.3388 25.5473 52.9314 25.5417 53.3929 25.8348C61.5324 31.0045 66.4775 33.1211 74.5877 34.5301C75.2371 34.6429 75.7245 35.2107 75.7419 35.8696C76.4829 63.8801 75.4241 70.4032 53.2451 81.9278C52.8668 82.1244 52.3965 82.1335 52.012 81.9494C30.6123 71.7027 28.1544 62.6 29.7164 35.8373C29.7541 35.192 30.2419 34.6497 30.8799 34.545C40.4887 32.9678 44.9605 30.4719 51.884 25.8508Z" fill="url(#paint4_radial_902_1890)" fillOpacity="0.3" />
      <path fillRule="evenodd" clipRule="evenodd" d="M27.2183 33.6136C27.2478 32.9425 27.7617 32.3752 28.4279 32.2885C38.2334 31.0121 43.6953 28.8163 51.7773 22.5885C52.2806 22.2006 52.9844 22.2028 53.4871 22.5913C60.9106 28.329 66.8575 30.7734 77.1265 32.4836C77.7656 32.59 78.2494 33.1393 78.2837 33.7862C79.8297 63.0246 80.3791 71.3255 53.2834 85.3371C52.8848 85.5432 52.3827 85.5389 51.9874 85.3266C25.1201 70.8973 25.8854 63.8985 27.2183 33.6136ZM51.884 25.8508C52.3388 25.5473 52.9314 25.5417 53.3929 25.8348C61.5324 31.0045 66.4775 33.1211 74.5877 34.5301C75.2371 34.6429 75.7245 35.2107 75.7419 35.8696C76.4829 63.8801 75.4241 70.4032 53.2451 81.9278C52.8668 82.1244 52.3965 82.1335 52.012 81.9494C30.6123 71.7027 28.1544 62.6 29.7164 35.8373C29.7541 35.192 30.2419 34.6497 30.8799 34.545C40.4887 32.9678 44.9605 30.4719 51.884 25.8508Z" stroke="white" strokeOpacity="0.4" strokeWidth="0.320303" />
      <path d="M51.884 25.8508C52.3388 25.5473 52.9314 25.5417 53.3929 25.8348C61.5324 31.0045 66.4775 33.1211 74.5877 34.5301C75.2371 34.6429 75.7245 35.2107 75.7419 35.8696C76.4829 63.8801 75.4241 70.4032 53.2451 81.9278C52.8668 82.1244 52.3965 82.1335 52.012 81.9494C30.6123 71.7027 28.1544 62.6 29.7164 35.8373C29.7541 35.192 30.2419 34.6497 30.8799 34.545C40.4887 32.9678 44.9605 30.4719 51.884 25.8508Z" stroke="white" strokeOpacity="0.4" strokeWidth="0.320303" />
      <g filter="url(#filter1_i_902_1890)">
        <path d="M52.3985 35.5825C43.6166 35.5825 44.9176 48.2675 44.9176 48.2675H47.1944C47.1944 48.2675 45.8934 37.8593 52.3985 37.8593C58.9036 37.8593 57.9278 48.2675 57.9278 48.2675H60.2046C60.2046 48.2675 61.1803 35.5825 52.3985 35.5825Z" fill="url(#paint5_linear_902_1890)" />
        <path fillRule="evenodd" clipRule="evenodd" d="M61.1803 63.8797C62.2581 63.8797 63.1319 63.006 63.1319 61.9282V50.219C63.1319 49.1412 62.2581 48.2675 61.1803 48.2675H60.2046H57.9278H47.1944H44.9176H43.6166C42.5388 48.2675 41.665 49.1412 41.665 50.219V61.9282C41.665 63.006 42.5388 63.8797 43.6166 63.8797H61.1803ZM55.3258 54.1221C55.3258 52.821 54.8646 51.52 52.7237 51.52C50.5828 51.52 49.7964 52.4958 49.7964 54.1221C49.7964 55.0022 50.1775 55.5965 50.5271 55.9566C50.841 56.2799 51.1223 56.6719 51.1631 57.1206L51.4147 59.8885C51.42 59.9472 51.4221 60.0063 51.4309 60.0646C51.4673 60.3045 51.6635 60.9524 52.7237 60.9524C54.0247 60.9524 54.0247 59.9767 54.0247 59.9767C54.0247 59.9767 54.0247 59.9767 54.0247 59.9767V57.0494C54.0247 56.6399 54.2227 56.257 54.4988 55.9546C54.8706 55.5473 55.3258 54.8916 55.3258 54.1221Z" fill="url(#paint6_linear_902_1890)" />
      </g>
      <path d="M44.9176 48.2675H43.6166C42.5388 48.2675 41.665 49.1412 41.665 50.219V61.9282C41.665 63.006 42.5388 63.8797 43.6166 63.8797H61.1803C62.2581 63.8797 63.1319 63.006 63.1319 61.9282V50.219C63.1319 49.1412 62.2581 48.2675 61.1803 48.2675H60.2046M44.9176 48.2675C44.9176 48.2675 43.6166 35.5825 52.3985 35.5825C61.1803 35.5825 60.2046 48.2675 60.2046 48.2675M44.9176 48.2675H47.1944M60.2046 48.2675H57.9278M57.9278 48.2675C57.9278 48.2675 58.9036 37.8593 52.3985 37.8593C45.8934 37.8593 47.1944 48.2675 47.1944 48.2675M57.9278 48.2675H47.1944M52.7237 51.52C54.8646 51.52 55.3258 52.821 55.3258 54.1221C55.3258 54.8916 54.8706 55.5473 54.4988 55.9546C54.2227 56.257 54.0247 56.6399 54.0247 57.0494V59.9767C54.0247 59.9767 54.0247 59.9767 54.0247 59.9767C54.0247 59.9767 54.0247 60.9524 52.7237 60.9524C51.6635 60.9524 51.4673 60.3045 51.4309 60.0646C51.4221 60.0063 51.42 59.9472 51.4147 59.8885L51.1631 57.1206C51.1223 56.6719 50.841 56.2799 50.5271 55.9566C50.1775 55.5965 49.7964 55.0022 49.7964 54.1221C49.7964 52.4958 50.5828 51.52 52.7237 51.52Z" stroke="#AACFE0" strokeWidth="0.325255" />
      <path d="M41.6367 61.4699L43.5692 63.8118M41.6367 59.734L45.447 63.8118M41.6367 57.7087L47.3249 63.8118M42.3955 63.5771L45.9165 60.5255M41.926 62.3379L44.9776 59.5866M44.0386 63.8118L46.8554 61.4645M44.0386 58.6477L41.6367 60.8913M47.7944 62.4034L46.1512 63.8118M43.0997 57.7087L41.6367 59.1553" stroke="url(#paint7_linear_902_1890)" strokeWidth="0.0938932" strokeLinecap="round" />
      <path d="M63.0911 50.4091L61.3774 48.4506M63.0911 52.3676L59.4189 48.4506M63.0911 54.3261L57.4604 48.4506M62.6014 48.6954L58.9293 51.8779M63.0911 49.9194L59.9085 52.8572M60.8878 48.4506L57.95 50.8987M60.8878 53.8364L63.0911 51.8779M56.9708 49.9194L58.6845 48.4506M61.867 54.8157L63.0911 53.5916" stroke="url(#paint8_linear_902_1890)" strokeWidth="0.0979246" strokeLinecap="round" />
      <defs>
        <filter id="filter0_f_902_1890" x="18.4191" y="14.0148" width="68.7442" height="79.758" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur stdDeviation="4.06195" result="effect1_foregroundBlur_902_1890" />
        </filter>
        <filter id="filter1_i_902_1890" x="41.5024" y="35.4199" width="21.7921" height="31.2244" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
          <feOffset dy="2.60204" />
          <feGaussianBlur stdDeviation="1.30102" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.0840683 0 0 0 0 0.0840683 0 0 0 0 0.0840683 0 0 0 0.25 0" />
          <feBlend mode="normal" in2="shape" result="effect1_innerShadow_902_1890" />
        </filter>
        <linearGradient id="paint0_linear_902_1890" x1="18.2437" y1="48.4549" x2="102.195" y2="43.0708" gradientUnits="userSpaceOnUse">
          <stop offset="0.397778" stopColor="white" />
          <stop offset="1" stopColor="white" stopOpacity="0" />
        </linearGradient>
        <radialGradient id="paint1_radial_902_1890" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(62.4812 77.5405) rotate(-86.9471) scale(24.4288 19.9937)">
          <stop stopColor="#485DCB" />
          <stop offset="1" stopColor="#5F6CE2" stopOpacity="0" />
        </radialGradient>
        <radialGradient id="paint2_radial_902_1890" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(39.7133 75.2637) rotate(-72.5176) scale(43.3078 35.4453)">
          <stop stopColor="#569EC5" />
          <stop offset="1" stopColor="#569EC5" stopOpacity="0" />
        </radialGradient>
        <radialGradient id="paint3_radial_902_1890" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(39.7133 75.2637) rotate(-68.1986) scale(35.0311 28.6711)">
          <stop stopColor="#6AAFD4" />
          <stop offset="1" stopColor="#6CB2D4" stopOpacity="0" />
        </radialGradient>
        <radialGradient id="paint4_radial_902_1890" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(39.7133 73.9627) rotate(-69.6769) scale(18.7297 17.4434)">
          <stop stopColor="#A2DFFF" />
          <stop offset="1" stopColor="#95D9FA" stopOpacity="0" />
        </radialGradient>
        <linearGradient id="paint5_linear_902_1890" x1="53.049" y1="36.233" x2="52.3985" y2="48.2675" gradientUnits="userSpaceOnUse">
          <stop stopColor="#5B99CE" />
          <stop offset="0.994551" stopColor="#265C9F" />
        </linearGradient>
        <linearGradient id="paint6_linear_902_1890" x1="52.7237" y1="48.918" x2="52.7237" y2="63.5545" gradientUnits="userSpaceOnUse">
          <stop stopColor="#356DB2" />
          <stop offset="1" stopColor="#5B99CE" />
        </linearGradient>
        <linearGradient id="paint7_linear_902_1890" x1="45.1086" y1="59.734" x2="41.926" y2="63.2058" gradientUnits="userSpaceOnUse">
          <stop stopColor="white" stopOpacity="0" />
          <stop offset="1" stopColor="#AACFE0" />
        </linearGradient>
        <linearGradient id="paint8_linear_902_1890" x1="59.9085" y1="52.8572" x2="62.8463" y2="49.185" gradientUnits="userSpaceOnUse">
          <stop stopColor="white" stopOpacity="0" />
          <stop offset="1" stopColor="#AACFE0" />
        </linearGradient>
      </defs>
    </svg>

  );
}

export default LockSVG;
