const { cos, sin, PI: π } = Math;

export type Matrix = [number, number][];
export type Vector = [number, number];

/**
 * Multiplies a matrix by a vector.
 */
function matrixTimes(matrix: Matrix, vector: Vector): Vector {
  const [[a, b], [c, d]] = matrix;
  const [x, y] = vector;
  return [a * x + b * y, c * x + d * y];
}

/**
 * Returns a rotation matrix for a given angle.
 */
function rotateMatrix(angle: number): Matrix {
  return [
    [cos(angle), -sin(angle)],
    [sin(angle), cos(angle)],
  ];
}

/**
 * Adds two 2D vectors.
 */
function vecAdd(vec1: Vector, vec2: Vector): Vector {
  const [a1, a2] = vec1;
  const [b1, b2] = vec2;
  return [a1 + b1, a2 + b2];
}

/**
 * Creates an SVG path element that represents an ellipse arc.
 */
function arcGen(center: Vector, radii: Vector, angles: Vector, φ: number): SVGPathElement {
  const t1 = angles[0];
  let Δ = angles[1];
  Δ %= (2 * π);
  const rotMatrix = rotateMatrix(φ);

  const startVec = [radii[0] * cos(t1), radii[1] * sin(t1)] as Vector;
  const [sX, sY] = vecAdd(matrixTimes(rotMatrix, startVec), center);

  const endVec = [radii[0] * cos(t1 + Δ), radii[1] * sin(t1 + Δ)] as Vector;
  const [eX, eY] = vecAdd(matrixTimes(rotMatrix, endVec), center);

  const fA = Δ > π ? 1 : 0;
  const fS = Δ > 0 ? 1 : 0;

  const path = document.createElementNS('http://www.w3.org/2000/svg', 'path');
  path.setAttribute('d', `M ${sX} ${sY} A ${radii[0]} ${radii[1]} ${((φ / (2 * π)) * 360)} ${fA} ${fS} ${eX} ${eY}`);

  return path;
}

export default arcGen;
