/* eslint-disable no-await-in-loop */
import logger from 'services/logger';
import { ResizeImageErrorCodes, loggerMessages } from 'types/logger';

interface ResizeImageOptions {
  maxFileSize: number;
  imageType: 'png' | 'jpeg';
  maxRetries: number;
}

const defaultOptions: ResizeImageOptions = { maxFileSize: 0, imageType: 'png', maxRetries: 10 };

/**
 * Resize image with the goal of reducing file size
 */
async function resizeImage(
  fileUrl: string,
  _resizeOptions?: Partial<ResizeImageOptions>,
) {
  const { maxFileSize, imageType, maxRetries } = { ...defaultOptions, ..._resizeOptions };
  const canvas = document.createElement('canvas');
  const image = new Image();
  image.src = fileUrl;
  const response = await fetch(fileUrl);
  const imgBlob = await response.blob();

  let { width, height } = image;
  const startSize = imgBlob.size;

  if (startSize <= maxFileSize) {
    logger.debug('Image not resized', {
      aggregates: {
        size: startSize, width, height,
      },
    });
    return fileUrl;
  }

  let fileSize = startSize;
  let attempt = 0;
  let newUrl = fileUrl;
  const newMax = maxFileSize || startSize * 0.85;

  while (fileSize > newMax && attempt <= maxRetries) {
    attempt += 1;
    width *= 0.85;
    height *= 0.85;
    canvas.width = width;
    canvas.height = height;
    const ctx = canvas.getContext('2d');
    if (!ctx) {
      break;
    }

    ctx.drawImage(image, 0, 0, width, height);
    newUrl = canvas.toDataURL(`image/${imageType}`);
    const r = await fetch(newUrl);
    const blob = await r.blob();
    fileSize = blob.size;
  }

  if (fileSize > newMax && attempt > maxRetries) {
    logger.warn(loggerMessages.resizeImage.warn.tooLarge, {
      aggregates: {
        startSize,
        newSize: fileSize,
        attempt,
        width,
        height,
        errorCode: ResizeImageErrorCodes.ERR_RESIZE_IMAGE,
      },
    });
    throw new Error('Image is too large');
  }

  logger.debug('Images size reduced', {
    aggregates: {
      startSize,
      newSize: fileSize,
      attempt,
      maxRetries,
      width,
      height,
    },
  });
  return newUrl;
}

export default resizeImage;
