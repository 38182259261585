import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useAuth, AuthProviderProps } from 'react-oidc-context';
import Button from 'components/Button';
import Text from 'components/Text';
import OIDCProvider from './OIDCProvider';

interface AuthenticationProps {
  children: JSX.Element;
}

function Authentication({ children }: AuthenticationProps) {
  const auth = useAuth();
  const location = useLocation();
  const signInRequired = !auth.isAuthenticated;
  const routePath = `${window.location.origin}${location.pathname}${location.search}`;

  useEffect(() => {
    if (!auth.isAuthenticated && !auth.isLoading && !auth.error) {
      auth.signinRedirect({ redirect_uri: routePath });
    }
  }, [auth, routePath]);

  if (auth.isLoading) {
    let message = '';
    switch (auth.activeNavigator) {
      case 'signinRedirect':
        message = 'Redirecting to sign in...';
        break;
      case 'signoutSilent':
        message = 'Signing you out...';
        break;
      default:
    }

    return (
      <div className="h-screen bg-gray-100 px-8 flex flex-col justify-center items-center">
        {!!message && <Text className="mb-4">{message}</Text>}
        <div>
          <h2>Loading</h2>
        </div>
      </div>
    );
  }

  if (signInRequired && auth.error) {
    return (
      <div className="h-screen bg-black px-8 flex justify-center items-center">
        <div className="max-w-screen-lg lg:min-w-[70rem] md:min-w-[100%] mx-auto">
          <h2>{auth.error.message}</h2>
          <div className="flex justify-center">
            <Button onClick={() => auth.signinRedirect({ redirect_uri: routePath })}>
              Log in
            </Button>
          </div>
        </div>
      </div>
    );
  }

  /**
   * User is authenticated and ready to go
   */
  return children;
}

type AuthenticationProviderProps = AuthenticationProps & AuthProviderProps;

function AuthenticationProvider({ children }: AuthenticationProviderProps) {
  return (
    <OIDCProvider>
      <Authentication>
        {children}
      </Authentication>
    </OIDCProvider>
  );
}

export default AuthenticationProvider;
