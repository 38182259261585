import React, {
  useState, useEffect, useCallback, FormEvent, useRef,
} from 'react';
import { useNavigate, Navigate } from 'react-router-dom';
import { FaceResult } from '@vladmandic/human';

import Button from 'components/Button';
import TakePhoto from 'components/TakePhoto';
import { useHumanAgePredictor } from 'contexts/HumanAgePredictionContext';

import useAgeDetectionStore from 'stores/ageDetectionStore';
import useVerificationStore from 'stores/verificationStore';
import useConfigStore from 'stores/configStore';

import axios from 'services/api/axios';
import logger from 'services/logger';
import mixpanel from 'services/mixpanel';
import { getMessageFromError } from 'utils/errorMessage';
import { VerifyIdErrorCodes, errorMessages, loggerMessages } from 'types/logger';
import useFileReader from 'hooks/useFileReader';
import Text from 'components/Text';
import Loader from 'components/Loader';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import MainLayout from 'layouts/MainLayout';

interface OCRData {
  text: string;
  error: null | { code: number; type: string; message: string };
  dates: string[];
  mugshot: string | null;
  verified: boolean;
}

const CLIENT_SIDE_TAG = '18+_idai';
const messageClasses = 'transition-[opacity] ease-in-out duration-500 delay-500 absolute rounded-lg bottom-4 left-4 right-4 p-4';

function VerifyIdRoute() {
  const [submitLoading, setSubmitLoading] = useState(false);
  const imgRef = useRef<HTMLImageElement | null>(null);
  const [faceDetected, setFaceDetected] = useState<null | false | FaceResult>(null);
  const [faceMatches, setFaceMatches] = useState<boolean | null>(null);
  const [error, setError] = useState<string | null>(null);

  const human = useHumanAgePredictor();

  const allowSkip = useConfigStore((state) => state.allowSkip);
  const faces = useAgeDetectionStore((state) => state.faces);
  const requireFaceDetection = useConfigStore((state) => state.humanConfig.licensePhoto.requireFaceDetection);
  const emblemState = useVerificationStore((state) => state.emblemState);
  const projectKey = useVerificationStore((state) => state.projectKey);
  const documentType = useVerificationStore((state) => state.documentType);
  const tag = useVerificationStore((state) => state.tag);
  const shouldOCR = useVerificationStore((state) => state.shouldOCR) && tag !== CLIENT_SIDE_TAG;
  const isRetry = useAgeDetectionStore((s) => s.isRetry);
  const setImageId = useAgeDetectionStore((state) => state.setImageId);
  const setDocumentType = useVerificationStore((state) => state.setDocumentType);
  const setVerificationFailure = useVerificationStore((state) => state.setVerificationFailure);
  const setSimilarity = useAgeDetectionStore((state) => state.setSimilarity);

  const navigate = useNavigate();
  const { t } = useTranslation();

  useEffect(() => {
    logger.info(loggerMessages.phases.info.verifyId, { aggregates: { phaseProgress: true, isRetry } });
    logger.flush();
  }, []);

  const {
    file,
    setFile,
    fileUrl,
    setFileUrl,
    imgLoading,
    error: imgError,
  } = useFileReader();

  useEffect(() => {
    if (imgError) {
      setError(imgError);
      setFile(null);
      setFileUrl(null);
    }
  }, [imgError, setFile, setFileUrl]);

  useEffect(() => {
    if (fileUrl === null) {
      setFaceDetected(null);
    }
  }, [fileUrl]);

  useEffect(() => {
    // Cannot compare ID to snapshots age detection if faces is empty
    // Send back to the beginning
    if (!faces.length && !allowSkip) {
      logger.warn(loggerMessages.verifyId.warn.noFaces);
      navigate('/');
    }
  }, [faces, navigate, allowSkip]);

  const handleSubmit = useCallback(async () => {
    mixpanel.trackOCR({ event: 'Submit ID for OCR' });
    setError(null);
    setSubmitLoading(true);

    logger.info(loggerMessages.verifyId.info.idPhotoSubmitted);

    if (!file) {
      logger.warn('No file selected');
      return;
    }

    const reset = () => {
      setDocumentType('');
      setVerificationFailure(true);
      navigate('/select-doc-type');
    };

    try {
      const formData = new FormData();
      formData.append('file', file);
      formData.append('emblemState', emblemState);
      formData.append('projectKey', projectKey);

      const res = await axios.post('/license-identification/api/v1/process', formData);
      const ocrData: OCRData = res.data;

      if (ocrData.error) {
        mixpanel.trackOCR({
          event: 'OCR Error',
          type: ocrData.error.type,
          backendErrorMessage: ocrData.error.message,
          errorCode: ocrData.error.code,
          errorMessage: errorMessages[ocrData.error.code],
        });
        logger.warn(
          loggerMessages.verifyId.warn.verifyId,
          {
            type: ocrData.error.type,
            backendErrorMessage: ocrData.error.message,
            errorMessage: errorMessages[ocrData.error.code],
            aggregates: { errorCode: ocrData.error.code, phaseType: 'verify-id:error' },
          },
        );
        reset();
        return;
      }
      mixpanel.trackOCR({ event: 'OCR Complete' });
      navigate('/exit');
    } catch (err) {
      reset();
      setError('Error processing image');
      logger.error(loggerMessages.verifyId.error.processImage, {
        type: 'ERR_PROCESSING_IMG',
        errorMessage: getMessageFromError(err),
        aggregates: {
          errorCode: VerifyIdErrorCodes.ERR_PROCESSING_IMG,
          phaseType: 'verify-id:error',
        },
      });
    } finally {
      setImageId(file);
      setSubmitLoading(false);
    }
  }, [file, setImageId, emblemState, projectKey, setDocumentType, setVerificationFailure, navigate]);

  useEffect(() => {
    // Automatically submit the ID to OCR if the user failed to scan the barcode and has an image already uploaded
    const barcodeFailure = window.location.search.includes('barcodeFailure=true');
    if (!file || !barcodeFailure) {
      return;
    }

    handleSubmit();
  }, [file, handleSubmit]);

  useEffect(() => {
    if (!file || !fileUrl) {
      return;
    }

    const matchFaces = async () => {
      if (imgRef.current === null) {
        return;
      }

      if (imgRef.current.naturalWidth === 0) {
        await new Promise((resolve, reject) => {
          if (imgRef.current) {
            imgRef.current.onload = resolve;
            imgRef.current.onerror = reject;
          }
        });

        imgRef.current.onload = null;
        imgRef.current.onerror = null;
      }

      let faceFromId = await human.detectFace(imgRef.current);

      if (faceFromId === false) {
        logger.info('Face detection failed on first attempt');
        /**
         * Calling it a second time appears to has fixed an issue with
         * new iPhones where the initial call always returned false.
         */
        faceFromId = await human.detectFace(imgRef.current);
        logger.info(faceFromId ? 'Face detection succeeded on second attempt' : 'Face detection failed on second attempt');
      }

      if (!faceFromId || !faceFromId.embedding) {
        setFaceMatches(false);
        setSimilarity(0);
        return;
      }

      const { pass } = await human.matchFaces(faceFromId, faces);
      setFaceMatches(pass);
    };
    matchFaces();
  }, [file, fileUrl, human, faces, setSimilarity]);

  let disableSubmit = true;
  if (requireFaceDetection === false) {
    disableSubmit = !faceMatches;
  } else {
    disableSubmit = !faceMatches || !faceDetected;
  }

  if (!documentType) {
    /**
     * If for whatever reason the documentType wasn't assigned,
     * go back.
     */
    return (
      <Navigate to="/select-doc-type" />
    );
  }

  return (
    <MainLayout includeBackButton backButtonHandler={() => navigate('/select-doc-type')}>
      <div className={clsx('flex flex-col items-center w-full', { 'bg-main': !fileUrl })}>
        <div className="flex flex-col items-center max-w-md text-center">
          {!fileUrl && error && (
            <div className="transition-[opacity] ease-in-out duration-500 delay-500 rounded-lg p-4 mb-4 bg-red-500 text-center text-white">
              {error}
            </div>
          )}

          {fileUrl ? (
            <>
              <div className="relative rounded-md bg-neutral-300 overflow-hidden mb-4 w-full">
                <div id="verify-id-image-container" className="aspect-[4/3]">
                  <img
                    id="muh-img-ref"
                    src={fileUrl}
                    alt="uploaded"
                    className="w-full h-full object-cover"
                    ref={imgRef}
                  />
                </div>
                {faceDetected === false && (
                  <div className={`${messageClasses} bg-white/70 text-center text-[#020F50]`}>
                    <Text>{t('verifyId.noFaceDetected')}</Text>
                  </div>
                )}
                {error && (
                  <div className={`${messageClasses} bg-red-500 text-center text-white`}>
                    <Text>{error}</Text>
                  </div>
                )}
                {faceMatches === false && (
                  <div className={`${messageClasses} bg-red-500 text-center text-white`}>
                    <Text>{t('verifyId.faceNoMatch')}</Text>
                  </div>
                )}
              </div>
              <Text className="my-4">
                {t('verifyId.idShouldBeVisible')}
              </Text>
            </>
          ) : (
            <Text className="my-4 font-bold">
              {t('verifyId.frontOfId')}
            </Text>
          )}

          {file && fileUrl && !submitLoading && !imgLoading && faceMatches && (
            <Button
              id="submit-verify-id-button"
              size="xl"
              className="w-full mb-4"
              disabled={disableSubmit}
              onClick={(event: FormEvent) => {
                event.preventDefault();

                // Submit to OCR api if user is on desktop or
                // if they failed to scan their ID's barcode
                if (shouldOCR) {
                  handleSubmit();
                  return;
                }

                navigate('/verify-id-info');
              }}
            >
              {shouldOCR ? t('verifyId.submit') : t('verifyId.next')}
            </Button>
          )}

          <TakePhoto
            fileUrl={fileUrl}
            setFile={setFile}
            disableTakePhoto={!faceDetected && !!requireFaceDetection}
            disableTakePhotoMessage={t('verifyId.noFaceDetected')}
          />

          {fileUrl && (
            <Button
              onClick={() => {
                setFile(null);
                setFileUrl(null);
                setError(null);
              }}
              className="mb-4"
              variant="secondary"
            >
              {t('verifyId.retakePhoto')}
            </Button>
          )}
          {faceMatches === false && (
            <Button
              onClick={() => {
                setFile(null);
                setFileUrl(null);
                setError(null);
                human.resetAgePrediction();
                navigate('/verify-age');
              }}
              className="mb-4"
              variant="secondary"
            >
              {t('verifyId.rescanFace')}
            </Button>
          )}

        </div>
        {(submitLoading || imgLoading) && (
          <div className="flex flex-col items-center justify-center absolute left-0 right-0 top-0 bottom-0 bg-main">
            <div className="flex flex-col items-center max-w-md text-center justify-center">
              <Loader />
              <Text size="2xl" className="font-bold my-4">
                {t('verifyId.verificationInProgress')}
              </Text>
              <Text>
                {t('verifyId.pleaseWait')}
              </Text>
            </div>
          </div>
        )}
      </div>
    </MainLayout>
  );
}

export default VerifyIdRoute;
