
				var addMethods = require("../../../../../node_modules/workerize-loader/dist/rpc-wrapper.js")
				var methods = []
				module.exports = function() {
					var w = new Worker(__webpack_public_path__ + "a088f7079256a26a57be.worker.js", { name: "[fullhash].worker.js" })
					URL.revokeObjectURL(__webpack_public_path__ + "a088f7079256a26a57be.worker.js");
					addMethods(w, methods)
					
					return w
				}
			