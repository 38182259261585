import clsx from 'clsx';
import React from 'react';

const fontSizes = {
  xs: 'text-xs md:text-sm',
  sm: 'text-sm md:text-base',
  base: 'text-base md:text-lg',
  lg: 'text-lg md:text-xl',
  xl: 'text-xl md:text-2xl',
  '2xl': 'text-2xl md:text-3xl',
  '3xl': 'text-3xl md:text-4xl',
  '4xl': 'text-4xl md:text-5xl',
  '5xl': 'text-5xl md:text-6xl',
  '6xl': 'text-6xl md:text-7xl',
  '7xl': 'text-7xl',
};

interface TextProps extends React.HTMLAttributes<HTMLParagraphElement>{
  children: React.ReactNode;
  size?: keyof typeof fontSizes;
  className?: string;
}
function Text({
  children, size = 'base', className = '', ...others
}:TextProps) {
  return (
    <p className={clsx(fontSizes[size], className)} {...others}>{children}</p>
  );
}

export default Text;
