import React, { memo } from 'react';

const strokeWidth = 10;

interface Props {
  width: number;
  height: number;
  radius: number;
}

function CircleMaskSVG({ width, height, radius }:Props) {
  const diameter = radius * 2;

  const trueDiameter = Math.max(diameter - (strokeWidth + 20), 0);
  const trueRadius = trueDiameter / 2;

  const cx = width / 2;
  const cy = height / 2;
  return (
    <svg
      viewBox={`0 0 ${width} ${height}`}
      preserveAspectRatio="xMinYMin slice"
      style={{
        position: 'absolute',
        top: 0,
        left: 0,
        height: '100%',
        width: '100%',
      }}
      fill="#1A1C24"
    >
      <mask id="faceMask">
        <rect x="0" y="0" width={width} height={height} fill="white" />
        <circle
          cx={cx}
          cy={cy}
          r={trueRadius}
          fill="black"
        />
      </mask>
      <rect
        width={width}
        height={height}
        mask="url(#faceMask)"
      />
    </svg>
  );
}

export default memo(CircleMaskSVG);
