/* eslint-disable max-len */
import mixpanel from 'mixpanel-browser';

interface User {
  emblemState?: string;
  tagRequestId?: string;
  projectKey?: string;
  companyName?: string;
}

interface EventTrackProps {
  event: 'Safe Passage Start' | 'Human Model Loaded' | 'Onnx Model Loaded' | 'Safe Passage Landing' | 'Safe Passage Landing Au10tix' | 'Allow Camera Access' | 'Continue to Select Country' | 'Select Document Country' | 'Choose ID Type' | 'Create Account' | 'Failure Try Again Click' | 'Failure Submit For Review Click' | 'Human Error' | 'Human Initialization Error' | 'Submit Manual Review';
  cameraAccessGranted?: boolean;
  country?: string;
  documentType?: string;
  errorMessage?: string;
  humanLoadTime?: number;
  onnxLoadTime?: number;
}

interface AgePredictionProps {
  event: 'Age Prediction Start' | 'Age Prediction Complete' | 'Age Prediction Timeout' | 'Age Prediction Timeout -> ID Upload' | 'Age Prediction No Face Readings' | 'Age Prediction Error' | 'Step 2 Variant' | 'Setting Key Face' | 'Age Predicted Above Threshold' | 'Age Predicted Below Threshold';
  startTime?: string;
  completed?: boolean;
  timedOut?: boolean;
  predictedAge?: number | null;
  stepCompleted?: number;
  keyFace?: boolean;
  numberOfFaces?: number;
  timeInProcess?: number;
  idleTime?: number;
  step2Variant?: 'FaceStraight' | 'FaceAngles';
  status?: 'timedOut' | 'complete';
}

interface BarcodeScanProps {
  event: 'Barcode Scan Start' | 'Barcode Scan Complete' | 'Barcode Scan Underage' | 'Barcode Scan Timeout' | 'Barcode Scan Error';
  age?: number;
  birthDate?: string;
  expirationDate?: string;
  detectedType?: string;
  documentType?: string;
  documentCountry?: string;
  errorCode?: number;
  errorMessage?: string;
}

interface OCRProps {
  event: 'Submit ID for OCR' | 'OCR Error' | 'OCR Complete';
  type?: string;
  backendErrorMessage?: string;
  errorCode?: number;
  errorMessage?: string;
}

interface FaceMatchProps {
  pass: boolean;
  similarity: number;
}

class WrappedMixpanelClient {
  /** Allows direct use of the mixpanel api */
  private readonly api: typeof mixpanel;
  private initialized: boolean;

  constructor() {
    this.api = mixpanel;
    this.initialized = false;
  }

  public init(isProduction: boolean, emblemState?: string) {
    if (!isProduction) return;
    if (this.initialized) return;

    this.api.init('8ca2fc033bd384966bfe3ba6c035a7ae');

    if (emblemState) {
      this.api.identify(emblemState);
    }
    this.initialized = true;
  }

  public trackEvent({ event, ...others }: EventTrackProps) {
    if (!this.initialized) return;
    this.api.track(event, { ...others });
  }

  public trackAgePrediction({ event, ...others }:AgePredictionProps) {
    if (!this.initialized) return;
    this.api.track(event, { ...others });
  }

  public trackFaceMatch(props:FaceMatchProps) {
    if (!this.initialized) return;
    this.api.track('Face Match', props);
  }

  public trackBarcodeScan({ event, ...others }:BarcodeScanProps) {
    if (!this.initialized) return;
    this.api.track(event, { ...others });
  }

  public trackOCR({ event, ...others }:OCRProps) {
    if (!this.initialized) return;
    this.api.track(event, { ...others });
  }

  public register(props: User) {
    if (!this.initialized) return;
    this.api.register(props);
  }
}

/**
 * Wraps the mixpanel api to provide app-specific typing to event and user properties
 * with some custom methods.
 */
const client = new WrappedMixpanelClient();

export default client;
