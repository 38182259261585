/* eslint-disable max-len */
import React from 'react';
import clsx from 'clsx';

interface LogoProps {
  className?: string;
}

function Logo({ className }: LogoProps) {
  return (
    <svg viewBox="0 0 255 53" xmlns="http://www.w3.org/2000/svg" className={clsx(className)}>
      <path d="M52.57 38.7001L13.87 0H2.13C0.960005 0 0 0.950005 0 2.13V13.87L38.7 52.5701H50.44C51.61 52.5701 52.57 51.6201 52.57 50.4401V38.7001Z" fill="#fff" />
      <path d="M0.630005 0.630127C0.250005 1.01013 0.0100098 1.55013 0.0100098 2.13013V13.8701L38.71 52.5702H50.45C51.04 52.5702 51.57 52.3302 51.95 51.9502L0.619995 0.620117L0.630005 0.630127Z" fill="#FF9933" />
      <path d="M2.13 52.5802H34.27L20.85 39.1602H13.42L2.90002 28.6401H0V50.4502C0 51.6202 0.950005 52.5802 2.13 52.5802Z" fill="#FF9933" />
      <path d="M50.45 0H18.31L31.73 13.42H39.16L49.68 23.9401H52.58V2.13C52.58 0.960005 51.63 0 50.45 0Z" fill="#fff" />
      <path d="M76.26 29.7999C76.4 31.3899 77.44 32.83 80.13 32.83C82.58 32.83 83.5 31.9599 83.5 30.6399C83.5 29.3199 82.75 28.7399 81.31 28.5299L77.13 28.0699C73.67 27.6699 71.68 25.7 71.68 22.45C71.68 18.84 74.39 16.3599 79.93 16.3599C85.84 16.3599 88.21 19.19 88.47 22.59H83.45C83.25 21.06 82.09 20.0499 79.93 20.0499C77.97 20.0499 76.82 20.83 76.82 22.1C76.82 23.08 77.4 23.8 78.87 23.97L82.53 24.35C86.74 24.75 88.65 26.74 88.65 30.21C88.65 33.99 86.28 36.5299 80.05 36.5299C73.82 36.5299 71.54 33.2399 71.34 29.8099H76.27L76.26 29.7999Z" fill="#fff" />
      <path d="M90.36 32.2299C90.36 29.9199 91.31 28.3899 95.29 27.7299L98.55 27.1499C99.88 26.9199 100.22 26.5699 100.22 25.8199C100.22 24.9499 99.7 24.4399 98.06 24.4399C96.33 24.4399 95.49 25.1299 95.46 26.3999H90.64C90.78 23.3399 93.44 21.1499 98 21.1499C102.56 21.1499 104.81 23.0199 104.81 26.3099V32.0499C104.81 33.5799 104.9 34.8499 105.07 36.0299H100.8C100.66 35.1899 100.57 34.2099 100.57 33.0299C99.85 35.1399 98.06 36.3999 95.15 36.3999C92.24 36.3999 90.36 34.76 90.36 32.22V32.2299ZM100.23 29.2599V28.71C99.54 29.23 98.87 29.3999 97.98 29.6299L97.14 29.83C95.76 30.18 95.24 30.6699 95.24 31.6499C95.24 32.6299 96.02 33.1799 97.03 33.1799C98.91 33.1799 100.23 31.8499 100.23 29.2599Z" fill="#fff" />
      <path d="M108.99 36.0298V25.3298H106.37V23.0198L108.99 21.5798V20.6298C108.99 17.7398 110.46 15.8398 114.1 15.8398H118.05V19.4198H113.2V21.6097H117.33V25.3298H113.96V36.0298H109H108.99Z" fill="#fff" />
      <path d="M128.98 31.6802H133.8C133.31 34.1602 131.35 36.5001 126.1 36.5001C120.22 36.5001 118.14 32.7502 118.14 28.8002C118.14 24.6202 120.56 21.1602 126.1 21.1602C131.99 21.1602 133.86 24.8801 133.86 28.8901C133.86 29.3501 133.83 29.7802 133.77 30.1002H122.95C123.24 32.4102 124.36 33.1902 126.21 33.1902C127.68 33.1902 128.57 32.5502 128.98 31.6902V31.6802ZM123.01 27.2102H129.15C128.98 25.3902 128.08 24.4102 126.15 24.4102C124.36 24.4102 123.32 25.1302 123.01 27.2102Z" fill="#fff" />
      <path d="M154.59 32.2299C154.59 29.9199 155.54 28.3899 159.52 27.7299L162.78 27.1499C164.11 26.9199 164.45 26.5699 164.45 25.8199C164.45 24.9499 163.93 24.4399 162.29 24.4399C160.56 24.4399 159.72 25.1299 159.69 26.3999H154.87C155.01 23.3399 157.67 21.1499 162.23 21.1499C166.79 21.1499 169.04 23.0199 169.04 26.3099V32.0499C169.04 33.5799 169.13 34.8499 169.3 36.0299H165.03C164.89 35.1899 164.8 34.2099 164.8 33.0299C164.08 35.1399 162.29 36.3999 159.38 36.3999C156.47 36.3999 154.59 34.76 154.59 32.22V32.2299ZM164.46 29.2599V28.71C163.77 29.23 163.1 29.3999 162.21 29.6299L161.37 29.83C159.98 30.18 159.47 30.6699 159.47 31.6499C159.47 32.6299 160.25 33.1799 161.26 33.1799C163.13 33.1799 164.46 31.8499 164.46 29.2599Z" fill="#FF9933" />
      <path d="M175.64 31.3899C175.76 32.5699 176.74 33.5199 178.58 33.5199C180.2 33.5199 180.83 32.9999 180.83 32.1599C180.83 31.4399 180.57 30.8299 178.9 30.6299L175.84 30.2799C173.04 29.9599 171.54 28.46 171.54 25.95C171.54 22.52 174.48 21.1299 178.35 21.1299C182.22 21.1299 185.1 22.4899 185.39 25.8599H180.89C180.75 24.7399 179.97 24.0699 178.38 24.0699C176.71 24.0699 176.07 24.6799 176.07 25.4799C176.07 26.0899 176.42 26.6299 177.77 26.7799L180.74 27.0699C184.14 27.4199 185.56 28.7999 185.56 31.5699C185.56 34.3399 183.86 36.47 178.49 36.47C173.12 36.47 171.25 34.0199 171.13 31.3599H175.63L175.64 31.3899Z" fill="#FF9933" />
      <path d="M191.65 31.3899C191.77 32.5699 192.75 33.5199 194.59 33.5199C196.21 33.5199 196.84 32.9999 196.84 32.1599C196.84 31.4399 196.58 30.8299 194.91 30.6299L191.85 30.2799C189.05 29.9599 187.55 28.46 187.55 25.95C187.55 22.52 190.49 21.1299 194.36 21.1299C198.23 21.1299 201.11 22.4899 201.4 25.8599H196.9C196.76 24.7399 195.98 24.0699 194.39 24.0699C192.72 24.0699 192.08 24.6799 192.08 25.4799C192.08 26.0899 192.43 26.6299 193.78 26.7799L196.75 27.0699C200.15 27.4199 201.57 28.7999 201.57 31.5699C201.57 34.3399 199.87 36.47 194.5 36.47C189.13 36.47 187.26 34.0199 187.14 31.3599H191.64L191.65 31.3899Z" fill="#FF9933" />
      <path d="M203.53 32.2299C203.53 29.9199 204.48 28.3899 208.46 27.7299L211.72 27.1499C213.05 26.9199 213.39 26.5699 213.39 25.8199C213.39 24.9499 212.87 24.4399 211.23 24.4399C209.5 24.4399 208.66 25.1299 208.63 26.3999H203.81C203.95 23.3399 206.61 21.1499 211.17 21.1499C215.73 21.1499 217.98 23.0199 217.98 26.3099V32.0499C217.98 33.5799 218.07 34.8499 218.24 36.0299H213.97C213.83 35.1899 213.74 34.2099 213.74 33.0299C213.02 35.1399 211.23 36.3999 208.32 36.3999C205.41 36.3999 203.53 34.76 203.53 32.22V32.2299ZM213.4 29.2599V28.71C212.71 29.23 212.04 29.3999 211.15 29.6299L210.31 29.83C208.92 30.18 208.41 30.6699 208.41 31.6499C208.41 32.6299 209.19 33.1799 210.2 33.1799C212.07 33.1799 213.4 31.8499 213.4 29.2599Z" fill="#FF9933" />
      <path d="M231.83 21.6099H236.79V35.2C236.79 39.87 234.45 42.1199 228.37 42.1199C223.15 42.1199 220.78 39.7 220.58 36.58H225.4C225.57 37.85 226.5 38.71 228.63 38.71C230.76 38.71 231.83 37.9 231.83 35.97V33.3999C230.85 34.7799 229.26 35.59 226.9 35.59C222.14 35.59 220.21 32.1899 220.21 28.3799C220.21 24.5699 222.14 21.1699 226.9 21.1699C229.26 21.1699 230.85 21.98 231.83 23.33V21.6V21.6099ZM231.83 28.4799C231.83 26.1699 231.02 25.0199 228.6 25.0199C226.18 25.0199 225.17 25.9999 225.17 28.4799C225.17 30.9599 226.12 31.97 228.6 31.97C231.08 31.97 231.83 30.8199 231.83 28.4799Z" fill="#FF9933" />
      <path d="M249.6 31.6802H254.42C253.93 34.1602 251.97 36.5001 246.72 36.5001C240.84 36.5001 238.76 32.7502 238.76 28.8002C238.76 24.6202 241.18 21.1602 246.72 21.1602C252.61 21.1602 254.48 24.8801 254.48 28.8901C254.48 29.3501 254.45 29.7802 254.39 30.1002H243.57C243.86 32.4102 244.98 33.1902 246.83 33.1902C248.3 33.1902 249.19 32.5502 249.6 31.6902V31.6802ZM243.63 27.2102H249.77C249.6 25.3902 248.7 24.4102 246.77 24.4102C244.98 24.4102 243.94 25.1302 243.63 27.2102Z" fill="#FF9933" />
      <path d="M147.03 16.8499H136.3V32.5999L139.73 36.0298H141.43V29.7698H147.03C151.44 29.7698 153.2 26.9098 153.2 23.1898C153.2 19.6998 151.44 16.8398 147.03 16.8398V16.8499ZM145.33 25.8499H141.44V20.7397H145.39C147.21 20.7397 147.9 21.7498 147.9 23.2198C147.9 24.6898 147.18 25.8499 145.33 25.8499Z" fill="#FF9933" />
    </svg>
  );
}

export default Logo;
