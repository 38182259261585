import { useEffect } from 'react';
import logger from 'services/logger';
import { getMessageFromError } from 'utils/errorMessage';
import resizeImage from 'utils/resizeImage';
import useImgFileStore from 'stores/imgFileStore';

function useFileReader() {
  const file = useImgFileStore((state) => state.file);
  const setFile = useImgFileStore((state) => state.setFile);
  const fileUrl = useImgFileStore((state) => state.fileUrl);
  const setFileUrl = useImgFileStore((state) => state.setFileUrl);
  const imgLoading = useImgFileStore((state) => state.imgLoading);
  const setImgLoading = useImgFileStore((state) => state.setImgLoading);
  const error = useImgFileStore((state) => state.error);
  const setError = useImgFileStore((state) => state.setError);

  // Load preview of the user's uploaded image
  useEffect(() => {
    if (file && file instanceof Blob) {
      setImgLoading(true);
      setFileUrl(null);

      const reader = new FileReader();
      reader.onload = async (event) => {
        setError(null);
        if (!event.target) {
          return;
        }
        try {
          const eventUrl = event.target.result as string;
          const url = await resizeImage(eventUrl, { maxFileSize: 3_500_000, imageType: 'png', maxRetries: 10 });
          logger.debug('Image url set');
          setFileUrl(url);
          setImgLoading(false);
        } catch (err) {
          const errorMessage = getMessageFromError(err);
          logger.error('Error loading image', { error: errorMessage });
          setError(errorMessage);
        } finally {
          setImgLoading(false);
        }
      };
      reader.readAsDataURL(file);
    }
  }, [file, setError, setFileUrl, setImgLoading]);

  return {
    file,
    setFile,
    fileUrl,
    setFileUrl,
    imgLoading,
    error,
  };
}

export default useFileReader;
