export interface LocalStorageUserValues {
  expires: number;
  lng: string;
}

class LocalStorageUtil {
  key = 'safePassageUserValues';
  defaultValues: LocalStorageUserValues = {
    lng: '',
    expires: 0,
  };

  checkIsExpired(expires: number) {
    const time = Date.now();
    if (time > expires) {
      this.clearValues();
      return true;
    }
    return false;
  }

  getValues() {
    const localStorageUserValues = localStorage.getItem(this.key);
    const userValues: LocalStorageUserValues = localStorageUserValues ? JSON.parse(localStorageUserValues) : null;

    if (!userValues) return this.defaultValues;

    if (this.checkIsExpired(userValues.expires)) {
      return this.defaultValues;
    }

    return userValues;
  }

  setValues(newValues: Partial<LocalStorageUserValues>) {
    const oldValues = this.getValues();
    const oneHourFromNow = Date.now() + (60 * 60 * 1000);
    const json = JSON.stringify({
      ...this.defaultValues,
      ...oldValues,
      ...newValues,
      expires: oneHourFromNow,
    });
    localStorage.setItem(this.key, json);
  }

  clearValues() {
    localStorage.removeItem(this.key);
  }
}

const localStorageUtil = new LocalStorageUtil();

export default localStorageUtil;
