import { create } from 'zustand';
import { Emotion } from '@vladmandic/human';

export interface DocumentScannerConfig {
  detectTimeout: number;
}

export interface AgeDetectionConfig {
  duration: number;
  threshold: number;
  delay: number;
  idleTimeout: number;
  minFaces: number;
  maxFaces: number;
}

export interface FaceMatchConfig {
  threshold: number;
  keyFaceThreshold: number,
  keyFaceMismatchLimit: number,
}

export interface FaceRotationConfig {
  pitchUp: number,
  pitchDown: number,
  yawLeft: number,
  yawRight: number,
}

export interface FaceValidationConfig {
  faceScore: number,
  liveScore: number,
  realScore: number,
}

export interface EmotionDetectionConfig {
  threshold: number,
  passingEmotion: Emotion,
  direction: 'above' | 'below'
}

export interface LicensePhotoConfig {
  requireFaceDetection: boolean,
}

export interface FaceAngleStep2Config {
  numAngles: number,
  percentToComplete: number,
  proximity: number,
  minEulerValue: number,
}

export interface HumanConfigValues {
  ageDetection: AgeDetectionConfig,
  faceMatch: FaceMatchConfig,
  faceRotation: FaceRotationConfig,
  forceFaceAngles: boolean,
  faceValidation: FaceValidationConfig,
  emotionDetection: EmotionDetectionConfig,
  licensePhoto: LicensePhotoConfig,
  documentScanner: DocumentScannerConfig,
  faceAngleStep2: FaceAngleStep2Config,
}

export interface DebugConfig {
  logFaceResult: boolean,
  logFaceAngles: boolean,
  logFaceEmotion: boolean,
  logFaceMatch: boolean,
  logInvalidFace: boolean,
  logValidFace: boolean,
  mugshot: boolean,
  displayKeyFace: boolean,
  displaySimilarity: boolean,
  displayTopSimilarityFace: boolean,
  infiniteFaceCollection: boolean,
}

export interface PublicConfig {
  emblemHost: string,
  networkId: number | null,
  configLogs: boolean,
  companySlug: string,
  requireManualReviewPhoto: boolean,
  uploadHost: string,
  clientId: string,
  keycloakBaseUrl: string,
  realm: string,
  useLicenseMask: boolean,
  allowLicenseUpload: boolean,
  country: string,
  todaysDate: string,
}

export interface ConfigStore {
  emblemHost: string;
  networkId: number | null;
  configLogs: boolean;
  requireManualReviewPhoto: boolean;
  companySlug: string;
  setPublicConfig: (publicConfig: Partial<PublicConfig>) => void;
  supportWebsiteUrl: string | null;
  redirectUrl: string | null;
  keycloakBaseUrl: string;
  realm: string;
  clientId: string;
  uploadHost: string;
  useLicenseMask: boolean;
  allowLicenseUpload: boolean;
  country: string;
  todaysDate: string;
  setSupportWebsiteUrl: (supportWebsiteUrl: string | null) => void;
  setRedirectUrl: (redirectUrl: string | null) => void;
  allowSkip: boolean;
  setAllowSkip: (allowSkip: boolean) => void;
  skipAgeDetection: boolean;
  setSkipAgeDetection: (skipAgeDetection: boolean) => void;
  humanConfig: HumanConfigValues;
  debug: DebugConfig;
  setAgeDetectionConfig: (humanConfig: Partial<AgeDetectionConfig>) => void;
  setFaceMatchConfig: (humanConfig: FaceMatchConfig) => void;
  setDocumentScannerConfig: (humanConfig: DocumentScannerConfig) => void;
  setEmotionDetectionConfig: (humanConfig: EmotionDetectionConfig) => void;
  setFaceValidationConfig: (humanConfig: FaceValidationConfig) => void;
  setForceFaceAngles: (forceFaceAngles: boolean) => void;
  setDebugConfig: (update: Partial<DebugConfig>) => void;
  forceAu10tixDoubleCheck: boolean,
  setForceAu10tixDoubleCheck: (forceAu10tixDoubleCheck: boolean) => void,
}

const useConfigStore = create<ConfigStore>((set, get) => ({
  emblemHost: '',
  configLogs: false,
  keycloakBaseUrl: '',
  uploadHost: '',
  realm: '',
  clientId: '',
  todaysDate: '',
  setEmblemHost: (emblemHost: string) => set({ emblemHost }),
  networkId: null,
  companySlug: '',
  requireManualReviewPhoto: false,
  useLicenseMask: true,
  allowLicenseUpload: true,
  country: '',
  allowSkip: false,
  setAllowSkip: (allowSkip: boolean) => set({ allowSkip }),
  skipAgeDetection: false,
  setSkipAgeDetection: (skipAgeDetection: boolean) => set({ skipAgeDetection }),
  supportWebsiteUrl: null,
  redirectUrl: null,
  setSupportWebsiteUrl: (supportWebsiteUrl: string | null) => set({ supportWebsiteUrl }),
  setRedirectUrl: (redirectUrl: string | null) => set({ redirectUrl }),
  forceAu10tixDoubleCheck: false,
  setForceAu10tixDoubleCheck: (forceAu10tixDoubleCheck: boolean) => set({ forceAu10tixDoubleCheck }),
  humanConfig: {
    forceFaceAngles: false,
    ageDetection: {
      duration: 3_000,
      threshold: 27,
      delay: 20,
      idleTimeout: 20_000,
      minFaces: 5,
      maxFaces: 25,
    },
    faceMatch: {
      // Human js considers 0.5 a match but ID photos tend to be old and blurry
      threshold: 0.25,
      // Making this higher than the minimum of 0.5 because each reading is taken only a few milliseconds apart so they should be very similar
      keyFaceThreshold: 0.7,
      keyFaceMismatchLimit: 10,
    },
    faceRotation: {
      pitchUp: -0.1,
      pitchDown: 0.1,
      yawLeft: -0.4,
      yawRight: 0.4,
    },
    faceAngleStep2: {
      // 360 degrees circle will be divided into numAngles parts
      numAngles: 18,

      // percent of circle that must be completed to be complete to pass the check
      percentToComplete: 0.25,

      // degrees
      proximity: 10,

      // pitch or yaw must be at least this value to be considered turned enough from center
      minEulerValue: 0.2,
    },
    faceValidation: {
      faceScore: 1,
      liveScore: 0.8,
      realScore: 0.5,
    },
    emotionDetection: {
      threshold: 0.2,
      passingEmotion: 'angry',
      direction: 'below',
    },
    licensePhoto: {
      requireFaceDetection: false,
    },
    documentScanner: {
      detectTimeout: 60_000,
    },
  },
  debug: {
    logFaceResult: false,
    logFaceAngles: false,
    logFaceEmotion: false,
    logFaceMatch: true,
    logInvalidFace: true,
    logValidFace: false,
    mugshot: false,
    displayKeyFace: false,
    keyFaceMismatchThreshold: 10,
    displaySimilarity: false,
    displayTopSimilarityFace: false,
    infiniteFaceCollection: false,
  },
  setAgeDetectionConfig: (ageDetectionConfig: Partial<AgeDetectionConfig>) => {
    const c = get().humanConfig;
    const newConfig = {
      ...c,
      ageDetection: {
        ...c.ageDetection,
        ...ageDetectionConfig,
      },
    };
    set({ humanConfig: newConfig });
  },
  setDocumentScannerConfig: (documentScannerConfig: DocumentScannerConfig) => {
    const newConfig = {
      ...get().humanConfig,
      documentScanner: documentScannerConfig,
    };
    set({ humanConfig: newConfig });
  },
  setFaceMatchConfig: (faceMatchConfig: FaceMatchConfig) => {
    const newConfig = {
      ...get().humanConfig,
      faceMatch: faceMatchConfig,
    };
    set({ humanConfig: newConfig });
  },
  setEmotionDetectionConfig: (emotionDetectionConfig: EmotionDetectionConfig) => {
    const newConfig = {
      ...get().humanConfig,
      emotionDetection: emotionDetectionConfig,
    };
    set({ humanConfig: newConfig });
  },
  setFaceValidationConfig: (faceValidationConfig: FaceValidationConfig) => {
    const newConfig = {
      ...get().humanConfig,
      faceValidation: faceValidationConfig,
    };
    set({ humanConfig: newConfig });
  },
  setForceFaceAngles: (forceFaceAngles: boolean) => {
    const newConfig = {
      ...get().humanConfig,
      forceFaceAngles,
    };
    set({ humanConfig: newConfig });
  },
  setDebugConfig: (update: Partial<DebugConfig>) => {
    const newConfig: DebugConfig = {
      ...get().debug,
      ...update,
    };
    set({ debug: newConfig });
  },
  setPublicConfig: (publicConfig) => {
    const pc = get();
    set({
      ...pc,
      ...publicConfig,
    });
  },
}));

export default useConfigStore;
