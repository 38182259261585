import React, { SyntheticEvent, useEffect, useState } from 'react';
import clsx from 'clsx';
import { detect } from 'detect-browser';
import Header from 'components/Header';
import Footer from 'components/Footer';
import Text from 'components/Text';
import localStorageUtil from 'utils/localStorageUtil';
import { useTranslation } from 'react-i18next';

interface Props {
  initialized: boolean;
  animationComplete: boolean;
  setAnimationComplete: (value: boolean) => void;
}

function LoadingPage({ initialized, animationComplete, setAnimationComplete }: Props) {
  const searchParams = new URLSearchParams(window.location.search);
  const [progress, setProgress] = useState(0);
  const [isLoadingComplete, setIsComplete] = useState(false);
  const [isIOS, setIsIOS] = useState(false);
  const [videoReady, setVideoReady] = useState<null | number>(null);
  const { t, i18n } = useTranslation();
  const lng = searchParams.get('lng') ?? localStorageUtil.getValues().lng ?? '';

  useEffect(() => {
    if (lng) { i18n.changeLanguage(lng); }
  }, [i18n, lng]);

  useEffect(() => {
    // Gain 10% every second
    const interval = setInterval(() => {
      setProgress((prev) => {
        if (prev >= 100) {
          clearInterval(interval);
          return 100;
        }
        return prev + 1;
      });
    }, 100);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (progress < 100) {
      return () => {};
    }

    // Show 100% for 500ms
    const timeout = setTimeout(() => {
      setIsComplete(true);
    }, 500);

    return () => clearTimeout(timeout);
  }, [progress]);

  useEffect(() => {
    if (!isLoadingComplete) {
      return () => {};
    }

    // Show COMPLETE! for 1000ms
    const timeout = setTimeout(() => {
      setAnimationComplete(true);
    }, 1000);

    return () => clearTimeout(timeout);
  }, [isLoadingComplete, setAnimationComplete]);

  useEffect(() => {
    // Set progress to 100% if Human finishes initializing
    // before the progress bar reaches 100%
    if (initialized) {
      setProgress(100);
    }
  }, [initialized]);

  useEffect(() => {
    const browser = detect();
    const ios = browser?.name === 'safari' && browser?.os === 'iOS';
    if (ios) {
      setIsIOS(ios);
    }
  }, []);

  if (!initialized && animationComplete) {
    return (
      <div className="fixed flex flex-col items-center justify-center inset-0 z-10">
        <img
          className="animate-spin w-[80px] h-auto"
          src="/images/loading.png"
          alt="loading"
        />
        <div className="flex flex-col items-center justify-center inset-0 p-8">
          <Text size="2xl" className="mb-4 text-center font-bold">
            {t('loadingPage.init')}
          </Text>
          <Text size="lg" className="mb-4 text-center">
            {isIOS
              ? t('loadingPage.safari')
              : t('loadingPage.pleaseWait')}
          </Text>
        </div>
      </div>
    );
  }

  return (
    <div className="h-full flex flex-col justify-between overflow-x-hidden overflow-y-auto">
      <div>
        <Header includeBackButton={false} basePath="" />
        <main className="flex-1">
          <div className="flex px-8 mx-auto max-w-2xl min-h-full flex-col">
            <div className="flex flex-col items-center justify-center inset-0">
              <Text size="2xl" className="mb-4 text-center font-bold">
                {t('loadingPage.title')}
              </Text>
            </div>
            <div className="flex justify-center mb-4 h-[14rem] sm:h-[20rem] relative">
              <video
                className={clsx(
                  'h-[14rem] sm:h-[20rem]',
                  {
                    'opacity-0': !videoReady,
                    'opacity-100': videoReady,
                  },
                )}
                autoPlay
                loop
                muted
                playsInline
                onCanPlay={(e: SyntheticEvent<HTMLVideoElement>) => {
                  const vid = e.target as HTMLVideoElement;
                  setVideoReady(vid.readyState);
                }}

              >
                <source
                  src="/images/loading-card.mp4"
                  type="video/mp4"
                />
              </video>
              <img
                src="/images/loading-card.png"
                alt="loading"
                className={clsx(
                  'h-[14rem] sm:h-[20rem] absolute',
                  {
                    hidden: videoReady,
                  },
                )}
              />
            </div>
            <div className="mb-8 px-6 relative">
              <div
                className="mb-4 bg-primary-100 rounded-full transition-[width] ease-in-out"
                style={{
                  width: `${progress}%`,
                  height: '11px',
                  background: 'linear-gradient(90deg, #153B79, #1989E9, #DEEDFC)',
                }}
              />
              <div className="px-6 absolute top-0 left-0 w-full">
                <div
                  className="w-full rounded-full"
                  style={{
                    height: '11px',
                    background: '#D9D9D9',
                    opacity: 0.2,
                  }}
                />
              </div>

              <Text
                size="lg"
                className={clsx('text-center font-bold', { hidden: isLoadingComplete })}
              >
                {`${progress}%`}
              </Text>
              <Text
                size="lg"
                className={clsx('text-center font-bold transition-opacity duration-1000', {
                  'opacity-0': !isLoadingComplete,
                  'opacity-100': isLoadingComplete,
                  absolute: !isLoadingComplete,
                })}
              >
                {t('loadingPage.complete')}
              </Text>

            </div>
            <div className="px-6">
              <Text
                className="text-center"
                size="base"
              >
                {t('loadingPage.personal')}
              </Text>
            </div>
          </div>
        </main>
      </div>
      <Footer />
    </div>
  );
}

export default LoadingPage;
