import { create } from 'zustand';

export interface ImgFileState {
  file: File | null;
  setFile: (newFile: File | null) => void;
  fileUrl: string | null;
  setFileUrl: (newFileUrl: string | null) => void;
  imgLoading: boolean;
  setImgLoading: (newImgLoading: boolean) => void;
  error: string | null;
  setError: (newError: string | null) => void;
}

const useImgFileStore = create<ImgFileState>((set) => ({
  file: null,
  setFile: (newFile) => set({ file: newFile }),
  fileUrl: null,
  setFileUrl: (newFileUrl) => set({ fileUrl: newFileUrl }),
  imgLoading: false,
  setImgLoading: (newImgLoading) => set({ imgLoading: newImgLoading }),
  error: null,
  setError: (newError) => set({ error: newError }),
}));

export default useImgFileStore;
