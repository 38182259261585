import React from 'react';
import { HumanAgePredictionProvider } from 'contexts/HumanAgePredictionContext';
import { Outlet } from 'react-router-dom';

function AgePredictionLayout() {
  return (
    <HumanAgePredictionProvider>
      <Outlet />
    </HumanAgePredictionProvider>
  );
}

export default AgePredictionLayout;
