import React, { Fragment, useEffect, useState } from 'react';
import Button from 'components/Button';
import Checkbox from 'components/Checkbox';
import Input from 'components/Input';
import isValidEmail from 'utils/isValidEmail';
import useFetchConfig from 'services/useFetchConfig';
import Text from 'components/Text';
import { useTranslation } from 'react-i18next';
import { StepProps } from './types';

function ManualSubmission({
  formData, setFormData, setStep, formStatus,
}: StepProps) {
  const [isEmailValid, setIsEmailValid] = useState(false);
  const { requireManualReviewPhoto } = useFetchConfig();
  const { t } = useTranslation();

  /**
   * Send the user back if the photos are missing
   */
  useEffect(() => {
    if (requireManualReviewPhoto && !formData.userImage) {
      setStep('ConfirmPhoto');
    }

    if (!formData.idImage) {
      setStep('ConfirmId');
    }
  }, [formData, requireManualReviewPhoto, setStep]);

  useEffect(() => {
    setIsEmailValid(isValidEmail(formData.email));
  }, [formData.email]);

  const disabled = !formData.agreedToTermsAndConditionsAndPrivacyPolicy || !isEmailValid || formStatus === 'submitting';
  return (
    <div className="flex flex-col justify-center items-center">
      <img
        alt="Hand with ID card"
        className="h-50 w-auto mb-8"
        src="/images/illustration-id-hand.svg"
      />
      <h1 className="text-center text-2xl tracking-tight font-bold text-white">{t('appeal.manualSubmission.title')}</h1>
      <Text className="text-center mt-2 mb-4">
        {t('appeal.manualSubmission.description')}
      </Text>
      <Input
        label={t('appeal.manualSubmission.emailAddress')}
        rootClassName="w-full mb-2"
        className="!text-black"
        type="email"
        onChange={({ target }) => setFormData({ email: target.value })}
      />
      <Input
        label={t('appeal.manualSubmission.message')}
        rootClassName="w-full"
        className="!text-black"
        as="textarea"
        onChange={({ target }) => setFormData({ message: target.value })}
        maxLength={500}
        rows={4}
      />
      <Button
        type="submit"
        className="mt-10 mb-2"
        disabled={disabled}
      >
        {t('appeal.manualSubmission.submitForManualReview')}
      </Button>
      <Checkbox
        checked={formData.agreedToTermsAndConditionsAndPrivacyPolicy}
        onChange={({ target }) => setFormData({ agreedToTermsAndConditionsAndPrivacyPolicy: target.checked })}
        description={(
          <Fragment>
            {t('appeal.manualSubmission.agreedToTermsAndConditionsAndPrivacyPolicy')}
            {' '}
            <a
              href="https://www.safepassageapp.com/terms"
              className="underline"
              target="_blank"
              rel="noreferrer"
            >
              {t('appeal.manualSubmission.termsAndConditions')}
            </a>
            {' '}
            {t('and')}
            {' '}
            <a
              href="https://www.safepassageapp.com/privacy"
              className="underline"
              target="_blank"
              rel="noreferrer"
            >
              {t('appeal.manualSubmission.privacyPolicy')}
              .
            </a>
          </Fragment>
        )}
      />
    </div>
  );
}

export default ManualSubmission;
