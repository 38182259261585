import React, { useMemo } from 'react';
import clsx from 'clsx';

const variants = {
  primary: 'focus:border-cyan-500 focus:ring-cyan-500',
  blue: 'focus:border-blue-400 focus:ring-blue-400',
};

type As = 'input' | 'textarea';

interface Props<T extends As> {
  label?: string;
  className?: string;
  rootClassName?: string;
  defaultValue?: string;
  variant?: keyof typeof variants;
  as?: T;
}

export type InputProps<T extends As = 'input'> = Props<T> & Omit<React.ComponentPropsWithoutRef<T>, keyof Props<T>>;

function Input<T extends As = 'input'>({
  label,
  className,
  variant = 'primary',
  rootClassName,
  as,
  ...props
}: InputProps<T>) {
  const Component = useMemo(() => {
    switch (as) {
      case 'textarea':
        return 'textarea';
      default:
        return 'input';
    }
  }, [as]);

  return (
    <div className={clsx(rootClassName)}>
      <label>{label}</label>
      {(Component === 'input' ? (
        <input
          {...(props as React.ComponentPropsWithoutRef<'input'>)}
          className={clsx(
            'form-input block w-full rounded-md border-gray-600 bg-primary-700 text-white shadow-sm sm:text-sm',
            variants[variant],
            className,
          )}
        />
      ) : (
        <textarea
          {...(props as React.ComponentPropsWithoutRef<'textarea'>)}
          className={clsx(
            'form-input block w-full rounded-md border-gray-600 bg-primary-700 text-white shadow-sm sm:text-sm',
            variants[variant],
            className,
          )}
        />
      ))}
    </div>
  );
}

export default Input;
