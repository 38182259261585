import { useEffect, useState } from 'react';
import useConfigStore from 'stores/configStore';
import getErrorMessage from 'utils/getErrorMessage';
import axios from './api/axios';
import logger from './logger';

function useFetchConfig() {
  const [loading, setLoading] = useState<boolean>(false);
  const emblemHost = useConfigStore((state) => state.emblemHost);
  const networkId = useConfigStore((state) => state.networkId);
  const configLogs = useConfigStore((state) => state.configLogs);
  const clientId = useConfigStore((state) => state.clientId);
  const realm = useConfigStore((state) => state.realm);
  const keycloakBaseUrl = useConfigStore((state) => state.keycloakBaseUrl);
  const uploadHost = useConfigStore((state) => state.uploadHost);
  const companySlug = useConfigStore((state) => state.companySlug);
  const requireManualReviewPhoto = useConfigStore((state) => state.requireManualReviewPhoto);
  const setPublicConfig = useConfigStore((state) => state.setPublicConfig);

  useEffect(() => {
    if (emblemHost) {
      return;
    }

    const fetchUrl = async () => {
      try {
        setLoading(true);
        const { data } = await axios.get('/api/safe-passage/v1/config');
        setPublicConfig({
          emblemHost: data.emblemHost || '',
          networkId: data.networkId || '',
          configLogs: data.configLogs || false,
          companySlug: data.companySlug || '',
          requireManualReviewPhoto: data.requireManualReviewPhoto || false,
          uploadHost: data.uploadHost || '',
          clientId: data.safePassageClientId || '',
          keycloakBaseUrl: data.safePassageKeycloakBaseUrl || '',
          realm: data.safePassageRealm || '',
          useLicenseMask: data.useLicenseMask ?? true,
          allowLicenseUpload: data.allowLicenseUpload ?? true,
          country: data.country || 'US',
          todaysDate: data.todaysDate || '',
        });
      } catch (error) {
        const errorMessage = getErrorMessage(error as Error, 'Error fetching public config');
        logger.error('Error fetching public config', { errorMessage });
        console.error(error);
      } finally {
        setLoading(false);
      }
    };
    fetchUrl();
  }, [emblemHost, setPublicConfig]);

  return {
    emblemHost,
    configLogs,
    loading,
    networkId,
    clientId,
    realm,
    keycloakBaseUrl,
    uploadHost,
    companySlug,
    requireManualReviewPhoto,
  };
}

export default useFetchConfig;
