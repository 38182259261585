import React from 'react';
import Arc, { degreesToRadians } from './Arc';
import { Vector } from './arc-gen';

interface LeftArcProps {
  center: Vector;
  radius: number;
  color?: 'red' | 'green';
}

const ANGLES = [degreesToRadians(160), degreesToRadians(40)] as Vector;

function LeftArc({ center, color = 'red', radius }: LeftArcProps): JSX.Element {
  return (
    <Arc
      cx={center[0]}
      cy={center[1]}
      radius={radius}
      color={color}
      startAngle={ANGLES[0]}
      length={ANGLES[1]}
      φ={0}
    />
  );
}

export default LeftArc;
