import React from 'react';
import Button from 'components/Button';
import { Link } from 'react-router-dom';
import Text from 'components/Text';
import { useTranslation } from 'react-i18next';

function CameraPermission() {
  const { t } = useTranslation();
  return (
    <div className="flex flex-col w-full">
      <div className="text-center my-8">
        <Text>
          {t('cameraPermission.description')}
        </Text>
        <Text className="font-semibold mt-3 text-red-500">
          {t('cameraPermission.denied')}
        </Text>
      </div>
      <div className="flex justify-center">
        <Button
          component={Link}
          to="/"
          className="mb-2"
        >
          {t('cameraPermission.goBack')}
        </Button>
      </div>
    </div>
  );
}

export default CameraPermission;
