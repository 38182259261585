import {
  useEffect, useState, useCallback, useRef,
} from 'react';
import axios from 'services/api/axios';
import logger from 'services/logger';
import { getMessageFromError } from 'utils/errorMessage';
import extractAu10tixResult from 'utils/extractAu10tixResult';
import { Au10tixErrorCodes, errorMessages, loggerMessages } from 'types/logger';
import useConfigStore from 'stores/configStore';
import { Au10tixProcessRequestData } from 'types/Au10tix';

export type EighteenPlusVerificationStatus = 'success' | 'fail';

export interface EighteenPlusVerification {
  age: number | null;
  status: EighteenPlusVerificationStatus;
  message?: string;
  failCode?: Au10tixErrorCodes;
}

const checkTimeout = 60_000; // 1 minute
const checkInterval = 5_000;
const maxAttempts = Math.floor(checkTimeout / checkInterval);

function useAu10tixAgeVerification() {
  const [au10tixRequestId, setRequestId] = useState<string | null>(null);
  const [au10tixAgeResult, setAu10TixAgeResult] = useState<null | EighteenPlusVerification>(null);
  const [au10tixLoading, setAu10TixLoading] = useState(false);
  const [au10tixError, setAu10tixError] = useState<null | string>(null);
  const [isDoubleCheck, setIsDoubleCheck] = useState(false);
  const checkIntervalRef = useRef<ReturnType<typeof setInterval> | null>(null);
  const forceAu10tixDoubleCheck = useConfigStore((state) => state.forceAu10tixDoubleCheck);

  const beginAu10tixProcessing = useCallback(async (idPhoto: File) => {
    try {
      setIsDoubleCheck(false);
      setAu10tixError(null);
      setAu10TixLoading(true);

      const requestData: Au10tixProcessRequestData = {
        identityDocumentProcessingRequest: {
          documentPages: [
            {
              documentImages: [
                {
                  imageUri: 'documentImage_Page0',
                },
              ],
            },
          ],
          imageSource: 'UncertifiedScanner',
          isDataExtractionOnly: false,
        },
      };

      if (forceAu10tixDoubleCheck) {
        requestData.identityDocumentProcessingRequest.processingHints = {
          DoubleCheckForcingDirective: 20,
        };
      }

      const formData = new FormData();
      formData.append('documentImage_Page0', idPhoto);
      formData.append('requestData', JSON.stringify(requestData));

      logger.info(loggerMessages.au10tix.info.beginProcessing);

      const response = await axios.post('/api/safe-passage/v1/au10tix/Au10tixBos4/IdentityDocuments/BeginProcessingRequest', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      const { data } = response;
      if (data.RequestId) {
        logger.info(loggerMessages.au10tix.info.beginProcessingResponse, { requestId: data.RequestId });
        setRequestId(data.RequestId);
      }
    } catch (error) {
      const errorMessage = getMessageFromError(error);
      logger.warn(loggerMessages.au10tix.error.beginProcessing, {
        type: 'ERR_AU10TIX_PROCESSING',
        errorMessage,
        aggregates: {
          errorCode: Au10tixErrorCodes.ERR_AU10TIX_PROCESSING,
        },
      });
      setAu10tixError('Error processing request');
    }
  }, [forceAu10tixDoubleCheck]);

  const resetAu10tix = useCallback(() => {
    setAu10TixAgeResult(null);
    setAu10TixLoading(false);
    setAu10tixError(null);
    setRequestId(null);
  }, []);

  const cancelCheckInterval = useCallback(() => {
    if (checkIntervalRef.current) {
      clearInterval(checkIntervalRef.current);
    }
  }, []);

  useEffect(() => {
    let attempt = 0;
    let shouldDoubleCheck = false;
    if (au10tixRequestId) {
      checkIntervalRef.current = setInterval(async () => {
        if (attempt >= maxAttempts) {
          logger.warn(errorMessages[Au10tixErrorCodes.ERR_AU10TIX_FAIL_MAX_ATTEMPTS], {
            requestId: au10tixRequestId,
            type: 'ERR_AU10TIX_FAIL_MAX_ATTEMPTS',
            errorMessage: errorMessages[Au10tixErrorCodes.ERR_AU10TIX_FAIL_MAX_ATTEMPTS],
            aggregates: {
              errorCode: Au10tixErrorCodes.ERR_AU10TIX_FAIL_MAX_ATTEMPTS,
            },
          });
          cancelCheckInterval();
          setAu10tixError('Please upload a higher quality photo and try again');
          setAu10TixLoading(false);
          return;
        }
        attempt += 1;
        try {
          logger.info(loggerMessages.au10tix.info.gettingResult, {
            attempt,
            requestId: au10tixRequestId,
          });
          const endpoint = shouldDoubleCheck
            ? 'DoubleCheckResults'
            : 'Results';
          const response = await axios.get(`/api/safe-passage/v1/au10tix/Au10tixBos4/IdentityDocuments/${endpoint}/${au10tixRequestId}`);

          const { data } = response;
          logger.info(loggerMessages.au10tix.info.results, {
            data,
            attempt,
            requestId: au10tixRequestId,
          });
          if (data) {
            const {
              age, isAuthentic, isExpired, is18Plus, doubleCheck,
            } = extractAu10tixResult(data);
            if (doubleCheck) {
              shouldDoubleCheck = true;
              setIsDoubleCheck(true);
              logger.info(loggerMessages.au10tix.info.doubleCheck, {
                attempt,
                requestId: au10tixRequestId,
              });
              return;
            }

            cancelCheckInterval();
            setAu10TixLoading(false);

            // Under 18 years old
            if (!is18Plus) {
              logger.warn(errorMessages[Au10tixErrorCodes.ERR_AU10TIX_FAIL_AGE], {
                requestId: au10tixRequestId,
                age,
                type: 'ERR_AU10TIX_FAIL_AGE',
                errorMessage: errorMessages[Au10tixErrorCodes.ERR_AU10TIX_FAIL_AGE],
                aggregates: {
                  errorCode: Au10tixErrorCodes.ERR_AU10TIX_FAIL_AGE,
                },
              });
              setAu10TixAgeResult({
                age,
                status: 'fail',
                message: 'ID is not 18+',
                failCode: Au10tixErrorCodes.ERR_AU10TIX_FAIL_AGE,
              });
              return;
            }

            // ID is expired or expiration date was not readable
            if (isExpired === true || isExpired === null) {
              logger.warn(errorMessages[Au10tixErrorCodes.ERR_AU10TIX_FAIL_EXPIRED], {
                requestId: au10tixRequestId,
                type: 'ERR_AU10TIX_FAIL_EXPIRED',
                errorMessage: errorMessages[Au10tixErrorCodes.ERR_AU10TIX_FAIL_EXPIRED],
                aggregates: {
                  errorCode: Au10tixErrorCodes.ERR_AU10TIX_FAIL_EXPIRED,
                },
              });
              setAu10TixAgeResult({
                age,
                status: 'fail',
                message: 'ID is expired',
                failCode: Au10tixErrorCodes.ERR_AU10TIX_FAIL_EXPIRED,
              });
              return;
            }

            // Not an authentic ID
            if (!isAuthentic) {
              logger.warn(errorMessages[Au10tixErrorCodes.ERR_AU10TIX_FAIL_AUTHENTIC], {
                requestId: au10tixRequestId,
                type: 'ERR_AU10TIX_FAIL_AUTHENTIC',
                errorMessage: errorMessages[Au10tixErrorCodes.ERR_AU10TIX_FAIL_AUTHENTIC],
                aggregates: {
                  errorCode: Au10tixErrorCodes.ERR_AU10TIX_FAIL_AUTHENTIC,
                },
              });
              setAu10TixAgeResult({
                age,
                status: 'fail',
                message: 'ID is not authentic',
                failCode: Au10tixErrorCodes.ERR_AU10TIX_FAIL_AUTHENTIC,
              });
              return;
            }

            logger.info(loggerMessages.au10tix.info.pass, { requestId: au10tixRequestId });
            setAu10TixAgeResult({
              age,
              status: 'success',
            });
          }
        } catch (error) {
          const errorMessage = getMessageFromError(error);
          logger.warn(loggerMessages.au10tix.error.getResults, {
            type: 'ERR_AU10TIX_RESULT',
            errorMessage,
            attempt,
            requestId: au10tixRequestId,
            aggregates: {
              errorCode: Au10tixErrorCodes.ERR_AU10TIX_RESULT,
            },
          });

          cancelCheckInterval();
          setAu10tixError('Error fetching result');
          setAu10TixLoading(false);
        }
      }, checkInterval);
    }
    return () => {
      cancelCheckInterval();
    };
  }, [au10tixRequestId, cancelCheckInterval]);

  return {
    beginAu10tixProcessing,
    au10tixAgeResult,
    au10tixRequestId,
    au10tixLoading,
    au10tixError,
    isDoubleCheck,
    setAu10tixError,
    resetAu10tix,
    cancelCheckInterval,
    setAu10TixLoading,
    setRequestId,
  };
}

export default useAu10tixAgeVerification;
