import logger from 'services/logger';
import { Au10tixResult } from 'types/Au10tix';

function extractAu10tixResult(data: Au10tixResult) {
  const { DocumentAuthenticity, ProcessingResult, DocumentStatusReport2 } = data;

  if (!DocumentStatusReport2) {
    logger.error('Invalid Au10tix data', { data: JSON.stringify(data) });
    throw new Error('Invalid Au10tix data');
  }

  const { PrimaryProcessingResult, ProcessingResultRemarks } = DocumentStatusReport2;

  if (ProcessingResultRemarks) {
    logger.info('Au10tix data has ProcessingResultRemarks', { remarks: ProcessingResultRemarks });

    if (ProcessingResultRemarks.includes(380)) {
      // 380 means Au10tix double check was triggered
      // This can take several minutes. The user can either wait or resubmit a better image
      // We must check this first because otherwise the ID will be marked as invalid
      return {
        age: null,
        isAuthentic: null,
        isExpired: null,
        is18Plus: null,
        doubleCheck: true,
      };
    }

    if (ProcessingResultRemarks.includes(100)) {
      // 100 means the ID is expired
      return {
        age: null,
        isAuthentic: null,
        isExpired: true,
        is18Plus: null,
        doubleCheck: false,
      };
    }

    // PrimaryProcessingResult should be 0 if the ID is valid
    if (PrimaryProcessingResult !== 0) {
    // ID failed Au10tix validation
      return {
        age: null,
        isAuthentic: false,
        isExpired: null,
        is18Plus: null,
        doubleCheck: false,
      };
    }
  }

  if (!DocumentAuthenticity || !ProcessingResult) {
    logger.error('Invalid Au10tix data', { data: JSON.stringify(data) });
    throw new Error('Invalid Au10tix data');
  }

  const isAuthentic = DocumentAuthenticity === 'Authentic';
  const dateOfBirth = ProcessingResult?.DocumentData2?.DateOfBirth?.Value;
  const expirationDate = ProcessingResult?.DocumentData2?.DateOfExpiry?.Value;
  const isExpired = expirationDate
    ? new Date(expirationDate) < new Date()
    : null;
  const age = dateOfBirth
    ? ((Date.now() - new Date(dateOfBirth).valueOf()) / 1000 / 60 / 60 / 24 / 365)
    : null;
  const is18Plus = age
    ? age > 18
    : null;

  const res = {
    age,
    isAuthentic,
    isExpired,
    is18Plus,
    doubleCheck: false,
  };

  logger.info('Au10tix Result', { result: res });
  return res;
}

export default extractAu10tixResult;
