import { Tensor } from 'onnxruntime-web';

function imageDataToTensor(image: ImageData): Tensor {
  const imageData = image.data;

  const tensorData = new Float32Array(image.width * image.height * 3);

  // Fill tensorData by iterating over imageData
  for (let i = 0, j = 0; i < imageData.length; i += 4, j += 3) {
    tensorData[j] = imageData[i] / 255.0; // R
    tensorData[j + 1] = imageData[i + 1] / 255.0; // G
    tensorData[j + 2] = imageData[i + 2] / 255.0; // B
    // Ignoring alpha channel: data[i + 3]
  }

  const tensor = new Tensor('float32', tensorData, [1, image.width, image.height, 3]);
  return tensor;
}

export default imageDataToTensor;
