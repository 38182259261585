import {
  useRef,
  useMemo,
  useState,
  useEffect,
} from 'react';
import { DocumentScannerGuidelinesProps } from './types';
import { guidelineConfig } from './config';
import PDF417Barcode from './PDF417Barcode';

/**
 * Heads up to anyone editing in here.
 * You cannot arbitrarily edit the dimensions of the guidelines.
 * They're assigned to constants that the DocumentScanner uses as well.
 *
 * If they're resized here then the scannable region will differ!!!
 */

function DocumentScannerGuidelines({
  type,
} : DocumentScannerGuidelinesProps) {
  const containerRef = useRef<HTMLDivElement>(null);
  const [contentRect, setContentRect] = useState<DOMRectReadOnly | null>(null);

  useEffect(() => {
    const { current: container } = containerRef;

    const observer = new ResizeObserver((entries) => {
      const match = entries.find((entry) => entry.target === container);

      if (match) {
        setContentRect(match.contentRect);
      }
    });

    observer.observe(container as Element);

    return () => {
      observer.disconnect();
    };
  }, []);

  const content = useMemo(() => {
    if (contentRect === null) {
      return null;
    }

    if (type === 'us-id') {
      const width = Math.round(contentRect.width * 0.9);
      const height = Math.round(width * 0.325);

      return (
        <div style={{ width, height }} className="relative flex items-center justify-center rounded-lg bg-blue-500 bg-opacity-50 outline outline-4 outline-white">
          <div className="absolute w-full h-[2px] bg-blue-200 animate-scanline" />
          <div className="h-3/4">
            <PDF417Barcode className="h-full w-auto fill-blue-200 opacity-50" />
          </div>
        </div>
      );
    }

    const config = guidelineConfig[type];

    const width = Math.round(contentRect.width * 0.9);
    const height = Math.round(width * config.ratio);

    const boxWidth = width;
    const boxHeight = Math.round(boxWidth * config.boxSize);

    const fontSize = contentRect.width * (type === 'passport' ? 0.03067 : 0.03374);

    return (
      <div style={{ width, height }} className="flex flex-col justify-end overflow-hidden rounded-lg outline outline-4 outline-white">
        <div style={{ width: boxWidth, height: boxHeight }} className="relative bg-blue-500 border-t-4 border-white bg-opacity-50">
          <div className="absolute w-full h-[2px] bg-blue-200 animate-scanline" />
          <div style={{ fontSize }} className="w-full h-full flex items-center justify-center font-mono">
            { type === 'passport' ? (
              <div className="flex flex-col text-blue-200 opacity-80">
                <span>{'P<USAERIKSSON<<ANNA<MARIA<<<<<<<<<<<<<<<<<<<'}</span>
                <span>{'L898902C36UTO7408122F1204159ZE184226B<<<<<10'}</span>
              </div>
            ) : (
              <div className="flex flex-col text-blue-200 opacity-80">
                <span>{'I<USAD231458907<<<<<<<<<<<<<<<'}</span>
                <span>{'7408122F1204159UTO<<<<<<<<<<<6'}</span>
                <span>{'ERIKSSON<<ANNA<MARIA<<<<<<<<<<'}</span>
              </div>
            ) }
          </div>
        </div>
      </div>
    );
  }, [
    type,
    contentRect,
  ]);

  return (
    <div ref={containerRef} className="absolute w-full h-full flex items-center justify-center">
      { content }
    </div>
  );
}

export default DocumentScannerGuidelines;
