const loadImage = (src: string): Promise<HTMLImageElement> => new Promise((resolve, reject) => {
  let timeoutId: number | undefined;

  const image = new Image();

  image.onload = () => {
    clearTimeout(timeoutId);

    image.onload = null;
    image.onerror = null;

    resolve(image as HTMLImageElement);
  };

  image.onerror = () => {
    clearTimeout(timeoutId);

    image.onload = null;
    image.onerror = null;

    reject(new Error('Image failed to load'));
  };

  image.src = src;

  timeoutId = setTimeout(image.onerror, 10000);
});

export default loadImage;
