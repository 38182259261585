import React from 'react';
import { useTranslation } from 'react-i18next';

function NoMatchRoute() {
  const { t } = useTranslation();
  return (
    <div>
      <h2>{t('pageNotFound')}</h2>
    </div>
  );
}

export default NoMatchRoute;
