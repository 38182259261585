import { AxiosError } from 'axios';

const getErrorMessage = (err: AxiosError | Error, defaultMessage?: string) => {
  if (err instanceof AxiosError) {
    if (err?.isAxiosError) {
      if (err?.response?.data?.errors?.[0]?.text) {
        return err.response.data.errors[0].text;
      }
    }
  }

  return err?.message || defaultMessage || 'An unknown error has occurred.';
};

export default getErrorMessage;
