import React, { useEffect, useLayoutEffect } from 'react';
import { ExclamationCircleIcon } from '@heroicons/react/24/solid';
import clsx from 'clsx';
import Button from 'components/Button';
import { Link } from 'react-router-dom';
import logger from 'services/logger';
import useConfigStore from 'stores/configStore';
import { loggerMessages } from 'types/logger';
import useAgeDetectionStore from 'stores/ageDetectionStore';
import IDPhotoGuidelines from 'components/IDPhotoGuidelines';
import Text from 'components/Text';
import { useTranslation } from 'react-i18next';
import mixpanel from 'services/mixpanel';

interface FailureProps {
  authority: 'au10tix' | 'emblem';
  errorType: string | null;
  errorCode: string | null;
  mintError: string | null;
}

const errorRedirects: Record<number, string> = {
  1: '/verify-age',
  2: '/verify-id',
  3: '/verify-id-info',
};

function Failure({
  authority, errorType, mintError, errorCode,
}: FailureProps) {
  const basePath = authority === 'au10tix' ? '/au10tix' : '';
  const errorNumber = parseInt(errorType || '');
  const errorUrl = errorRedirects[errorNumber] ?? '/';
  const tryAgainRoute = `${basePath}${errorUrl}`;
  const isRetry = useAgeDetectionStore((s) => s.isRetry);
  const setIsRetry = useAgeDetectionStore((s) => s.setIsRetry);
  const reset = useAgeDetectionStore((s) => s.reset);
  const supportWebsiteUrl = useConfigStore((s) => s.supportWebsiteUrl);
  const redirectUrl = useConfigStore((s) => s.redirectUrl);
  const { t } = useTranslation();

  const code = Number(errorCode);

  useLayoutEffect(() => {
    logger.info(loggerMessages.phases.info.fail, { aggregates: { phaseProgress: true, isRetry } });
    logger.flush();
  }, []);

  const genericError = t('error.generic');

  let errorMsg = t('failure.errorMessage');
  if (code) {
    errorMsg = t(`error.dynamic.${code}`, { defaultValue: genericError });
  }

  if (mintError) {
    errorMsg = mintError;
  }

  useEffect(() => {
    if (errorNumber === 1) {
      reset();
    }
  }, [errorNumber, reset]);

  return (
    <div className="flex flex-1 flex-col items-center">
      <div className="flex flex-1 flex-col items-center justify-center">
        <ExclamationCircleIcon
          className={clsx('mb-4 h-32 w-32 rounded-full border text-red-400')}
        />
        <h1 className="text-center text-lg font-bold tracking-tight">
          {t('failure.title')}
        </h1>
        <Text className="text-center">
          {errorMsg}
        </Text>
      </div>
      {errorNumber === 2 && <IDPhotoGuidelines className="p-0 py-2" />}
      <Button
        id="try-again-link"
        component={Link}
        to={tryAgainRoute}
        className="mt-4"
        onClick={() => {
          mixpanel.trackEvent({ event: 'Failure Try Again Click' });
          setIsRetry(true);
          logger.info(loggerMessages.phases.info.retry, {
            aggregates: {
              redirectTo: errorUrl,
            },
          });
        }}
      >
        {t('failure.tryAgain')}
      </Button>
      {supportWebsiteUrl && (
        <>
          <Text className="my-4 text-center">{t('or')}</Text>
          <Button
            id="return-to-site-link"
            component="a"
            variant="secondary"
            href={supportWebsiteUrl}
          >
            {t('failure.returnToSite')}
          </Button>
        </>
      )}
      {!supportWebsiteUrl && redirectUrl && (
        <>
          <Text className="my-4 text-center">{t('or')}</Text>
          <Button
            id="manual-review-link"
            component={Link}
            to="/appeal"
            className="!px-0"
            onClick={() => {
              mixpanel.trackEvent({ event: 'Failure Submit For Review Click' });
            }}
          >
            {t('failure.submitForReview')}
          </Button>
        </>
      )}
    </div>
  );
}

export default Failure;
