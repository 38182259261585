import React, { CSSProperties } from 'react';
import createCardDimensions from 'utils/createCardDimensions';

interface Props {
  height: number;
  width: number;
  style: CSSProperties
}

function LandscapeCardSVG({ height, width, style }: Props) {
  const { cardHeight, cardWidth } = createCardDimensions(height, width, 'landscape');
  const maskX = (width / 2) - (cardWidth / 2);
  const maskY = (height / 2) - (cardHeight / 2);

  const photoWidth = cardWidth * 0.25;
  const photoHeight = cardHeight * 0.5;

  const photoX = maskX + (width * 0.05);
  const photoY = (height / 2) - (photoHeight / 2);
  return (
    <svg
      viewBox={`0 0 ${width} ${height}`}
      preserveAspectRatio="xMinYMin slice"
      style={{ width: '100%', ...style }}
    >
      <mask id="cardMask">
        <rect x="0" y="0" width="100%" height="100%" fill="#808080" />
        <rect
          x={maskX}
          y={maskY}
          width={cardWidth}
          height={cardHeight}
          fill="black"
          rx="10"
          ry="10"
        />
      </mask>

      <rect
        width={width}
        height={height}
        mask="url(#cardMask)"
      />

      {/* this rect creates the white border */}
      <rect
        x={maskX}
        y={maskY}
        width={cardWidth}
        height={cardHeight}
        fill="transparent"
        rx="10"
        ry="10"
        style={{
          stroke: 'white',
          strokeWidth: 4,
        }}
      />

      {/* this rect creates the dotted border for the photo */}
      <rect
        width={photoWidth}
        height={photoHeight}
        x={photoX}
        y={photoY}
        style={{
          fill: 'transparent',
          strokeWidth: 4,
          stroke: 'white',
          strokeDasharray: '5 5',
        }}
      />
    </svg>
  );
}

export default LandscapeCardSVG;
