import React, {
  useCallback, useEffect, useRef, useState,
} from 'react';
import { PhotoIcon } from '@heroicons/react/24/outline';
import Button from 'components/Button';
import Loader from 'components/Loader';
import setVideoSource from 'utils/setVideoSource';
import stopStream from 'utils/stopStream';
import useFileReader from 'hooks/useFileReader';
import { getMessageFromError } from 'utils/errorMessage';
import logger from 'services/logger';
import Text from 'components/Text';
import { useTranslation } from 'react-i18next';
import { StepProps } from './types';

function ConfirmPhoto({ formData, setStep, setFormData }: StepProps) {
  const videoRef = useRef<HTMLVideoElement | null>(null);
  const [isLoading, setIsLoading] = useState(!formData.userImageUrl);
  const [error, setError] = useState('');
  const { t } = useTranslation();

  const {
    file,
    setFile,
    fileUrl,
  } = useFileReader();

  useEffect(() => {
    if (!fileUrl || !file) return;
    setFormData({ userImageUrl: fileUrl, userImage: file });
  }, [fileUrl, file, setFormData]);

  const snapPhoto = useCallback(async () => {
    try {
      const canvas = document.createElement('canvas');
      const video = videoRef.current;
      if (!canvas || !video) {
        return;
      }
      const context = canvas.getContext('2d');
      const width = 1000;
      const height = (video.videoHeight / video.videoWidth) * width;

      canvas.width = width;
      canvas.height = height;
      context?.drawImage(video, 0, 0, width, height);

      canvas.toBlob((blob) => {
        if (blob) {
          const imgFile = new File([blob], 'image.png');
          const mediaStream = video.srcObject as MediaStream;
          stopStream(mediaStream);
          setFile(imgFile);
        }
      });
    } catch (err) {
      console.error(err);
      setError(t('appeal.confirm.photo.errorAndRetake'));
      const errorMessage = getMessageFromError(err);
      logger.warn('Error taking photo', { errorMessage });
    }
  }, [setFile]);

  const setVideo = useCallback(async () => {
    if (videoRef.current) {
      await setVideoSource(videoRef.current, 'user', (err) => console.error(err));
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    if (isLoading) setVideo();
  }, [isLoading, setVideo]);

  return (
    <div className="h-full">
      <h1 className="text-left text-2xl tracking-tight font-bold text-white">{t('appeal.confirm.photo.title')}</h1>
      <Text className="text-left mt-2 mb-6">
        {t('appeal.confirm.photo.description')}
      </Text>
      <div className="w-full relative">
        {formData.userImageUrl ? (
          <img
            src={formData.userImageUrl}
            alt="uploaded"
            className="w-full object-cover h-full rounded-md"
            style={{ transform: 'rotateY(180deg)' }}
          />
        ) : (
          <video
            className="w-full bg-gray-300 rounded-md"
            ref={videoRef}
            style={{ transform: 'rotateY(180deg)' }}
            playsInline
            autoPlay
            muted
          />
        )}
        {isLoading && <Loader className="absolute left-0 right-0 top-0 bottom-0 m-auto w-10 text-primary-500" />}
        {error && (
          <div
            className="absolute left-0 right-0 bottom-4 bg-black w-fit mx-auto p-2 rounded-md text-sm"
          >
            {error}
          </div>
        )}
      </div>
      {formData.userImageUrl ? (
        <div className="flex flex-wrap justify-center items-center">
          <Button
            className="my-4"
            type="button"
            onClick={() => {
              setStep('ConfirmId');
            }}
            disabled={!!error}
          >
            {t('appeal.confirm.photo.confirmAndProceed')}
          </Button>
          <Button
            className="flex justify-center items-center gap-2"
            type="button"
            variant="secondary"
            onClick={() => {
              setFormData({ userImageUrl: '' });
              setIsLoading(true);
              setError('');
            }}
          >
            <PhotoIcon width={25} />
            {' '}
            {t('appeal.confirm.photo.retakeNewPhoto')}
          </Button>
        </div>
      ) : (
        <Button
          className="my-4"
          type="button"
          onClick={() => {
            snapPhoto();
          }}
          disabled={isLoading}
        >
          {t('appeal.confirm.photo.takePhoto')}
        </Button>
      )}
    </div>
  );
}

export default ConfirmPhoto;
