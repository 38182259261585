import clsx from 'clsx';
import React, { useMemo, memo } from 'react';

const strokeWidth = 8;

interface Props {
  cx: number;
  cy: number;
  radius: number;
  hidden: boolean;
}

function CompleteCircleSVG({
  cx, cy, radius, hidden,
}:Props) {
  const _cx = cx + strokeWidth;
  const _cy = cy + strokeWidth;

  const offset = strokeWidth * 2;

  const computedStyle: React.CSSProperties = useMemo(() => ({
    position: 'absolute',
    top: `${-strokeWidth}px`,
    left: `${-strokeWidth}px`,
    fill: 'none',
    width: `${_cx + radius + offset}px`,
    height: `${_cy + radius + offset}px`,
  }), [_cx, _cy, radius, offset]);

  return (
    <svg style={computedStyle}>
      <circle
        cx={_cx}
        cy={_cy}
        r={radius}
        fill="none"
        stroke="#059669"
        strokeWidth={strokeWidth * 2}
        className={clsx('opacity-1 transition-[opacity] ease-in-out duration-500', {
          'opacity-0': hidden,
        })}
      />
    </svg>
  );
}

export default memo(CompleteCircleSVG);
