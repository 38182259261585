import React, { useCallback } from 'react';
import clsx from 'clsx';
import { ArrowLeftIcon } from '@heroicons/react/24/outline';
import { useNavigate } from 'react-router-dom';
import logger from 'services/logger';
import EmblemLogo from './EmblemLogo';

interface HeaderProps {
  includeBackButton?: boolean;
  basePath?: string;
  backButtonHandler?: () => void;
}

function Header({ includeBackButton = false, backButtonHandler }: HeaderProps) {
  const navigate = useNavigate();

  const navigateBack = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  return (
    <header
      className={clsx('px-8 max-w-2xl items-center md:items-start flex flex-col w-full mx-auto md:mx-0 flex-col !mx-auto md:items-center', {
        'gap-2 align-middle py-4': includeBackButton,
        'flex-col items-start w-full py-8': !includeBackButton,
      })}
    >
      {includeBackButton ? (
        <button
          onClick={() => {
            logger.info('Back button clicked', { currentPath: window.location.pathname });
            if (backButtonHandler) {
              backButtonHandler();
              return;
            }
            navigateBack();
          }}
          className="w-8 h-8 text-neutral-500 absolute top-4 left-4"
          type="button"
        >
          <ArrowLeftIcon className="w-8 h-8" />
        </button>
      ) : null}
      <EmblemLogo className="h-10 w-auto" />
    </header>
  );
}

export default Header;
