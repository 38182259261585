import clsx from 'clsx';
import React from 'react';

interface EmblemLogoProps {
  className?: string;
  style?: React.CSSProperties;
}

function EmblemLogo({ className = '', style = {} }: EmblemLogoProps) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="233" height="77" viewBox="0 0 233 77" fill="none" className={clsx(className)} style={style}>
      <path d="M107.938 27.8776C109.118 28.3376 110.138 29.0175 111.018 29.8575C111.858 30.6975 112.538 31.7576 112.958 32.8976C113.418 34.0776 113.638 35.3476 113.638 36.6576V40.7576H99.4476C99.5276 41.9776 99.9576 42.9976 100.668 43.7576C101.508 44.5576 102.738 45.0276 104.218 45.0276C105.568 45.0276 106.748 44.6075 107.598 43.8475L110.728 47.2676C109.038 48.8276 106.758 49.6775 104.228 49.6775C102.878 49.6775 101.608 49.4676 100.468 48.9976C99.2876 48.5376 98.2676 47.8976 97.4276 47.0176C96.5376 46.1776 95.9076 45.1575 95.4476 43.9775C94.9876 42.8375 94.7676 41.5675 94.7676 40.2175V36.6675C94.7676 35.3575 94.9776 34.0876 95.4476 32.9076C95.9076 31.7676 96.5476 30.7076 97.4276 29.8676C98.2676 29.0276 99.2876 28.3476 100.468 27.8876C101.608 27.4676 102.878 27.2075 104.228 27.2075C105.578 27.2075 106.808 27.4576 107.948 27.8876L107.938 27.8776ZM100.678 33.1576C99.9576 33.8776 99.5376 34.8876 99.4576 36.1176H108.998C108.868 34.8976 108.448 33.8776 107.728 33.1576C106.888 32.3176 105.658 31.8475 104.218 31.8475C102.778 31.8475 101.518 32.3076 100.668 33.1576H100.678Z" fill="white" />
      <path d="M144.128 29.6075C145.688 31.1675 146.538 33.3676 146.538 35.7776V49.6775H141.888V35.7776C141.888 33.3276 140.448 31.8475 137.998 31.8475C135.548 31.8475 134.068 33.3276 134.068 35.7776V49.6775H129.418V35.7776C129.418 33.3276 127.938 31.8475 125.528 31.8475C123.118 31.8475 121.598 33.3276 121.598 35.7776V49.6775H116.948V27.2075H121.598V28.0475C122.778 27.4975 124.088 27.2075 125.528 27.2075C127.938 27.2075 130.128 28.0976 131.648 29.6176C131.688 29.6576 131.688 29.6975 131.728 29.6975C131.768 29.6975 131.768 29.6576 131.808 29.6176C133.368 28.0976 135.528 27.2075 137.978 27.2075C140.428 27.2075 142.578 28.0976 144.098 29.6176L144.128 29.6075Z" fill="white" />
      <path d="M156.458 27.8774C157.598 27.4574 158.868 27.1974 160.218 27.1974C161.568 27.1974 162.798 27.4474 163.938 27.8774C165.118 28.3374 166.138 29.0174 167.018 29.8574C167.858 30.6974 168.538 31.7575 168.958 32.8975C169.418 34.0775 169.638 35.3475 169.638 36.6575V40.2074C169.638 41.5574 169.428 42.8274 168.958 43.9674C168.538 45.1474 167.858 46.1674 167.018 47.0074C166.128 47.8974 165.118 48.5274 163.938 48.9874C162.798 49.4474 161.528 49.6674 160.218 49.6674C158.908 49.6674 157.598 49.4574 156.458 48.9874C156.078 48.8574 155.738 48.6975 155.398 48.5275V49.6674H150.748V20.1074H155.398V28.3875L156.458 27.8774ZM156.668 33.1575C155.868 33.9975 155.398 35.2274 155.398 36.6674V40.2174C155.398 41.6974 155.858 42.9175 156.668 43.7675C157.508 44.5675 158.738 45.0374 160.218 45.0374C161.698 45.0374 162.878 44.5775 163.728 43.7675C164.568 42.9275 164.998 41.6974 164.998 40.2174V36.6674C164.998 35.2274 164.578 34.0075 163.728 33.1575C162.888 32.3175 161.658 31.8474 160.218 31.8474C158.778 31.8474 157.518 32.3075 156.668 33.1575Z" fill="white" />
      <path d="M173.008 49.6674V20.1074H177.658V49.6674H173.008Z" fill="white" />
      <path d="M194.208 27.8776C195.388 28.3376 196.408 29.0175 197.288 29.8575C198.128 30.6975 198.808 31.7576 199.228 32.8976C199.688 34.0776 199.908 35.3476 199.908 36.6576V40.7576H185.718C185.798 41.9776 186.228 42.9976 186.938 43.7576C187.778 44.5576 189.008 45.0276 190.488 45.0276C191.838 45.0276 193.018 44.6075 193.868 43.8475L196.998 47.2676C195.308 48.8276 193.028 49.6775 190.498 49.6775C189.148 49.6775 187.878 49.4676 186.738 48.9976C185.558 48.5376 184.538 47.8976 183.698 47.0176C182.808 46.1776 182.178 45.1575 181.718 43.9775C181.258 42.8375 181.038 41.5675 181.038 40.2175V36.6675C181.038 35.3575 181.248 34.0876 181.718 32.9076C182.178 31.7676 182.818 30.7076 183.698 29.8676C184.538 29.0276 185.558 28.3476 186.738 27.8876C187.878 27.4676 189.148 27.2075 190.498 27.2075C191.848 27.2075 193.078 27.4576 194.218 27.8876L194.208 27.8776ZM186.948 33.1576C186.228 33.8776 185.808 34.8876 185.728 36.1176H195.268C195.138 34.8976 194.718 33.8776 193.998 33.1576C193.158 32.3176 191.928 31.8475 190.488 31.8475C189.048 31.8475 187.788 32.3076 186.938 33.1576H186.948Z" fill="white" />
      <path d="M230.448 29.6075C232.008 31.1675 232.858 33.3676 232.858 35.7776V49.6775H228.208V35.7776C228.208 33.3276 226.768 31.8475 224.318 31.8475C221.868 31.8475 220.388 33.3276 220.388 35.7776V49.6775H215.738V35.7776C215.738 33.3276 214.258 31.8475 211.848 31.8475C209.438 31.8475 207.918 33.3276 207.918 35.7776V49.6775H203.268V27.2075H207.918V28.0475C209.098 27.4975 210.408 27.2075 211.848 27.2075C214.258 27.2075 216.448 28.0976 217.968 29.6176C218.008 29.6576 218.008 29.6975 218.048 29.6975C218.088 29.6975 218.088 29.6576 218.128 29.6176C219.688 28.0976 221.848 27.2075 224.298 27.2075C226.748 27.2075 228.898 28.0976 230.418 29.6176L230.448 29.6075Z" fill="white" />
      <path d="M65.2174 9.8877H57.1274V13.3977H63.4774V19.1377L60.8874 21.7277L53.8774 14.7177C53.1874 14.0277 52.0674 14.0277 51.3774 14.7177L43.1274 22.9677C42.4374 23.6577 42.4374 24.7777 43.1274 25.4677L51.3774 33.7177C52.0674 34.4077 53.1874 34.4077 53.8774 33.7177L66.4674 21.1277C66.7974 20.7977 66.9874 20.3477 66.9874 19.8777V11.6676C66.9874 10.6876 66.1974 9.89771 65.2174 9.89771V9.8877Z" fill="white" />
      <path d="M11.6274 66.9373H19.7174V63.4273H13.3674V57.6873L15.9574 55.0973L22.9674 62.1073C23.6574 62.7973 24.7774 62.7973 25.4674 62.1073L33.7174 53.8573C34.4074 53.1673 34.4074 52.0473 33.7174 51.3573L25.4674 43.1073C24.7774 42.4173 23.6574 42.4173 22.9674 43.1073L10.3774 55.6973C10.0474 56.0273 9.85742 56.4773 9.85742 56.9473V65.1574C9.85742 66.1374 10.6474 66.9273 11.6274 66.9273V66.9373Z" fill="white" />
      <path d="M46.6877 35.9377L39.6777 28.9277C38.9877 28.2377 37.8677 28.2377 37.1777 28.9277L28.9277 37.1777C28.2377 37.8677 28.2377 38.9877 28.9277 39.6777L37.1777 47.9277C37.8677 48.6177 38.9877 48.6177 39.6777 47.9277L47.9277 39.6777C48.6177 38.9877 48.6177 37.8677 47.9277 37.1777L46.6977 35.9477L46.6877 35.9377Z" fill="#626366" />
      <path d="M75.0876 35.9377L68.0776 28.9277C67.3876 28.2377 66.2676 28.2377 65.5776 28.9277L57.3276 37.1777C56.6376 37.8677 56.6376 38.9877 57.3276 39.6777L65.5776 47.9277C66.2676 48.6177 67.3876 48.6177 68.0776 47.9277L76.3276 39.6777C77.0176 38.9877 77.0176 37.8677 76.3276 37.1777L75.0976 35.9477L75.0876 35.9377Z" fill="#999999" />
      <path d="M46.6877 7.52751L39.6777 0.5175C38.9877 -0.1725 37.8677 -0.1725 37.1777 0.5175L28.9277 8.7675C28.2377 9.4575 28.2377 10.5775 28.9277 11.2675L37.1777 19.5175C37.8677 20.2075 38.9877 20.2075 39.6777 19.5175L47.9277 11.2675C48.6177 10.5775 48.6177 9.4575 47.9277 8.7675L46.6977 7.5374L46.6877 7.52751Z" fill="#999999" />
      <path d="M46.6877 64.3376L39.6777 57.3276C38.9877 56.6376 37.8677 56.6376 37.1777 57.3276L28.9277 65.5776C28.2377 66.2676 28.2377 67.3876 28.9277 68.0776L37.1777 76.3276C37.8677 77.0176 38.9877 77.0176 39.6777 76.3276L47.9277 68.0776C48.6177 67.3876 48.6177 66.2676 47.9277 65.5776L46.6977 64.3476L46.6877 64.3376Z" fill="#999999" />
      <path d="M18.2775 35.9377L11.2675 28.9277C10.5775 28.2377 9.4575 28.2377 8.7675 28.9277L0.5175 37.1777C-0.1725 37.8677 -0.1725 38.9877 0.5175 39.6777L8.7675 47.9277C9.4575 48.6177 10.5775 48.6177 11.2675 47.9277L19.5175 39.6777C20.2075 38.9877 20.2075 37.8677 19.5175 37.1777L18.2875 35.9477L18.2775 35.9377Z" fill="#999999" />
      <path d="M9.89746 11.6177V19.7076H13.4075V13.3577H19.1475L21.7375 15.9476L14.7275 22.9576C14.0375 23.6476 14.0375 24.7676 14.7275 25.4576L22.9775 33.7076C23.6675 34.3976 24.7875 34.3976 25.4775 33.7076L33.7275 25.4576C34.4175 24.7676 34.4175 23.6476 33.7275 22.9576L21.1375 10.3677C20.8075 10.0377 20.3575 9.84766 19.8875 9.84766H11.6775C10.6975 9.84766 9.90746 10.6377 9.90746 11.6177H9.89746Z" fill="white" />
      <path d="M66.9476 65.2173V57.1274H63.4376V63.4773H57.6976L55.1076 60.8874L62.1176 53.8774C62.8076 53.1874 62.8076 52.0674 62.1176 51.3774L53.8676 43.1274C53.1776 42.4374 52.0576 42.4374 51.3676 43.1274L43.1176 51.3774C42.4276 52.0674 42.4276 53.1874 43.1176 53.8774L55.7076 66.4673C56.0376 66.7973 56.4876 66.9873 56.9576 66.9873H65.1676C66.1476 66.9873 66.9376 66.1973 66.9376 65.2173H66.9476Z" fill="white" />
    </svg>
  );
}

export default EmblemLogo;
