import { Box } from '@vladmandic/human';

function detectCenteredFace(box:Box, videoWidth:number, videoHeight:number) {
  const [x, y, width, height] = box;

  // Get the center of the face
  const midX = x + width / 2;
  const midY = y + height / 2;

  // Get the percentage of the face's center relative to the video's width and height
  const midXPercent = midX / videoWidth;
  const midYPercent = midY / videoHeight;

  // If the face is centered, the percentage of the face's center should be between 0.2 and 0.8
  const centeredFace = midXPercent > 0.2 && midXPercent < 0.8 && midYPercent > 0.2 && midYPercent < 0.8;

  return centeredFace;
}

export default detectCenteredFace;
