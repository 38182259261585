import clsx from 'clsx';
import React, { ReactNode } from 'react';

interface ToastProps {
  message: string;
  className?: string;
  icon?: ReactNode;
}

function Toast({ message, icon, className }: ToastProps) {
  return (
    <div className={clsx('fixed bottom-10 left-4 flex p-2 shadow-lg bg-white rounded-md gap-2 items-center', className)}>
      <div>{icon}</div>
      <div className="w-[1px] bg-gray-300 min-h-[25px]" />
      <div className="text-sm">{message}</div>
    </div>
  );
}

export default Toast;
