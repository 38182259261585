export const guidelineConfig = {
  'non-us-id': {
    ratio: 0.6296, // 3.375in x 2.125in
    boxSize: 0.25,
  },
  passport: {
    ratio: 0.704, // 125mm x 88m
    boxSize: 0.20,
  },
};

export const renderConfig = {
  /**
   * The maximum renderScale I'm allowing for MRZ image sources.
   * Anything higher will be downscaled as it makes a big difference
   * with Androids to cap it at 2.
   */
  maxRenderScaleMRZ: 2,
  /**
   * The duration of the "scan line" animation
   */
  animationDuration: 2000,
  /**
   * Border radius of the guideline overlays
   */
  borderRadius: 8,
  /**
   * Line width of the guideline overlay strokes (lines)
   */
  lineWidth: 4,
  /**
   * Color of the guideline overlay strokes (lines)
   */
  strokeStyle: 'rgba(255, 0, 0, 1.0)',
  /**
   * Fill color of the "scannable" region within the guideline overlay.
   */
  fillStyle: 'rgba(255, 0, 0, 0.5)',
};

export default {
  renderConfig,
  guidelineConfig,
};
