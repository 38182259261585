import clsx from 'clsx';

interface HeadingProps {
  className?: string;
  children: React.ReactNode;
}

function Heading({
  className,
  children,
}: HeadingProps) {
  return (
    <h1 className={clsx('text-2xl tracking-tight font-bold text-neutral-50', className)}>
      {children}
    </h1>
  );
}

export default Heading;
