import {
  DocumentScanResult,
  MRZType,
  MRZConfig,
  MRZStats,
} from './types';

const MRZ: MRZType = {
  TD1: [{
    size: 30,
    expr: /([A|C|I][A-Z0-9<]{1})([A-Z<]{3})([A-Z0-9<]{9})([0-9]{1})([A-Z0-9<]{15})/,
  }, {
    size: 30,
    expr: /([0-9]{6})([0-9]{1})([M|F|X|<]{1})([0-9]{6})([0-9]{1})([A-Z<]{3})([A-Z0-9<]{11})([0-9]{1})/,
    data: [{
      index: 1,
      name: 'birthDate',
    }, {
      index: 4,
      name: 'expirationDate',
    }],
  }, {
    size: 30,
    expr: /([A-Z0-9<]{30})/,
  }],
  TD2: [{
    size: 36,
    expr: /([A|C|I][A-Z0-9<]{1})([A-Z]{3})([A-Z0-9<]{31})/,
  }, {
    size: 36,
    expr: /([A-Z0-9<]{9})([0-9]{1})([A-Z]{3})([0-9]{6})([0-9]{1})([M|F|X|<]{1})([0-9]{6})([0-9]{1})([A-Z0-9<]{7})([0-9]{1})/,
    data: [{
      index: 4,
      name: 'birthDate',
    }, {
      index: 7,
      name: 'expirationDate',
    }],
  }],
  TD3: [{
    size: 44,
    expr: /(P[A-Z0-9<]{1})([A-Z<]{3})([A-Z0-9<]{39})/,
  }, {
    size: 44,
    expr: /([A-Z0-9<]{9})([0-9]{1})([A-Z<]{3})([0-9]{6})([0-9]{1})([M|F|X|<]{1})([0-9]{6})([0-9]{1})([A-Z0-9<]{14})([0-9<]{1})([0-9]{1})/,
    data: [{
      index: 4,
      name: 'birthDate',
    }, {
      index: 7,
      name: 'expirationDate',
    }],
  }],
  MRVA: [{
    size: 44,
    expr: /(V[A-Z0-9<]{1})([A-Z]{3})([A-Z0-9<]{39})/,
  }, {
    size: 44,
    expr: /([A-Z0-9<]{9})([0-9]{1})([A-Z]{3})([0-9]{6})([0-9]{1})([M|F|X|<]{1})([0-9]{6})([0-9]{1})([A-Z0-9<]{16})/,
    data: [{
      index: 4,
      name: 'birthDate',
    }, {
      index: 7,
      name: 'expirationDate',
    }],
  }],
  MRVB: [{
    size: 36,
    expr: /(V[A-Z0-9<]{1})([A-Z]{3})([A-Z0-9<]{31})/,
  }, {
    size: 36,
    expr: /([A-Z0-9<]{9})([0-9]{1})([A-Z]{3})([0-9]{6})([0-9]{1})([M|F|X|<]{1})([0-9]{6})([0-9]{1})([A-Z0-9<]{8})/,
    data: [{
      index: 4,
      name: 'birthDate',
    }, {
      index: 7,
      name: 'expirationDate',
    }],
  }],
};

const processMRZMatches = (mrz: MRZConfig, matches: RegExpMatchArray[]): DocumentScanResult => {
  const data: Record<string, string> = {};

  mrz.forEach((mrzConfig, index) => {
    if (!mrzConfig.data) {
      return;
    }

    mrzConfig.data.forEach((meta) => {
      const str = matches[index][meta.index];
      const yy = str.substr(0, 2);
      const mm = str.substr(2, 2);
      const dd = str.substr(4, 2);

      let fullYear;

      /**
       * MRZ date formats are YYMMDD
       * It's up to the implementor to determine the full year.
       * If the two digit year provided is less than or equal to the current
       * two digit year (i.e, 2024 is 24) then I assume the base year of 2000.
       * So anything under (or equal) to the current two digit year is 2000 + yy.
       * (expirationDates will always use a base of 2000)
       *
       * Otherwise, you'll get 1900 + yy.
       */

      const currentYY = parseInt(new Date().getFullYear().toString().substr(2));

      if (meta.name === 'expirationDate' || parseInt(yy, 10) <= currentYY) {
        fullYear = `20${yy}`;
      } else {
        fullYear = `19${yy}`;
      }

      const date = `${mm}/${dd}/${fullYear}`;

      if (!Number.isNaN(new Date(date).getTime())) {
        data[meta.name] = date;
      }
    });
  });

  const result: DocumentScanResult = {
    type: 'mrz',
  };

  if ('expirationDate' in data) {
    result.expirationDate = data.expirationDate;
  }

  if ('birthDate' in data) {
    result.birthDate = data.birthDate;
  }

  return result;
};

const shouldLogMRZLines = (lines: string[] = [], stats: MRZStats): boolean => {
  for (const type of Object.keys(MRZ)) {
    let mrz: MRZConfig | null = MRZ[type];

    if (lines.length !== mrz.length) {
      continue;
    }

    for (let i = 0, l = mrz.length; i < l; i++) {
      const line = lines[i];
      const mrzConfig = mrz[i];

      if (mrzConfig.size !== line.length) {
        mrz = null;
        break;
      }
    }

    if (mrz) {
      return true;
    }

    /**
     * Update the linesIncomplete stat, as the MRZ type that matched didn't have the
     * required number of characters on one of the lines.
     */
    stats.linesIncomplete += 1;
  }

  /**
   * Update linesMissing stat, as no existing MRZ types matched the number of lines provided.
   */
  stats.linesMissing += 1;

  return false;
};

const processMRZLines = (lines: string[] = []): DocumentScanResult | undefined => {
  for (const type of Object.keys(MRZ)) {
    const mrz = MRZ[type];

    for (let i = 0, l = lines.length; i < l; i++) {
      const buffer: RegExpMatchArray[] = [];

      for (let ii = 0, ll = mrz.length; ii < ll; ii++) {
        const line = lines[i + ii];
        const mrzConfig = mrz[ii];

        if (!line) {
          break;
        }

        if (line.length !== mrzConfig.size) {
          break;
        }

        const match = line.match(mrzConfig.expr);

        if (!match) {
          break;
        }

        buffer.push(match);
      }

      if (buffer.length === mrz.length) {
        return processMRZMatches(mrz, buffer);
      }
    }
  }

  return undefined;
};

export {
  processMRZLines,
  shouldLogMRZLines,
};
