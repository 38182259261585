import React from 'react';
import { QueryClientProvider } from '@tanstack/react-query';
import queryClient from 'services/api/queryClient';
import ReactDOM from 'react-dom/client';
import AppRoutes from './AppRoutes';
import './index.css';
import './services/i18n/i18n';

const rootNode = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

rootNode.render(
  <QueryClientProvider client={queryClient}>
    <AppRoutes />
  </QueryClientProvider>,
);
