/* eslint-disable no-restricted-globals */

import logger from 'services/logger';

// Check if OffscreenCanvas is supported
function isOffscreenCanvasSupported() {
  // Window does not exist in a worker context
  // which is why we need to check for self
  const globalScope = typeof window !== 'undefined'
    ? window
    : (typeof self !== 'undefined' ? self : {});

  const offscreenCanvasSupported = 'OffscreenCanvas' in globalScope;
  if (!offscreenCanvasSupported) {
    return false;
  }
  const offscreen = new OffscreenCanvas(96, 96);
  const webglSupported = !!offscreen.getContext('webgl');
  logger.info('OffscreenCanvas supported:', { webglSupported });
  return webglSupported;
}

export default isOffscreenCanvasSupported;
