/**
 * Uses yaw and pitch as x,y coordinates to calculate the angle of the face.
 */
function calculateFaceAngle(yaw:number, pitch:number) {
  const x = yaw;
  const y = pitch;
  const tanAngle = Math.atan2(y, x) * (180 / Math.PI);

  /**
   * atan2 returns a value between -180 and 180
   * We need to convert it to a value between 0 and 360
   */
  if (tanAngle > 90) {
    return tanAngle - 90;
  }

  return 270 + tanAngle;
}

export default calculateFaceAngle;
