import { useEffect, useState } from 'react';
import { detect } from 'detect-browser';
import { LoggerCore, LoggerGlobal } from '@video/log-client';
import useConfigStore from 'stores/configStore';
import useVerificationStore from 'stores/verificationStore';

const logger = new LoggerCore('safe-passage-client');

// Force disable printing in tests
if (process.env.NODE_ENV === 'test') {
  // The logger stores console methods in memory and currently has no option/method to disable them
  (logger as any).printer = null;
}

export function useSetupLogger(logHostUrl: string) {
  const [isReady, setIsReady] = useState(false);
  const configLogs = useConfigStore((state) => state.configLogs);
  const {
    tagRequestId, projectKey, emblemState, setEmblemState, setTagRequestId, setProjectKey, setCompanyName,
  } = useVerificationStore((state) => state);

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const emblemStateParam = searchParams.get('emblemState');
    const tagRequestIdParam = searchParams.get('tagRequestId');
    const projectKeyParam = searchParams.get('projectKey');
    const companyNameParam = searchParams.get('companyName');

    if (emblemStateParam) setEmblemState(emblemStateParam);
    if (tagRequestIdParam) setTagRequestId(tagRequestIdParam);
    if (projectKeyParam) setProjectKey(projectKeyParam);
    if (companyNameParam) setCompanyName(companyNameParam);
    setIsReady(true);
  }, [setEmblemState, setProjectKey, setTagRequestId, setCompanyName]);

  useEffect(() => {
    if (!logHostUrl || !configLogs || !isReady) return;
    const browser = detect();

    const loggerGlobal = new LoggerGlobal();
    // Only write logs to Kibana when the app is built/deployed
    logger.setLoggerMeta('service', 'safe-passage-client');
    logger.setMessageAggregate('emblemState', emblemState);
    logger.setMessageAggregate('tagRequestId', tagRequestId || '');
    logger.setMessageAggregate('projectKey', projectKey);
    if (browser) {
      const mobileOs = ['iOS', 'Android OS', 'BlackBerry OS', 'Windows Mobile'];
      const isMobile = browser.os && mobileOs.includes(browser.os);
      logger.setMessageAggregate('browserName', browser.name);
      logger.setMessageAggregate('browserVersion', browser.version || '');
      logger.setMessageAggregate('browserOs', browser.os || '');
      logger.setMessageAggregate('browserIsMobile', isMobile ?? 'unknown');
    }
    loggerGlobal.setOptions({
      host: logHostUrl,
      interval: 5000,
      level: 'debug',
    });
  }, [configLogs, emblemState, isReady, logHostUrl, projectKey, setEmblemState, tagRequestId]);

  useEffect(() => {
    localStorage.setItem('debug', '*');
  }, []);
}

export default logger;
