import { useState, useEffect } from 'react';
import stopStream from 'utils/stopStream';

interface UserMediaConstraints {
  audio: boolean;
  video: {
    facingMode?: {
      ideal: string;
    };
    deviceId?: {
      exact: string;
    };
    width?: {
      ideal: number;
    };
  };
}

const useVideoStream = (videoDevice: InputDeviceInfo | null): [Error | null, MediaStream | null] => {
  const [state, setState] = useState<[Error | null, MediaStream | null]>([null, null]);
  const [, stream] = state;

  useEffect(() => {
    if (!videoDevice) {
      return undefined;
    }

    let exited: boolean | undefined;

    const getUserMedia = (useBasicConstraints: boolean) => {
      const constraints: UserMediaConstraints = {
        audio: false,
        video: {},
      };

      if (videoDevice.getCapabilities && !useBasicConstraints) {
        const capabilities = videoDevice.getCapabilities();

        if (capabilities?.width?.max) {
          constraints.video.width = {
            ideal: capabilities.width.max,
          };
        }

        constraints.video.deviceId = {
          exact: videoDevice.deviceId,
        };
      } else {
        constraints.video.facingMode = {
          ideal: 'environment',
        };
      }

      navigator.mediaDevices.getUserMedia(constraints)
        .then((mediaStream: MediaStream) => {
          if (exited) {
            /**
             * The hook exited early, clean up the stream.
             */
            stopStream(mediaStream);
            return;
          }

          setState([null, mediaStream]);
        })
        .catch((err: Error) => {
          if (exited) {
            return;
          }

          /**
           * Older phones sometimes don't work well with specific constraints.
           * If the stream fails to start a NotReadableError is captured and
           * I attempt to getUserMedia again, but with very basic constraints.
           */
          if (err.name === 'NotReadableError' && !useBasicConstraints) {
            getUserMedia(true);
            return;
          }

          setState([err, null]);
        });
    };

    getUserMedia(false);

    return () => {
      exited = true;
    };
  }, [videoDevice]);

  useEffect(() => {
    if (!stream) {
      return undefined;
    }

    /**
     * Stop any tracks associated with requested MediaStream
     */
    return () => {
      stopStream(stream);
    };
  }, [stream]);

  return state;
};

export default useVideoStream;
