import { FaceAngle } from '../services/human/types';

function createFaceAngles(numAngles: number) {
  const degreesPerAngle = 360 / numAngles;
  const faceAngles: FaceAngle[] = [];
  for (let i = 0; i < numAngles; i++) {
    const degrees = i * degreesPerAngle;
    const start = degrees;
    faceAngles.push({
      angle: degrees,
      completed: false,
      vector: [start, degreesPerAngle],
    });
  }
  return faceAngles;
}

export default createFaceAngles;
