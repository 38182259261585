import { FaceResult } from '@vladmandic/human';
import { FaceValidationConfig } from 'stores/configStore';

export interface FaceValidationResult {
  faceScore: number,
  live: number,
  real: number,
  faceScoreValid: boolean,
  liveScoreValid: boolean,
  realScoreValid: boolean,
  isValidFace: boolean,
}

function validateFace(face:FaceResult | null, config:FaceValidationConfig) {
  if (!face) {
    return {
      faceScore: 0,
      live: 0,
      real: 0,
      faceScoreValid: false,
      liveScoreValid: false,
      realScoreValid: false,
      hasEmbedding: false,
      isValidFace: false,
    };
  }

  const {
    faceScore: faceScoreThreshold,
    liveScore: liveScoreThreshold,
    realScore: realScoreThreshold,
  } = config;
  const faceScore = face?.faceScore || 0;
  const live = face?.live || 0;
  const real = face?.real || 0;

  const hasEmbedding = !!face.embedding;

  // We have never seen a real face below 1.0
  const faceScoreValid = faceScore >= faceScoreThreshold;

  // Live score has been turned down to 0.8 because it is inconsistent
  const liveScoreValid = live >= liveScoreThreshold;

  // Check against computer generated faces
  const realScoreValid = real >= realScoreThreshold;

  // If face score is 1 and it has an age and embedding, it is valid
  // const isValidFace = hasAge && hasEmbedding && faceScoreValid;
  const isValidFace = hasEmbedding && faceScoreValid;

  const results = {
    faceScore,
    live,
    real,
    faceScoreValid,
    liveScoreValid,
    realScoreValid,
    hasEmbedding,
    isValidFace,
  };

  return results;
}

export default validateFace;
