import React from 'react';
import { CheckCircleIcon } from '@heroicons/react/24/solid';
import Text from 'components/Text';
import { useTranslation } from 'react-i18next';

function Success() {
  const { t } = useTranslation();
  return (
    <div className="flex justify-center items-center flex-col pt-10">
      <CheckCircleIcon width={100} className="text-green-600" />
      <div className="mt-6 text-lg">{t('appeal.success.title')}</div>
      <Text className="text-center mt-2">
        {t('appeal.success.description')}
      </Text>
    </div>
  );
}

export default Success;
