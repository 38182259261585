import React from 'react';
import { useTranslation } from 'react-i18next';
import Text from './Text';

interface DetermineMessageProps {
  step: number;
  left: boolean;
  right: boolean;
  up: boolean;
  down: boolean;
  faceDetected: boolean;
  centered: boolean;
}

function determineMessage({
  step,
  left,
  right,
  up,
  down,
  faceDetected,
  centered,
}: DetermineMessageProps) {
  let rotationMessage = '';
  if (!left) {
    rotationMessage = 'Turn your head to the left';
  } else if (!right) {
    rotationMessage = 'Turn your head to the right';
  } else if (!up) {
    rotationMessage = 'Tilt your head up';
  } else if (!down) {
    rotationMessage = 'Tilt your head down';
  }

  if (!faceDetected || !centered) {
    return {
      message: 'Please move your face into the frame',
    };
  }

  if (step === 3) {
    return {
      message: rotationMessage,
    };
  }

  return {
    message: 'Look straight into the camera',
  };
}

interface Props {
  step: number;
  left: boolean;
  right: boolean;
  up: boolean;
  down: boolean;
  faceDetected: boolean;
  centered: boolean;
}

function DetectionMessage({
  step,
  left,
  right,
  up,
  down,
  faceDetected,
  centered,
}: Props) {
  const { t } = useTranslation();
  const { message } = determineMessage({
    step,
    left,
    right,
    up,
    down,
    faceDetected,
    centered,
  });

  return (
    <div className="font-bold p-4 text-center text-white">
      <Text size="lg">{t(`detectionMessage.${message}`)}</Text>
    </div>
  );
}

export default DetectionMessage;
