import React from 'react';
import Arc, { degreesToRadians } from './Arc';
import { Vector } from './arc-gen';

interface RightArcProps {
  center: Vector;
  radius: number;
  color?: 'red' | 'green';
}

const ANGLES = [degreesToRadians(340), degreesToRadians(40)] as Vector;

function RightArc({ center, color = 'red', radius }: RightArcProps): JSX.Element {
  return (
    <Arc
      cx={center[0]}
      cy={center[1]}
      radius={radius}
      color={color}
      startAngle={ANGLES[0]}
      length={ANGLES[1]}
      φ={0}
    />
  );
}

export default RightArc;
