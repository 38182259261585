import { PDF417BarcodeProps } from './types';

function PDF417Barcode({
  className,
} : PDF417BarcodeProps) {
  return (
    <svg className={className} viewBox="0.0000 0.0000 63.5000 21.4310" version="1.1" xmlns="http://www.w3.org/2000/svg" shapeRendering="crispEdges">
      <g>
        <rect x="0.0000" y="0.0000" width="4.2333" height="2.3812" />
        <rect x="4.7625" y="0.0000" width="0.5292" height="2.3812" />
        <rect x="5.8208" y="0.0000" width="0.5292" height="2.3812" />
        <rect x="6.8792" y="0.0000" width="0.5292" height="2.3812" />
        <rect x="8.9958" y="0.0000" width="2.6458" height="2.3812" />
        <rect x="12.1708" y="0.0000" width="0.5292" height="2.3812" />
        <rect x="13.2292" y="0.0000" width="0.5292" height="2.3812" />
        <rect x="14.2875" y="0.0000" width="2.6458" height="2.3812" />
        <rect x="17.9917" y="0.0000" width="1.0583" height="2.3812" />
        <rect x="19.5792" y="0.0000" width="0.5292" height="2.3812" />
        <rect x="20.6375" y="0.0000" width="1.0583" height="2.3812" />
        <rect x="23.2833" y="0.0000" width="1.5875" height="2.3812" />
        <rect x="26.9875" y="0.0000" width="2.6458" height="2.3812" />
        <rect x="30.6917" y="0.0000" width="1.0583" height="2.3812" />
        <rect x="32.2792" y="0.0000" width="0.5292" height="2.3812" />
        <rect x="33.3375" y="0.0000" width="2.1167" height="2.3812" />
        <rect x="35.9833" y="0.0000" width="0.5292" height="2.3812" />
        <rect x="38.6292" y="0.0000" width="0.5292" height="2.3812" />
        <rect x="39.6875" y="0.0000" width="1.0583" height="2.3812" />
        <rect x="41.2750" y="0.0000" width="1.0583" height="2.3812" />
        <rect x="44.9792" y="0.0000" width="2.6458" height="2.3812" />
        <rect x="48.1542" y="0.0000" width="0.5292" height="2.3812" />
        <rect x="49.2125" y="0.0000" width="0.5292" height="2.3812" />
        <rect x="50.2708" y="0.0000" width="2.6458" height="2.3812" />
        <rect x="53.9750" y="0.0000" width="3.7042" height="2.3812" />
        <rect x="58.2083" y="0.0000" width="0.5292" height="2.3812" />
        <rect x="60.3250" y="0.0000" width="0.5292" height="2.3812" />
        <rect x="61.3833" y="0.0000" width="0.5292" height="2.3812" />
        <rect x="62.9708" y="0.0000" width="0.5292" height="2.3812" />
      </g>
      <g>
        <rect x="0.0000" y="2.3812" width="4.2333" height="2.3812" />
        <rect x="4.7625" y="2.3812" width="0.5292" height="2.3812" />
        <rect x="5.8208" y="2.3812" width="0.5292" height="2.3812" />
        <rect x="6.8792" y="2.3812" width="0.5292" height="2.3812" />
        <rect x="8.9958" y="2.3812" width="2.1167" height="2.3812" />
        <rect x="11.6417" y="2.3812" width="0.5292" height="2.3812" />
        <rect x="12.7000" y="2.3812" width="0.5292" height="2.3812" />
        <rect x="15.3458" y="2.3812" width="0.5292" height="2.3812" />
        <rect x="17.9917" y="2.3812" width="1.5875" height="2.3812" />
        <rect x="20.6375" y="2.3812" width="0.5292" height="2.3812" />
        <rect x="21.6958" y="2.3812" width="1.0583" height="2.3812" />
        <rect x="25.4000" y="2.3812" width="0.5292" height="2.3812" />
        <rect x="26.9875" y="2.3812" width="1.0583" height="2.3812" />
        <rect x="28.5750" y="2.3812" width="1.0583" height="2.3812" />
        <rect x="31.7500" y="2.3812" width="2.1167" height="2.3812" />
        <rect x="34.9250" y="2.3812" width="0.5292" height="2.3812" />
        <rect x="35.9833" y="2.3812" width="1.0583" height="2.3812" />
        <rect x="40.2167" y="2.3812" width="0.5292" height="2.3812" />
        <rect x="41.2750" y="2.3812" width="1.5875" height="2.3812" />
        <rect x="43.3917" y="2.3812" width="0.5292" height="2.3812" />
        <rect x="44.9792" y="2.3812" width="2.1167" height="2.3812" />
        <rect x="47.6250" y="2.3812" width="0.5292" height="2.3812" />
        <rect x="48.6833" y="2.3812" width="0.5292" height="2.3812" />
        <rect x="50.2708" y="2.3812" width="0.5292" height="2.3812" />
        <rect x="53.9750" y="2.3812" width="3.7042" height="2.3812" />
        <rect x="58.2083" y="2.3812" width="0.5292" height="2.3812" />
        <rect x="60.3250" y="2.3812" width="0.5292" height="2.3812" />
        <rect x="61.3833" y="2.3812" width="0.5292" height="2.3812" />
        <rect x="62.9708" y="2.3812" width="0.5292" height="2.3812" />
      </g>
      <g>
        <rect x="0.0000" y="4.7624" width="4.2333" height="2.3812" />
        <rect x="4.7625" y="4.7624" width="0.5292" height="2.3812" />
        <rect x="5.8208" y="4.7624" width="0.5292" height="2.3812" />
        <rect x="6.8792" y="4.7624" width="0.5292" height="2.3812" />
        <rect x="8.9958" y="4.7624" width="0.5292" height="2.3812" />
        <rect x="10.0542" y="4.7624" width="0.5292" height="2.3812" />
        <rect x="11.1125" y="4.7624" width="0.5292" height="2.3812" />
        <rect x="12.7000" y="4.7624" width="2.1167" height="2.3812" />
        <rect x="17.9917" y="4.7624" width="0.5292" height="2.3812" />
        <rect x="19.5792" y="4.7624" width="2.6458" height="2.3812" />
        <rect x="22.7542" y="4.7624" width="1.0583" height="2.3812" />
        <rect x="24.3417" y="4.7624" width="0.5292" height="2.3812" />
        <rect x="26.9875" y="4.7624" width="3.1750" height="2.3812" />
        <rect x="30.6917" y="4.7624" width="0.5292" height="2.3812" />
        <rect x="31.7500" y="4.7624" width="1.0583" height="2.3812" />
        <rect x="33.3375" y="4.7624" width="0.5292" height="2.3812" />
        <rect x="35.9833" y="4.7624" width="1.0583" height="2.3812" />
        <rect x="37.5708" y="4.7624" width="0.5292" height="2.3812" />
        <rect x="38.6292" y="4.7624" width="1.0583" height="2.3812" />
        <rect x="40.2167" y="4.7624" width="3.1750" height="2.3812" />
        <rect x="44.9792" y="4.7624" width="0.5292" height="2.3812" />
        <rect x="46.0375" y="4.7624" width="0.5292" height="2.3812" />
        <rect x="47.0958" y="4.7624" width="0.5292" height="2.3812" />
        <rect x="49.7417" y="4.7624" width="2.1167" height="2.3812" />
        <rect x="53.9750" y="4.7624" width="3.7042" height="2.3812" />
        <rect x="58.2083" y="4.7624" width="0.5292" height="2.3812" />
        <rect x="60.3250" y="4.7624" width="0.5292" height="2.3812" />
        <rect x="61.3833" y="4.7624" width="0.5292" height="2.3812" />
        <rect x="62.9708" y="4.7624" width="0.5292" height="2.3812" />
      </g>
      <g>
        <rect x="0.0000" y="7.1437" width="4.2333" height="2.3812" />
        <rect x="4.7625" y="7.1437" width="0.5292" height="2.3812" />
        <rect x="5.8208" y="7.1437" width="0.5292" height="2.3812" />
        <rect x="6.8792" y="7.1437" width="0.5292" height="2.3812" />
        <rect x="8.9958" y="7.1437" width="1.0583" height="2.3812" />
        <rect x="10.5833" y="7.1437" width="0.5292" height="2.3812" />
        <rect x="11.6417" y="7.1437" width="2.1167" height="2.3812" />
        <rect x="14.8167" y="7.1437" width="2.6458" height="2.3812" />
        <rect x="17.9917" y="7.1437" width="0.5292" height="2.3812" />
        <rect x="19.5792" y="7.1437" width="1.5875" height="2.3812" />
        <rect x="22.7542" y="7.1437" width="1.5875" height="2.3812" />
        <rect x="25.4000" y="7.1437" width="0.5292" height="2.3812" />
        <rect x="26.9875" y="7.1437" width="1.0583" height="2.3812" />
        <rect x="29.6333" y="7.1437" width="0.5292" height="2.3812" />
        <rect x="32.8083" y="7.1437" width="0.5292" height="2.3812" />
        <rect x="33.8667" y="7.1437" width="1.0583" height="2.3812" />
        <rect x="35.9833" y="7.1437" width="1.5875" height="2.3812" />
        <rect x="38.1000" y="7.1437" width="0.5292" height="2.3812" />
        <rect x="39.1583" y="7.1437" width="1.5875" height="2.3812" />
        <rect x="41.8042" y="7.1437" width="2.6458" height="2.3812" />
        <rect x="44.9792" y="7.1437" width="1.0583" height="2.3812" />
        <rect x="46.5667" y="7.1437" width="0.5292" height="2.3812" />
        <rect x="47.6250" y="7.1437" width="2.1167" height="2.3812" />
        <rect x="50.8000" y="7.1437" width="2.6458" height="2.3812" />
        <rect x="53.9750" y="7.1437" width="3.7042" height="2.3812" />
        <rect x="58.2083" y="7.1437" width="0.5292" height="2.3812" />
        <rect x="60.3250" y="7.1437" width="0.5292" height="2.3812" />
        <rect x="61.3833" y="7.1437" width="0.5292" height="2.3812" />
        <rect x="62.9708" y="7.1437" width="0.5292" height="2.3812" />
      </g>
      <g>
        <rect x="0.0000" y="9.5249" width="4.2333" height="2.3812" />
        <rect x="4.7625" y="9.5249" width="0.5292" height="2.3812" />
        <rect x="5.8208" y="9.5249" width="0.5292" height="2.3812" />
        <rect x="6.8792" y="9.5249" width="0.5292" height="2.3812" />
        <rect x="8.9958" y="9.5249" width="1.0583" height="2.3812" />
        <rect x="10.5833" y="9.5249" width="0.5292" height="2.3812" />
        <rect x="11.6417" y="9.5249" width="1.5875" height="2.3812" />
        <rect x="15.8750" y="9.5249" width="0.5292" height="2.3812" />
        <rect x="17.9917" y="9.5249" width="2.1167" height="2.3812" />
        <rect x="20.6375" y="9.5249" width="0.5292" height="2.3812" />
        <rect x="22.2250" y="9.5249" width="0.5292" height="2.3812" />
        <rect x="24.8708" y="9.5249" width="0.5292" height="2.3812" />
        <rect x="26.9875" y="9.5249" width="2.1167" height="2.3812" />
        <rect x="30.1625" y="9.5249" width="0.5292" height="2.3812" />
        <rect x="33.3375" y="9.5249" width="0.5292" height="2.3812" />
        <rect x="34.3958" y="9.5249" width="0.5292" height="2.3812" />
        <rect x="35.9833" y="9.5249" width="1.0583" height="2.3812" />
        <rect x="38.6292" y="9.5249" width="1.0583" height="2.3812" />
        <rect x="41.2750" y="9.5249" width="2.1167" height="2.3812" />
        <rect x="43.9208" y="9.5249" width="0.5292" height="2.3812" />
        <rect x="44.9792" y="9.5249" width="2.1167" height="2.3812" />
        <rect x="47.6250" y="9.5249" width="0.5292" height="2.3812" />
        <rect x="48.6833" y="9.5249" width="1.5875" height="2.3812" />
        <rect x="51.3292" y="9.5249" width="1.5875" height="2.3812" />
        <rect x="53.9750" y="9.5249" width="3.7042" height="2.3812" />
        <rect x="58.2083" y="9.5249" width="0.5292" height="2.3812" />
        <rect x="60.3250" y="9.5249" width="0.5292" height="2.3812" />
        <rect x="61.3833" y="9.5249" width="0.5292" height="2.3812" />
        <rect x="62.9708" y="9.5249" width="0.5292" height="2.3812" />
      </g>
      <g>
        <rect x="0.0000" y="11.9061" width="4.2333" height="2.3812" />
        <rect x="4.7625" y="11.9061" width="0.5292" height="2.3812" />
        <rect x="5.8208" y="11.9061" width="0.5292" height="2.3812" />
        <rect x="6.8792" y="11.9061" width="0.5292" height="2.3812" />
        <rect x="8.9958" y="11.9061" width="2.6458" height="2.3812" />
        <rect x="12.1708" y="11.9061" width="0.5292" height="2.3812" />
        <rect x="13.2292" y="11.9061" width="2.1167" height="2.3812" />
        <rect x="15.8750" y="11.9061" width="1.0583" height="2.3812" />
        <rect x="17.9917" y="11.9061" width="0.5292" height="2.3812" />
        <rect x="21.1667" y="11.9061" width="0.5292" height="2.3812" />
        <rect x="22.2250" y="11.9061" width="0.5292" height="2.3812" />
        <rect x="24.3417" y="11.9061" width="2.1167" height="2.3812" />
        <rect x="26.9875" y="11.9061" width="1.0583" height="2.3812" />
        <rect x="30.1625" y="11.9061" width="2.1167" height="2.3812" />
        <rect x="33.3375" y="11.9061" width="0.5292" height="2.3812" />
        <rect x="34.3958" y="11.9061" width="1.0583" height="2.3812" />
        <rect x="35.9833" y="11.9061" width="1.0583" height="2.3812" />
        <rect x="38.6292" y="11.9061" width="2.6458" height="2.3812" />
        <rect x="42.3333" y="11.9061" width="0.5292" height="2.3812" />
        <rect x="43.9208" y="11.9061" width="0.5292" height="2.3812" />
        <rect x="44.9792" y="11.9061" width="2.1167" height="2.3812" />
        <rect x="47.6250" y="11.9061" width="0.5292" height="2.3812" />
        <rect x="48.6833" y="11.9061" width="2.6458" height="2.3812" />
        <rect x="51.8583" y="11.9061" width="1.0583" height="2.3812" />
        <rect x="53.9750" y="11.9061" width="3.7042" height="2.3812" />
        <rect x="58.2083" y="11.9061" width="0.5292" height="2.3812" />
        <rect x="60.3250" y="11.9061" width="0.5292" height="2.3812" />
        <rect x="61.3833" y="11.9061" width="0.5292" height="2.3812" />
        <rect x="62.9708" y="11.9061" width="0.5292" height="2.3812" />
      </g>
      <g>
        <rect x="0.0000" y="14.2873" width="4.2333" height="2.3812" />
        <rect x="4.7625" y="14.2873" width="0.5292" height="2.3812" />
        <rect x="5.8208" y="14.2873" width="0.5292" height="2.3812" />
        <rect x="6.8792" y="14.2873" width="0.5292" height="2.3812" />
        <rect x="8.9958" y="14.2873" width="1.5875" height="2.3812" />
        <rect x="11.1125" y="14.2873" width="0.5292" height="2.3812" />
        <rect x="12.7000" y="14.2873" width="1.5875" height="2.3812" />
        <rect x="14.8167" y="14.2873" width="2.6458" height="2.3812" />
        <rect x="17.9917" y="14.2873" width="0.5292" height="2.3812" />
        <rect x="20.6375" y="14.2873" width="1.0583" height="2.3812" />
        <rect x="23.2833" y="14.2873" width="1.0583" height="2.3812" />
        <rect x="25.4000" y="14.2873" width="0.5292" height="2.3812" />
        <rect x="26.9875" y="14.2873" width="2.1167" height="2.3812" />
        <rect x="29.6333" y="14.2873" width="1.0583" height="2.3812" />
        <rect x="31.2208" y="14.2873" width="0.5292" height="2.3812" />
        <rect x="33.3375" y="14.2873" width="1.5875" height="2.3812" />
        <rect x="35.9833" y="14.2873" width="2.1167" height="2.3812" />
        <rect x="39.1583" y="14.2873" width="0.5292" height="2.3812" />
        <rect x="40.2167" y="14.2873" width="1.0583" height="2.3812" />
        <rect x="41.8042" y="14.2873" width="2.6458" height="2.3812" />
        <rect x="44.9792" y="14.2873" width="1.5875" height="2.3812" />
        <rect x="47.0958" y="14.2873" width="0.5292" height="2.3812" />
        <rect x="48.6833" y="14.2873" width="1.5875" height="2.3812" />
        <rect x="50.8000" y="14.2873" width="2.6458" height="2.3812" />
        <rect x="53.9750" y="14.2873" width="3.7042" height="2.3812" />
        <rect x="58.2083" y="14.2873" width="0.5292" height="2.3812" />
        <rect x="60.3250" y="14.2873" width="0.5292" height="2.3812" />
        <rect x="61.3833" y="14.2873" width="0.5292" height="2.3812" />
        <rect x="62.9708" y="14.2873" width="0.5292" height="2.3812" />
      </g>
      <g>
        <rect x="0.0000" y="16.6686" width="4.2333" height="2.3812" />
        <rect x="4.7625" y="16.6686" width="0.5292" height="2.3812" />
        <rect x="5.8208" y="16.6686" width="0.5292" height="2.3812" />
        <rect x="6.8792" y="16.6686" width="0.5292" height="2.3812" />
        <rect x="8.9958" y="16.6686" width="3.1750" height="2.3812" />
        <rect x="12.7000" y="16.6686" width="0.5292" height="2.3812" />
        <rect x="14.2875" y="16.6686" width="0.5292" height="2.3812" />
        <rect x="15.3458" y="16.6686" width="1.5875" height="2.3812" />
        <rect x="17.9917" y="16.6686" width="2.1167" height="2.3812" />
        <rect x="20.6375" y="16.6686" width="2.1167" height="2.3812" />
        <rect x="23.2833" y="16.6686" width="2.1167" height="2.3812" />
        <rect x="25.9292" y="16.6686" width="0.5292" height="2.3812" />
        <rect x="26.9875" y="16.6686" width="1.5875" height="2.3812" />
        <rect x="29.6333" y="16.6686" width="0.5292" height="2.3812" />
        <rect x="30.6917" y="16.6686" width="1.0583" height="2.3812" />
        <rect x="33.3375" y="16.6686" width="0.5292" height="2.3812" />
        <rect x="35.9833" y="16.6686" width="1.0583" height="2.3812" />
        <rect x="38.1000" y="16.6686" width="0.5292" height="2.3812" />
        <rect x="39.6875" y="16.6686" width="1.5875" height="2.3812" />
        <rect x="41.8042" y="16.6686" width="0.5292" height="2.3812" />
        <rect x="44.9792" y="16.6686" width="0.5292" height="2.3812" />
        <rect x="46.0375" y="16.6686" width="0.5292" height="2.3812" />
        <rect x="47.0958" y="16.6686" width="3.1750" height="2.3812" />
        <rect x="50.8000" y="16.6686" width="1.5875" height="2.3812" />
        <rect x="53.9750" y="16.6686" width="3.7042" height="2.3812" />
        <rect x="58.2083" y="16.6686" width="0.5292" height="2.3812" />
        <rect x="60.3250" y="16.6686" width="0.5292" height="2.3812" />
        <rect x="61.3833" y="16.6686" width="0.5292" height="2.3812" />
        <rect x="62.9708" y="16.6686" width="0.5292" height="2.3812" />
      </g>
      <g>
        <rect x="0.0000" y="19.0498" width="4.2333" height="2.3812" />
        <rect x="4.7625" y="19.0498" width="0.5292" height="2.3812" />
        <rect x="5.8208" y="19.0498" width="0.5292" height="2.3812" />
        <rect x="6.8792" y="19.0498" width="0.5292" height="2.3812" />
        <rect x="8.9958" y="19.0498" width="3.1750" height="2.3812" />
        <rect x="12.7000" y="19.0498" width="0.5292" height="2.3812" />
        <rect x="14.2875" y="19.0498" width="1.0583" height="2.3812" />
        <rect x="16.4042" y="19.0498" width="0.5292" height="2.3812" />
        <rect x="17.9917" y="19.0498" width="1.0583" height="2.3812" />
        <rect x="20.1083" y="19.0498" width="1.5875" height="2.3812" />
        <rect x="23.2833" y="19.0498" width="0.5292" height="2.3812" />
        <rect x="24.8708" y="19.0498" width="1.5875" height="2.3812" />
        <rect x="26.9875" y="19.0498" width="0.5292" height="2.3812" />
        <rect x="28.5750" y="19.0498" width="0.5292" height="2.3812" />
        <rect x="29.6333" y="19.0498" width="1.5875" height="2.3812" />
        <rect x="32.2792" y="19.0498" width="3.1750" height="2.3812" />
        <rect x="35.9833" y="19.0498" width="1.0583" height="2.3812" />
        <rect x="38.6292" y="19.0498" width="0.5292" height="2.3812" />
        <rect x="40.7458" y="19.0498" width="0.5292" height="2.3812" />
        <rect x="41.8042" y="19.0498" width="2.6458" height="2.3812" />
        <rect x="44.9792" y="19.0498" width="2.6458" height="2.3812" />
        <rect x="48.1542" y="19.0498" width="0.5292" height="2.3812" />
        <rect x="49.7417" y="19.0498" width="1.5875" height="2.3812" />
        <rect x="51.8583" y="19.0498" width="0.5292" height="2.3812" />
        <rect x="53.9750" y="19.0498" width="3.7042" height="2.3812" />
        <rect x="58.2083" y="19.0498" width="0.5292" height="2.3812" />
        <rect x="60.3250" y="19.0498" width="0.5292" height="2.3812" />
        <rect x="61.3833" y="19.0498" width="0.5292" height="2.3812" />
        <rect x="62.9708" y="19.0498" width="0.5292" height="2.3812" />
      </g>
    </svg>
  );
}

export default PDF417Barcode;
