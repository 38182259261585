function straightFaceMessage(pitch: number, yaw: number) {
  // Negative pitch means face is tilted up
  const tooHigh = pitch < -0.4;

  // Positive pitch means face is tilted down
  const tooLow = pitch > 0.4;

  // Negative yaw means face is turned left
  const tooLeft = yaw < -0.4;

  // Positive yaw means face is turned right
  const tooRight = yaw > 0.4;

  let message = '';
  if (tooHigh) {
    message = 'Tilt your face down';
  } else if (tooLow) {
    message = 'Tilt your face up';
  } else if (tooRight) {
    message = 'Turn your face left';
  } else if (tooLeft) {
    message = 'Turn your face right';
  }

  return {
    message,
    isStraight: !message,
  };
}

export default straightFaceMessage;
