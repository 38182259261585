import React, {
  ChangeEvent, useCallback, useEffect,
} from 'react';
import { CameraIcon, PhotoIcon } from '@heroicons/react/24/outline';
import Button from 'components/Button';
import UploadPhoto from 'components/UploadPhoto';
import useFileReader from 'hooks/useFileReader';
import useAgeDetectionStore from 'stores/ageDetectionStore';
import FullscreenLoader from 'components/FullscreenLoader';
import Text from 'components/Text';
import { useTranslation } from 'react-i18next';
import TakePhoto from 'components/TakePhoto';
import { StepProps } from './types';

function ConfirmId({ formData, setStep, setFormData }: StepProps) {
  const imageId = useAgeDetectionStore((s) => s.imageId);
  const { t } = useTranslation();

  const {
    file,
    setFile,
    setFileUrl,
    fileUrl,
    imgLoading,
  } = useFileReader();

  useEffect(() => {
    if (!imageId) return;
    setFile(imageId);
  }, [imageId, setFile]);

  useEffect(() => {
    if (!fileUrl || !file) return;
    setFormData({ idImageUrl: fileUrl, idImage: file });
  }, [fileUrl, file, setFormData]);

  const handleUpload = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    const { files } = event.target;
    if (!files || files.length === 0) {
      console.error(t('error.noFileSelected'));
      return;
    }
    setFile(files[0]);
  }, [setFile, t]);

  return (
    <div>
      <h1 className="text-left text-2xl tracking-tight font-bold text-white">{t('appeal.confirm.id.title')}</h1>
      <Text className="text-left mt-2 mb-6">
        {t('appeal.confirm.id.description')}
      </Text>
      {formData.idImageUrl ? (
        <img
          src={formData.idImageUrl}
          alt="uploaded"
          className="w-full object-cover h-full rounded-md"
        />
      ) : (
        <div className="flex justify-center">
          <TakePhoto fileUrl={fileUrl} setFile={setFile} />
        </div>
      )}
      <div className="flex flex-wrap justify-center items-center">
        {formData.idImageUrl && (
          <div className="flex flex-wrap justify-center items-center">
            <Button
              className="my-4"
              type="button"
              onClick={() => {
                setStep('ManualSubmission');
              }}
              disabled={!formData.idImageUrl}
            >
              {t('appeal.confirm.photo.confirmAndProceed')}
            </Button>
            <Button
              className="flex justify-center items-center gap-2"
              type="button"
              variant="secondary"
              onClick={() => {
                setFormData({ idImageUrl: '' });
                setFileUrl('');
              }}
            >
              <CameraIcon width={25} />
              {' '}
              {t('appeal.confirm.photo.retakeNewPhoto')}
            </Button>
          </div>
        )}
        <UploadPhoto handleChange={handleUpload}>
          {formData.idImageUrl ? (
            <div className="flex justify-center items-center gap-2 text-center">
              <PhotoIcon width={25} />
              {' '}
              {t('appeal.confirm.id.uploadNewIdPhoto')}
            </div>
          ) : t('appeal.confirm.id.uploadPhoto')}
        </UploadPhoto>
      </div>
      {imgLoading && <FullscreenLoader />}
    </div>
  );
}

export default ConfirmId;
