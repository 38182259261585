import { Config } from '@vladmandic/human';

const humanConfig: Partial<Config> = {
  gesture: {
    enabled: false,
  },
  hand: {
    enabled: false,
  },
  body: {
    enabled: false,
  },
  segmentation: {
    enabled: false,
  },
  object: {
    enabled: false,
  },
  face: {
    antispoof: {
      enabled: true,
    },
    liveness: {
      enabled: true,
    },
    iris: {
      enabled: false,
    },
    emotion: {
      enabled: false,
    },
    mesh: {
      enabled: true,
    },
    detector: {
      rotation: true,
      return: false,
      skipFrames: 50,
    },
    description: {
      enabled: true,
    },
  },
};

export default humanConfig;
