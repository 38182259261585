import clsx from 'clsx';
import Loader from 'components/Loader';
import React, {
  memo, useRef, useEffect, useMemo,
} from 'react';
import { RecognizeResult, Line } from 'tesseract.js';
import { VerifyIdInfoErrorCodes, loggerMessages } from 'types/logger';
import logger from 'services/logger';
import { guidelineConfig, renderConfig } from './config';
import useBestVideoDevice from './hooks/useBestVideoDevice';
import useVideoStream from './hooks/useVideoStream';
import useTesseract from './hooks/useTesseract';
import useZXing from './hooks/useZXing';
import DocumentScannerGuidelines from './DocumentScannerGuidelines';
import parseDriverLicenseDateFields from './PDF417';
import { processMRZLines, shouldLogMRZLines } from './MRZ';
import {
  DocumentScannerProps,
  DocumentScanResult,
  Rect,
  MRZStats,
} from './types';
import {
  drawRect,
  drawVideo,
  captureFrame,
  createFPSCounter,
} from './utils';

function DocumentScanner({
  renderScale = Math.max(window.devicePixelRatio || 1, 2),
  guidelines = 'none',
  debug = false,
  className,
  onSuccess,
  onFrame,
  onError,
  timeout,
  detect,
}: DocumentScannerProps) {
  const detectMRZ = detect === 'mrz' || detect === 'all';
  const detectPDF417 = detect === 'pdf417' || detect === 'all';

  const canvasRef = useRef<HTMLCanvasElement>(null);
  const videoRef = useRef<HTMLVideoElement>(null);

  const cbErrorRef = useRef<((error: Error) => void) | undefined>(undefined);
  const cbFrameRef = useRef<((imageDataURL: string) => void) | undefined>(undefined);
  const cbSuccessRef = useRef<((data: DocumentScanResult) => void) | undefined>(undefined);

  const [deviceError, device] = useBestVideoDevice();
  const [streamError, stream] = useVideoStream(device);
  const [tesseractError, tesseract] = useTesseract({ enabled: detectMRZ });
  const [zxingError, zxing] = useZXing({ enabled: detectPDF417 });
  const scanStart = useMemo(() => Date.now(), []);

  useEffect(() => {
    if (typeof onSuccess === 'function') {
      cbSuccessRef.current = onSuccess;
    } else {
      cbSuccessRef.current = undefined;
    }
  }, [onSuccess]);

  useEffect(() => {
    if (typeof onFrame === 'function') {
      cbFrameRef.current = onFrame;
    } else {
      cbFrameRef.current = undefined;
    }
  }, [onFrame]);

  useEffect(() => {
    if (typeof onError === 'function') {
      cbErrorRef.current = onError;
    } else {
      cbErrorRef.current = undefined;
    }
  }, [onError]);

  useEffect(() => {
    const error = streamError || deviceError || tesseractError || zxingError;

    if (error) {
      if (cbErrorRef.current) {
        cbErrorRef.current(error);
      }

      return undefined;
    }

    if (!stream || (detectMRZ && !tesseract) || (detectPDF417 && !zxing)) {
      return undefined;
    }

    const { current: video } = videoRef;
    const { current: canvas } = canvasRef;

    if (!canvas || !video) {
      return undefined;
    }

    const proxyCanvas = document.createElement('canvas');
    const proxyContext = proxyCanvas.getContext('2d', {
      willReadFrequently: true,
    });

    const context = canvas.getContext('2d', {
      willReadFrequently: true,
    });

    if (!context || !proxyContext) {
      return undefined;
    }

    let exited: boolean | undefined;
    let processing: boolean | undefined;
    let requestedFrameId: number | undefined;
    let timeoutId: ReturnType<typeof setTimeout> | undefined;
    let renderStart = 0;
    let lastFrameCapture = 0;
    let lastTesseractCapture = 0;
    let tesseractLogBuffer: string[] = [];

    /**
     * Create FPS counter for debug
     */
    const fps = createFPSCounter();

    /**
     * Create MRZ stats for additonal logging metrics
     */
    const mrzStats: MRZStats = {
      linesMissing: 0,
      linesUnmatched: 0,
      linesIncomplete: 0,
    };

    /**
     * Reset any previous context changes to the default state.
     * This also appears to have fixed a strange black-box issue some people
     * experienced when rendering shapes. No clue why, but hooray!
     */
    context.reset();

    /**
     * Reset the previous transform of the context as they stack.
     */
    context.setTransform(1, 0, 0, 1, 0, 0);

    /**
     * Upscale the canvas context to the desired
     * factor before starting the draw process. hello
     */
    context.scale(renderScale, renderScale);

    const logMRZMetrics = () => {
      if (detectMRZ) {
        logger.info(loggerMessages.verifyIdInfo.info.mrzMetrics, {
          aggregates: {
            mrzLinesMissing: mrzStats.linesMissing,
            mrzLinesUnmatched: mrzStats.linesUnmatched,
            mrzLinesIncomplete: mrzStats.linesIncomplete,
          },
        });
      }
    };

    const handleTimeout = () => {
      if (requestedFrameId) {
        cancelAnimationFrame(requestedFrameId);
      }

      logMRZMetrics();

      /**
       * If onFrame was provided, capture final frame before calling error with timeout.
       */
      if (cbFrameRef.current) {
        const imageDataURL = captureFrame(canvas, 1024, 0.1);

        if (imageDataURL) {
          cbFrameRef.current(imageDataURL);
        }
      }

      if (cbErrorRef.current) {
        cbErrorRef.current(new Error(`${VerifyIdInfoErrorCodes.ERR_BARCODE_SCAN_TIMEOUT}`));
      }
    };

    const processBarcodeResult = (barcode: Record<string, string>) => {
      /**
       * If there was an error detected or no format found, noop.
       */
      if (!barcode.format || barcode.error) {
        return;
      }

      if (requestedFrameId) {
        cancelAnimationFrame(requestedFrameId);
      }

      if (timeoutId) {
        clearTimeout(timeoutId);
      }

      const result = parseDriverLicenseDateFields(barcode.text);

      /**
       * Render a final frame without any guidelines and grab the image as a data
       * url for the result.
       */
      drawVideo(context, video);

      result.imageDataURL = canvas.toDataURL('image/jpg');

      if (cbSuccessRef.current) {
        logger.info('Barcode scan successful.', {
          aggregates: { scanDuration: (Date.now() - scanStart) / 1000 },
        });
        cbSuccessRef.current(result);
      }
    };

    const processTesseractResult = (result: RecognizeResult) => {
      /**
       * This is function called after an async worker request, so the component
       * could have unmounted. Thus the check for exited before continuing.
       */
      if (exited) {
        return;
      }

      if (!result?.data?.lines?.length) {
        return;
      }

      const now = Date.now();

      const lines = result.data.lines.map((line: Line) => line.text.trim()).filter((line) => line.length >= 30);
      const match = processMRZLines(lines);

      /**
       * If lines were extracted and they match any MRZ structure (i.e 3 lines of 30 characters),
       * push them to the log buffer.
       */
      if (shouldLogMRZLines(lines, mrzStats)) {
        const message = lines.join('\n');

        /**
         * If lines were technically valid, but didn't match with the regexp, update the linesUnmatched stat.
         */
        if (!match) {
          mrzStats.linesUnmatched += 1;
        }

        /**
         * Push to log buffer only if message doesn't exist already.
         */
        if (!tesseractLogBuffer.find((m) => m === message)) {
          tesseractLogBuffer.push(message);
        }
      }

      /**
       * If the log buffer has items and at least 5 seconds have passed, submit the log.
       */
      if (tesseractLogBuffer.length && now - lastTesseractCapture >= 5_000) {
        logger.info(loggerMessages.verifyIdInfo.info.tesseractResult, {
          aggregates: {
            extractedText: tesseractLogBuffer.join('\n\n'),
          },
        });

        logger.flush();

        tesseractLogBuffer = [];
        lastTesseractCapture = now;
      }

      /**
       * If there's no match or a valid birthDate was not extracted then
       * keep on scanning.
       */
      if (!match || !match.birthDate) {
        return;
      }

      if (requestedFrameId) {
        cancelAnimationFrame(requestedFrameId);
      }

      if (timeoutId) {
        clearTimeout(timeoutId);
      }

      logMRZMetrics();

      /**
       * Render a final frame without any guidelines and grab the image as a data
       * url for the result.
       */
      drawVideo(context, video);

      match.imageDataURL = canvas.toDataURL('image/jpg');

      if (cbSuccessRef.current) {
        logger.info('Barcode scan successful.', {
          aggregates: { scanDuration: (Date.now() - scanStart) / 1000 },
        });
        cbSuccessRef.current(match);
      }
    };

    const renderVideoFrame = () => {
      requestedFrameId = requestAnimationFrame(renderVideoFrame);

      const now = Date.now();

      if (renderStart === 0) {
        renderStart = now;
      }

      /**
       * Get the current canvas bounds
       */
      const bounds = canvas.getBoundingClientRect();

      const targetWidth = Math.round(bounds.width * renderScale);
      const targetHeight = Math.round(bounds.height * renderScale);

      /**
       * Assign the upscaled the width and height if different from current canvas
       * values.
       */
      if (canvas.width !== targetWidth) {
        canvas.width = targetWidth;
      }

      if (canvas.height !== targetHeight) {
        canvas.height = targetHeight;
      }

      drawVideo(context, video);

      const guideline: Rect = {
        x: 0,
        y: 0,
        width: 0,
        height: 0,
      };

      const box: Rect = {
        x: 0,
        y: 0,
        width: 0,
        height: 0,
      };

      if (guidelines === 'passport' || guidelines === 'non-us-id') {
        const config = guidelineConfig[guidelines];

        guideline.width = Math.round(targetWidth * 0.9);
        guideline.height = Math.round(guideline.width * config.ratio);
        guideline.x = (targetWidth - guideline.width) * 0.5;
        guideline.y = (targetHeight - guideline.height) * 0.5;

        box.width = guideline.width;
        box.height = Math.round(box.width * config.boxSize);
        box.x = guideline.x;
        box.y = guideline.y + guideline.height - box.height;
      } else {
        box.width = Math.round(targetWidth * 0.9);
        box.height = Math.round(box.width * 0.325);
        box.x = (targetWidth - box.width) * 0.5;
        box.y = (targetHeight - box.height) * 0.5;
      }

      /**
       * If we are detecting PDF417 or detecting MRZ and the renderScale is less than or
       * equal to the "maxRenderScaleMRZ" then perform the cropped region draw
       * now. Otherwise, there is a second draw for MRZ that scales it down.
       *
       * PDF417 likes a high renderScale, MRZ does not (at least from my tests),
       * thus the two draw blocks for the proxyContext.
       */
      if (detectPDF417 || (detectMRZ && renderScale <= renderConfig.maxRenderScaleMRZ)) {
        if (proxyCanvas.width !== box.width) {
          proxyCanvas.width = box.width;
        }

        if (proxyCanvas.height !== box.height) {
          proxyCanvas.height = box.height;
        }

        proxyContext.drawImage(
          canvas,
          box.x,
          box.y,
          box.width,
          box.height,
          0,
          0,
          box.width,
          box.height,
        );
      }

      context.fillStyle = renderConfig.fillStyle;
      context.strokeStyle = renderConfig.strokeStyle;
      context.lineWidth = renderConfig.lineWidth * renderScale;

      /**
       * Draw guidelines (if any)
       */
      if (debug && guideline.width && guideline.height) {
        context.beginPath();

        drawRect(context, guideline.x, guideline.y, guideline.width, guideline.height, renderConfig.borderRadius * renderScale);

        context.stroke();
      }

      /**
       * Render crop region box
       */
      if (debug) {
        context.beginPath();

        drawRect(context, box.x, box.y, box.width, box.height, renderConfig.borderRadius * renderScale);

        context.fill();
        context.stroke();
      }

      /**
       * If onFrame was provided, periodically provide the callback with a fully rendered
       * frame. This is only used for debugging purposes.
       */
      if (cbFrameRef.current && now - lastFrameCapture >= 10_000) {
        const imageDataURL = captureFrame(canvas, 1024, 0.1);

        if (imageDataURL) {
          cbFrameRef.current(imageDataURL);
          lastFrameCapture = now;
        }
      }

      if (debug) {
        const fontSize = 16 * renderScale;

        context.font = `${fontSize}px monospace`;
        context.fillStyle = '#000';
        context.textBaseline = 'top';

        const messages = [
          `FPS:             ${fps()}`,
          `guidelines:      ${guidelines}`,
          `renderScale:     ${renderScale}`,
          `detectMRZ:       ${detectMRZ ? 'yes' : 'no'}`,
          `detectPDF417:    ${detectPDF417 ? 'yes' : 'no'}`,
          `videoResolution: ${video.videoWidth}x${video.videoHeight}`,
        ];

        for (let i = 0, l = messages.length; i < l; i++) {
          context.fillText(messages[i], fontSize, fontSize + (fontSize * i));
        }
      }

      if (detectPDF417 && zxing) {
        try {
          /**
           * Extract imageData
           */
          const imageData = proxyContext.getImageData(0, 0, proxyCanvas.width, proxyCanvas.height);
          const { data: sourceBuffer } = imageData;

          /**
           * Allocate and assign memory buffer
           */
          const buffer = zxing._malloc(sourceBuffer.byteLength);

          zxing.HEAPU8.set(sourceBuffer, buffer);

          const barcode = zxing.readBarcodeFromPixmap(buffer, proxyCanvas.width, proxyCanvas.height, true, 'PDF417');

          /**
           * Release the buffer memory.
           */
          zxing._free(buffer);

          processBarcodeResult(barcode);
        } catch (err) {
          /**
           * Try/catch was added for an InvalidStateError thrown on some older iPhones for the
           * first few renders. Unsure why?
           */
        }
      }

      if (!(detectMRZ && tesseract)) {
        return;
      }

      if (processing) {
        return;
      }

      /**
       * If the renderScale provided exceeds the maximum defined MRZ renderScale,
       * then downscale the cropped region. I've noticed anything above 2
       * seems to struggle.
       */
      if (renderScale > renderConfig.maxRenderScaleMRZ) {
        const downscaleFactor = renderConfig.maxRenderScaleMRZ / renderScale;

        const renderWidth = box.width * downscaleFactor;
        const renderHeight = box.height * downscaleFactor;

        if (proxyCanvas.width !== renderWidth) {
          proxyCanvas.width = renderWidth;
        }

        if (proxyCanvas.height !== renderHeight) {
          proxyCanvas.height = renderHeight;
        }

        proxyContext.drawImage(
          canvas,
          box.x,
          box.y,
          box.width,
          box.height,
          0,
          0,
          renderWidth,
          renderHeight,
        );
      }

      processing = true;

      tesseract.recognize(proxyCanvas).then(processTesseractResult).catch(() => {
        /**
         * Continously scanning so disregard single erroneous result.
         */
      }).finally(() => {
        if (exited) {
          tesseract.terminate();
          return;
        }

        processing = false;
      });
    };

    const onLoadedMetadata = () => {
      if (exited) {
        return;
      }

      /**
       * Trigger video playback of the camera feed once metadata event is called.
       */
      video?.play();

      /**
       * Start the render loop
       */
      renderVideoFrame();
    };

    video.addEventListener('loadedmetadata', onLoadedMetadata);
    video.srcObject = stream;

    if (timeout && timeout > 0) {
      timeoutId = setTimeout(handleTimeout, timeout);
    }

    return () => {
      exited = true;

      /**
       * Tesseract could still be processing and you can't abort it,
       * so there is logic in the async handler of the call to terminate itself.
       * If it's not processing, terminate it immediately.
       */
      if (!processing && tesseract) {
        tesseract.terminate();
      }

      if (requestedFrameId) {
        cancelAnimationFrame(requestedFrameId);
      }

      if (timeoutId) {
        clearTimeout(timeoutId);
      }

      if (video) {
        video.srcObject = null;
        video.removeEventListener('loadedmetadata', onLoadedMetadata);
      }
    };
  }, [
    debug,
    zxing,
    stream,
    timeout,
    tesseract,
    detectMRZ,
    guidelines,
    zxingError,
    deviceError,
    streamError,
    renderScale,
    detectPDF417,
    tesseractError,
  ]);

  const isLoading = (detectMRZ && !tesseract) || (detectPDF417 && !zxing) || !stream;

  return (
    <div className={clsx('w-full', className)}>
      <div className="relative w-full aspect-[4/3] flex items-center justify-center">
        {isLoading ? <Loader className="absolute" /> : null}
        {guidelines !== 'none' && !isLoading ? <DocumentScannerGuidelines type={guidelines} /> : null}
        <canvas className="w-full h-full bg-gray-700 rounded-md" ref={canvasRef} />
      </div>
      <video
        ref={videoRef}
        className="w-0 h-0 opacity-0 hidden"
        playsInline
        autoPlay
        muted
      />
    </div>
  );
}

export default memo(DocumentScanner);
