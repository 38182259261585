import React, { ReactNode } from 'react';
import { Outlet } from 'react-router-dom';
import Header from 'components/Header';
import Footer from 'components/Footer';
import useAgeDetectionStore from 'stores/ageDetectionStore';
import useConfigStore from 'stores/configStore';
import Text from 'components/Text';

interface MainLayoutProps {
  includeBackButton?: boolean;
  basePath?: string;
  children?: ReactNode;
  backButtonHandler?: () => void;
}

function MainLayout({
  includeBackButton,
  basePath = '',
  children,
  backButtonHandler,
}: MainLayoutProps) {
  const displaySimilarity = useConfigStore((state) => state.debug.displaySimilarity);
  const similarity = useAgeDetectionStore((state) => state.similarity);
  return (
    <div id="main-container" className="h-full flex flex-col justify-between overflow-x-hidden overflow-y-auto">
      <div>
        <Header includeBackButton={includeBackButton} basePath={basePath} backButtonHandler={backButtonHandler} />
        {!!similarity && displaySimilarity && (
          <div className="absolute rounded-lg top-4 left-4 right-4 p-4 bg-white text-center text-black z-10">
            <Text>{`Similarity: ${similarity}`}</Text>
          </div>
        )}
        <main className="flex-1">
          <div className="flex px-8 mx-auto max-w-2xl min-h-full">
            {children || <Outlet />}
          </div>
        </main>
      </div>
      <Footer />
    </div>
  );
}

export default MainLayout;
