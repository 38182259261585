export enum VerifyIdErrorCodes {
  'ERR_LOAD_IMAGE' = 1000,
  'ERR_ENCODE_IMAGE' = 1001,
  'ERR_DETECT_NONE' = 1002,
  'ERR_DETECT_MULTIPLE' = 1003,
  'ERR_DETECT_MUGSHOT' = 1004,
  'ERR_DETECT_DATE' = 1005,
  'ERR_DETECT_DATE_THRESHOLD' = 1006,
  'ERR_DETECT_DATE_FUTURE' = 1008,
  'ERR_DETECT_FACE' = 1103,
  'ERR_PROCESSING_IMG' = 1104,
}

export enum VerifyIdInfoErrorCodes {
  'ERR_SCAN_DOCUMENT' = 1400,
  'FAIL_INVALID_BIRTH_DATE' = 1401,
  'FAIL_INVALID_EXPIRATION_DATE' = 1402,
  'FAIL_BIRTH_DATE_BELOW_THRESHOLD' = 1403,
  'FAIL_MATCHING_FACES' = 1404,
  'ERR_BARCODE_SCAN_TIMEOUT' = 1405,
}

export enum FaceMatchErrorCodes {
  'ERR_FACE_MATCH_ID' = 1107,
  'ERR_MATCHING_FACES' = 1105,
}

export enum AgePredictionErrorCodes {
  'ERR_HUMAN_DETECT' = 1100,
  'ERR_RECURSIVE_AGE_PREDICTION' = 1101,
  'ERR_SET_VIDEO_SOURCE' = 1102,
  'ERR_HUMAN_INIT' = 1114,
  'ERR_FACE_MISMATCH' = 1116,
  'ERR_NO_FACE_TIMEOUT' = 1117,
}

export enum MintErrorCodes {
  'ERR_MINT_ROUTE' = 1106,
}

export enum ResizeImageErrorCodes {
  'ERR_RESIZE_IMAGE' = 1108,
}

export enum UploadImageErrorCodes {
  'ERR_UPLOAD_IMAGE' = 1109,
}

export enum Au10tixErrorCodes {
  'ERR_AU10TIX_PROCESSING' = 1110,
  'ERR_AU10TIX_RESULT' = 1111,
  'ERR_AU10TIX_FAIL_AGE' = 1300,
  'ERR_AU10TIX_FAIL_AUTHENTIC' = 1301,
  'ERR_AU10TIX_FAIL_EXPIRED' = 1302,
  'ERR_AU10TIX_FAIL_MAX_ATTEMPTS' = 1303,
}

export enum ManualReviewErrorCodes {
  'ERR_APPROVE_MANUAL_REVIEW' = 1112,
  'ERR_DENY_MANUAL_REVIEW' = 1113,
}

export enum AppealFormErrorCodes {
  'ERR_APPEAL_FORM_SUBMIT' = 1115,
}

export enum MonocularDepthErrorCodes {
  'ERR_MONOCULAR_DEPTH' = 1120,
}

export const errorMessages: Record<number, string> = {
  // VerifyId errors
  [VerifyIdErrorCodes.ERR_LOAD_IMAGE]: 'Something went wrong. Please try again.',
  [VerifyIdErrorCodes.ERR_ENCODE_IMAGE]: 'Something went wrong. Please try again.',
  [VerifyIdErrorCodes.ERR_DETECT_NONE]: 'Could not find any ID card in the provided photo. Please ensure that the photo contains a clear and valid ID card for identification.',
  [VerifyIdErrorCodes.ERR_DETECT_MULTIPLE]: 'Detected multiple ID cards, please provide only one ID card for identification.',
  [VerifyIdErrorCodes.ERR_DETECT_MUGSHOT]: 'No photo suitable for identification was found within the identification card. Please try again.',
  [VerifyIdErrorCodes.ERR_DETECT_DATE]: 'We could not verify the dates in your photo. Please upload a new photo and make sure they are visible',
  [VerifyIdErrorCodes.ERR_DETECT_DATE_THRESHOLD]: 'No date(s) detected within age threshold.',
  [VerifyIdErrorCodes.ERR_DETECT_FACE]: 'Error resizing ID photo and detecting face',
  [VerifyIdErrorCodes.ERR_DETECT_DATE_FUTURE]: 'Could not find a valid expiration date. Please try again.',

  //  Match face errors
  [FaceMatchErrorCodes.ERR_FACE_MATCH_ID]: 'The photo on the ID does not match your appearance. Please try again.',
  [FaceMatchErrorCodes.ERR_MATCHING_FACES]: 'There was an error matching your face. Please try again.',

  // Au10tix errors
  [Au10tixErrorCodes.ERR_AU10TIX_FAIL_AGE]: 'Au10tix age not 18+.',
  [Au10tixErrorCodes.ERR_AU10TIX_FAIL_AUTHENTIC]: 'Au10tix ID not authentic.',
  [Au10tixErrorCodes.ERR_AU10TIX_FAIL_EXPIRED]: 'Au10tix ID expired.',
  [Au10tixErrorCodes.ERR_AU10TIX_FAIL_MAX_ATTEMPTS]: 'Au10tix max attempts reached.',
};

// These messages are used in for graphs. Please DO NOT change any messages.
// If you need to create a new message, please make sure it is unique.
export const loggerMessages = {
  agePrediction: {
    info: {
      step: 'Age prediction step completed.',
      step2Variant: 'Age prediction step 2 variant.',
      realAndLiveCheck: 'Age prediction live and real check.',
      timedOut: 'Age prediction timed out.',
      startFaceDirectionTest: 'Age prediction starting face direction test',
      predicted: 'Age predicted.',
      complete: 'Age prediction completed.',
      stalledProgress: 'Age prediction stalled.',
      noFaceDetected: 'Age prediction no face detected.',
      youngestFace: 'Age prediction youngest face.',
      oldestFace: 'Age prediction oldest face.',
      medianFace: 'Age prediction median face.',
    },
    debug: {
      humanInit: 'Initializing human.',
      humanInitialized: 'Human initialization time',
      faceFailedValidation: 'Age prediction face failed validation.',
      faceScoreLow: 'Age prediction face score low.',
      faceHasNoAge: 'Age prediction face has no age.',
      faceHasNoEmbedding: 'Age prediction face has no embedding.',
    },
    warn: {
      humanJsDetect: 'Error detecting face with human.',
      detectingFace: 'Error detecting face.',
      videoSource: 'Error setting video source.',
      noFaceTimeout: 'Age prediction timed out without detecting a face.',
      noProgressTimeout: 'Age prediction timed out because user stopped progressing.',
      reinitializingHuman: 'Reinitializing Human',
      faceMismatch: 'Age prediction face mismatch restart.',
    },
    error: {
      humanInit: 'Error initializing human.',
      humanWorkerDetect: 'Error in human worker during face detect.',
    },
  },
  verifyId: {
    info: {
      idPhotoFileURLSet: 'ID photo file URL set.',
      idPhotoSubmitted: 'ID photo submitted',
      faceMatches: 'Face matches ID mugshot.',
      complete: 'ID verification complete.',
      noFaceInPhoto: 'No face detected in uploaded photo',
      ocrDataReceived: 'OCR data received.',
    },
    warn: {
      faceDoesNotMatch: 'Face does not match ID mugshot.',
      matchingFaces: 'Error matching faces.',
      detectFaceInImage: 'Error detecting face in image.',
      verifyId: 'Error verifying ID.',
      noFaces: 'No faces from age detection to match with ID.',
    },
    error: {
      processImage: 'Error processing image.',
    },
  },
  verifyIdInfo: {
    info: {
      start: 'Starting verify id info document scan.',
      scanResult: 'Verifying age from scan result.',
      frameCaptured: 'Document scanner frame captured.',
      tesseractResult: 'Tesseract extraction result.',
      mrzMetrics: 'MRZ metrics.',
    },
    warn: {
      ageBelowThreshold: 'Age detected is below allowed threshold.',
    },
    error: {
      invalidBirthDate: 'Invalid or missing "birthDate" in scan result.',
      scan: 'Error scanning document.',
      faceMatch: 'Error matching faces within document.',
    },
  },
  mintTag: {
    info: {
      mintStart: 'Sending mint tag request.',
      mintComplete: 'Mint tag request complete.',
    },
    error: {
      mintError: 'Error in mint tag request.',
      missingParams: 'Missing params in mint tag request.',
    },
  },
  phases: {
    info: {
      entry: 'Safe passage entry page.',
      cameraEntry: 'Camera entry page.',
      start: 'Starting safe passage checks.',
      selectCountry: 'Select document country page.',
      selectDocumentType: 'Select document type page.',
      verifyId: 'Verify ID page.',
      verifyBarcode: 'Verify barcode page.',
      pass: 'Pass safe passage checks.',
      fail: 'Fail safe passage checks.',
      retry: 'Retry safe passage checks.',
    },
  },
  resizeImage: {
    warn: {
      tooLarge: 'Image is too large.',
    },
  },
  appealForm: {
    info: {
      submit: 'Appeal form submitted.',
      mount: 'Appeal form page.',
    },
    error: {
      upload: 'Error uploading appeal form images.',
      submit: 'Error submitting appeal form.',
    },
  },
  au10tix: {
    info: {
      verifyId: 'Au10tix verify id page.',
      beginProcessing: 'Au10tix begin processing request.',
      beginProcessingResponse: 'Au10tix begin processing response.',
      gettingResult: 'Au10tix getting result.',
      results: 'Au10tix Results.',
      pass: 'Au10tix 18+ Verified.',
      doubleCheck: 'Au10tix triggered doublecheck.',
    },
    warn: {
      errorProcessingRequest: 'Au10tix error processing request.',
    },
    error: {
      beginProcessing: 'Au10tix error begin processing request.',
      getResults: 'Au10tix error getting result.',
    },
  },
  successPage: {
    info: {
      createAccount: 'Create account button clicked.',
      continue: 'Continue button clicked.',
    },
  },
  manualReview: {
    info: {
      approve: 'Manual review approved.',
      deny: 'Manual review denied.',
    },
    error: {
      approve: 'Error approving manual review.',
      deny: 'Error denying manual review.',
    },
  },
  monocularDepth: {
    info: {},
    warn: {},
    error: {
      processImage: 'Error processing monocular depth image.',
      postProcess: 'Error with monocular depth postprocess or upload.',
    },
  },
} as const;
