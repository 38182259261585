import { useState, useEffect } from 'react';
import stopStream from 'utils/stopStream';

const useBestVideoDevice = (): [Error | null, InputDeviceInfo | null] => {
  const [state, setState] = useState<[Error | null, InputDeviceInfo | null]>([null, null]);

  useEffect(() => {
    navigator.mediaDevices?.getUserMedia({
      video: true,
      audio: false,
    }).then((stream) => {
      stopStream(stream);

      return navigator.mediaDevices
        .enumerateDevices()
        .then((devices: MediaDeviceInfo[]) => {
          const filteredDevices: InputDeviceInfo[] = devices
            .filter((device: MediaDeviceInfo): device is InputDeviceInfo => device.kind === 'videoinput')
            .filter((device: InputDeviceInfo) => {
              if (!device.getCapabilities) {
                return true;
              }

              const capabilities = device.getCapabilities();

              return (
                capabilities.facingMode?.length === 0
                || capabilities.facingMode?.includes('environment')
              );
            })
            .sort((a: InputDeviceInfo, b: InputDeviceInfo) => {
              if (!a.getCapabilities || !b.getCapabilities) {
                return 0;
              }

              const aCapabilities = a.getCapabilities();
              const bCapabilities = b.getCapabilities();

              const aMaxWidth = aCapabilities?.width?.max ?? 0;
              const aMaxHeight = aCapabilities?.height?.max ?? 0;
              const aMaxResolution = aMaxWidth * aMaxHeight;

              const bMaxWidth = bCapabilities?.width?.max ?? 0;
              const bMaxHeight = bCapabilities?.height?.max ?? 0;
              const bMaxResolution = bMaxWidth * bMaxHeight;

              if (aMaxResolution > bMaxResolution) {
                return -1;
              }

              if (aMaxResolution < bMaxResolution) {
                return 1;
              }

              return 0;
            });

          setState([null, filteredDevices?.[0] ?? null]);
        });
    }).catch((err: Error) => {
      setState([err, null]);
    });
  }, []);

  return state;
};

export default useBestVideoDevice;
