import { useCallback } from 'react';
import axios from 'services/api/axios';
import { UploadFileResponse } from 'types/upload';

function useUpload() {
  const uploadFile = useCallback((file: File) => new Promise<UploadFileResponse>((resolve, reject) => {
    const formData = new FormData();
    formData.append('file', file);

    axios.post<UploadFileResponse>('/api/safe-passage/v1/asset', formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    })
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  }), []);

  return uploadFile;
}

export default useUpload;
