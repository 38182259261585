import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import useVerificationStore from 'stores/verificationStore';
import Button from 'components/Button';
import useConfigStore from 'stores/configStore';
import MainLayout from 'layouts/MainLayout';
import logger from 'services/logger';
import { loggerMessages } from 'types/logger';
import useAgeDetectionStore from 'stores/ageDetectionStore';
import mixpanel from 'services/mixpanel';
import countries from './countries';

function SelectDocCountry() {
  const { t } = useTranslation();
  const setDocumentCountry = useVerificationStore((state) => state.setDocumentCountry);
  const documentCountry = useVerificationStore((state) => state.documentCountry);
  const faces = useAgeDetectionStore((s) => s.faces);
  const cntry = useConfigStore((state) => state.country);
  const isRetry = useAgeDetectionStore((s) => s.isRetry);
  const navigate = useNavigate();

  useEffect(() => {
    if (faces.length === 0) {
      logger.warn('No faces from age prediction. Sending back to the beginning');
      navigate('/');
    }
  }, [faces, navigate]);

  useEffect(() => {
    logger.info(loggerMessages.phases.info.selectCountry, { aggregates: { phaseProgress: true, isRetry } });
    logger.flush();
  }, []);

  useEffect(() => {
    // Default to country from config - this comes from the IP address
    if (cntry && !documentCountry) {
      setDocumentCountry(cntry);
    }
  }, [cntry, documentCountry, setDocumentCountry]);

  return (
    <MainLayout includeBackButton={false}>
      <div className="flex flex-col items-center gap-6 w-full text-center">
        <label
          htmlFor="documentCountry"
          className="text-center text-xl tracking-tight font-bold text-neutral-50"
        >
          {t('verifyId.selectCountry')}
        </label>
        <select
          id="documentCountry"
          className="border border-white rounded text-white w-full"
          style={{ backgroundColor: 'rgba(76, 147, 212, 0.31)' }}
          value={documentCountry}
          onChange={(e) => {
            const code = e.target.value;
            setDocumentCountry(code);
          }}
        >
          {countries.map((c) => (
            <option
              key={c.code}
              value={c.code}
            >
              {c.name}
            </option>
          ))}
        </select>
        <Button
          id="continue-to-select-doc-type-link"
          onClick={() => {
            logger.info('Select document country', { aggregates: { country: documentCountry } });
            mixpanel.trackEvent({ event: 'Select Document Country', country: documentCountry });
            navigate('/select-doc-type');
          }}
        >
          {t('verifyId.continue')}
        </Button>
      </div>
    </MainLayout>
  );
}

export default SelectDocCountry;
