import { DocumentScanResult } from './types';

const driverLicenseDateFields: Record<string, string> = {
  DBA: 'expirationDate',
  DBB: 'birthDate',
  DBL: 'birthDate',
  PAB: 'expirationDate',
};

const formatDate = (date: string): string | undefined => {
  if (date.length !== 8) {
    return undefined;
  }

  const fullDate = `${date.substr(0, 2)}/${date.substr(2, 2)}/${date.substr(4, 4)}`;

  if (!Number.isNaN(new Date(fullDate).getTime())) {
    return fullDate;
  }

  return undefined;
};

const parseDriverLicenseDateFields = (pdf417Data: string): DocumentScanResult => {
  const json: Record<string, string> = {};

  const chunks = pdf417Data.replaceAll('<RS>', '').replaceAll('<CR>', '').split('<LF>');

  while (chunks.length) {
    const chunk = chunks.pop();

    if (!chunk) {
      continue;
    }

    const field = chunk.substr(0, 3);
    const match = driverLicenseDateFields[field];

    if (match) {
      const formattedDate = formatDate(chunk.substr(3));

      if (formattedDate) {
        json[match] = formattedDate;
      }
    }
  }

  const result: DocumentScanResult = {
    type: 'pdf417',
  };

  if ('expirationDate' in json) {
    result.expirationDate = json.expirationDate;
  }

  if ('birthDate' in json) {
    result.birthDate = json.birthDate;
  }

  return result;
};

export default parseDriverLicenseDateFields;
