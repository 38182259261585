const drawRoundedRect = (
  ctx: CanvasRenderingContext2D,
  x: number,
  y: number,
  w: number,
  h: number,
  radius: number,
  roundedCorners: { topLeft: boolean; topRight: boolean; bottomLeft: boolean; bottomRight: boolean; },
) => {
  ctx.beginPath();
  ctx.moveTo(x + (roundedCorners.topLeft ? radius : 0), y);
  ctx.lineTo(x + w - (roundedCorners.topRight ? radius : 0), y);
  // eslint-disable-next-line no-unused-expressions
  roundedCorners.topRight ? ctx.quadraticCurveTo(x + w, y, x + w, y + radius) : ctx.lineTo(x + w, y);
  ctx.lineTo(x + w, y + h - (roundedCorners.bottomRight ? radius : 0));
  // eslint-disable-next-line no-unused-expressions
  roundedCorners.bottomRight ? ctx.quadraticCurveTo(x + w, y + h, x + w - radius, y + h) : ctx.lineTo(x + w, y + h);
  ctx.lineTo(x + (roundedCorners.bottomLeft ? radius : 0), y + h);
  // eslint-disable-next-line no-unused-expressions
  roundedCorners.bottomLeft ? ctx.quadraticCurveTo(x, y + h, x, y + h - radius) : ctx.lineTo(x, y + h);
  ctx.lineTo(x, y + (roundedCorners.topLeft ? radius : 0));
  // eslint-disable-next-line no-unused-expressions
  roundedCorners.topLeft ? ctx.quadraticCurveTo(x, y, x + radius, y) : ctx.lineTo(x, y);
  ctx.closePath();
};

export default drawRoundedRect;
