import React from 'react';
import { Outlet } from 'react-router-dom';
import Header from 'components/Header';
import Footer from 'components/Footer';

interface ErrorLayoutProps {
  includeBackButton?: boolean;
  basePath?: string;
  children?: React.ReactNode;
}

function ErrorLayout({
  includeBackButton = false,
  basePath = '',
  children,
}: ErrorLayoutProps) {
  return (
    <div id="main-container" className="h-full flex flex-col justify-between overflow-x-hidden overflow-y-auto">
      <div>
        <Header includeBackButton={includeBackButton} basePath={basePath} />
        <main className="flex-1">
          <div className="flex px-8 mx-auto max-w-2xl min-h-full">
            {children || <Outlet />}
          </div>
        </main>
      </div>
      <Footer />
    </div>
  );
}

export default ErrorLayout;
