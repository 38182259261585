import React, { useEffect } from 'react';
import AgePrediction from 'components/AgePrediction';
import logger from 'services/logger';
import { loggerMessages } from 'types/logger';
import useAgeDetectionStore from 'stores/ageDetectionStore';

function VerifyAgeRoute() {
  const isRetry = useAgeDetectionStore((s) => s.isRetry);

  useEffect(() => {
    logger.info(loggerMessages.phases.info.start, { aggregates: { phaseProgress: true, isRetry } });
    logger.flush();
  }, []);

  return (
    <div className="flex flex-col w-full p-2 overflow-hidden">
      <AgePrediction />
    </div>
  );
}

export default VerifyAgeRoute;
