import React, { useMemo, memo } from 'react';

const strokeWidth = 8;
const offset = strokeWidth * 2;

interface Props {
  cx: number;
  cy: number;
  radius: number;
  startAngle: number;
  length: number;
}

function ProgressArcSVG({
  cx, cy, radius, startAngle, length,
}:Props) {
  const _cx = cx + strokeWidth;
  const _cy = cy + strokeWidth;

  const computedStyle: React.CSSProperties = useMemo(() => ({
    position: 'absolute',
    top: `${-strokeWidth}px`,
    left: `${-strokeWidth}px`,
    fill: 'none',
    width: `${_cx + radius + offset}px`,
    height: `${_cy + radius + offset}px`,
  }), [_cx, _cy, radius]);

  const circumference = radius * 2 * Math.PI;
  const lengthInPixels = (length / 360) * circumference;

  return (
    <svg style={computedStyle}>
      <circle
        cx={_cx}
        cy={_cy}
        r={radius}
        fill="none"
        stroke="#059669"
        strokeWidth={strokeWidth}
        strokeDasharray={`${lengthInPixels}, ${circumference}`}
        strokeLinecap="round"
        transform={`rotate(${startAngle}, ${_cx}, ${_cy})`}
      />
    </svg>
  );
}

export default memo(ProgressArcSVG);
