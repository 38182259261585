import { ChevronDoubleLeftIcon } from '@heroicons/react/24/solid';
import React from 'react';

interface RoundedDoubleLeftIconProps {
  style?: React.CSSProperties;
  className?: string;
}

function RoundedDoubleLeftIcon({ className, style }: RoundedDoubleLeftIconProps): JSX.Element {
  return (
    <div style={style} className={className}>
      <ChevronDoubleLeftIcon />
    </div>
  );
}

export default RoundedDoubleLeftIcon;
