import React from 'react';
import { useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Failure from './Failure';
import Success from './Success';

export interface ExitProps {
  authority?: 'au10tix' | 'emblem';
  authenticated: boolean;
}

function ExitRoute({ authority = 'emblem', authenticated }: ExitProps) {
  const [params] = useSearchParams();
  const { t } = useTranslation();
  const [mintStatus, setMintStatus] = React.useState<'loading' | 'success' | 'error'>('loading');
  const errorType = params.get('error');
  const errorCode = params.get('errorCode');
  const hasError = !!errorType || mintStatus === 'error' || errorCode;

  if (hasError) {
    return (
      <Failure
        authority={authority}
        errorType={errorType}
        errorCode={errorCode}
        mintError={mintStatus === 'error' ? t('error.mintTag') : null}
      />
    );
  }

  return (
    <Success
      setMintStatus={setMintStatus}
      authenticated={authenticated}
      mintStatus={mintStatus}
    />
  );
}

export default ExitRoute;
